import {
  CompanyApiRemoteService,
  TAccountClassificationRuleUpdateDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { array, object, string } from 'yup'

export class UpdateAccountClassificationRuleUseCase
  implements
    IFormUsecase<
      { companyId: string; data: TAccountClassificationRuleUpdateDTO },
      void
    >
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle({
    companyId,
    data,
  }: {
    companyId: string
    data: TAccountClassificationRuleUpdateDTO
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.updateClassificationRule(
        companyId,
        data,
      )

      if (response.isFailure) {
        return Result.fail(new Error(response?.error?.error))
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    code: string().required('Defina um código para a nova regra'),
    name: string().required('Defina um nome para a nova regra'),
    credit: string().notRequired(),
    debit: string().notRequired(),
    historic: array()
      .of(string().optional())
      .optional()
      .test(
        'is-empty-or-valid',
        'Histórico inválido',
        (value) =>
          !value ||
          value.length === 0 ||
          value.every((item) => typeof item === 'string'),
      ),
    financialCategory: string().notRequired(),
  })
}
