import { IBankTransfersImport } from 'src/service/service-account'
import { BankApiRemoteService } from 'src/service/service-bank'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListTransfersImportHistoryUseCase
  implements
    IUsecase<
      { bankAccountId: string; companyId: string },
      IBankTransfersImport[]
    >
{
  constructor(private userApi: BankApiRemoteService) {}

  public async handle({
    bankAccountId,
    companyId,
  }: {
    bankAccountId: string
    companyId: string
  }): Promise<Result<IBankTransfersImport[]>> {
    try {
      // eslint-disable-next-line prettier/prettier
        const response = await this.userApi.listBankAccountsTransferHistory({
        bankAccountId,
        companyId,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar o histórico de importações.'),
        )
      }
      const result = response.getValue()

      if (result === null) {
        return Result.ok([] as IBankTransfersImport[])
      }

      return Result.ok(result as IBankTransfersImport[])
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
