import {
  TUserProfileResponse,
  UserApiRemoteService,
} from 'src/service/service-user'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'
import { StorageLocalstorage } from 'src/shared/storage/storage-localstorage'

export class UserGetProfileUseCase
  implements IUsecase<void, TUserProfileResponse['user']>
{
  constructor(private userApi: UserApiRemoteService) {}

  public async handle(): Promise<Result<TUserProfileResponse['user']>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.getProfile()
      if (response.isFailure) {
        return Result.fail(new Error('Erro ao recuperar dados do usuário'))
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar dados do usuário'))
      }

      const user = {
        name: result.user.name,
        companyName: result.user.companyName,
        personType: result.user.personType,
        cpfCnpj: result.user.cpfCnpj,
        username: result.user.username,
        termsOfUse: result.user.termsOfUse,
        email: result.user.email,
        backupCode: result.user.backupCode,
        twoAuthEnabled: result.user.twoAuthEnabled,
      }

      await StorageLocalstorage.set('user', {
        ...StorageLocalstorage.get('user'),
        ...user,
      })

      return Result.ok(result.user)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
