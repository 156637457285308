export function formatCnpj(cnpj: string): string {
  let value = (cnpj || '').replace(/\D/gi, '')
  value = value.replace(/(\d{2})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1/$2')
  value = value.replace(/(\d{4})(\d)/, '$1-$2')
  return value
}

export function formatCpf(cpf: string): string {
  let value = (cpf || '').replace(/\D/gi, '')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1-$2')
  return value
}

export function formatMoney(amount: number) {
  if (isNaN(amount)) return 'R$ 0,00'

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)
}

export function getMonthName(monthNumber: number, year?: number) {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  if (monthNumber < 1 || monthNumber > 12) {
    return 'Número do mês inválido'
  }

  if (year) {
    return `${monthNames[monthNumber - 1]}/${year}`
  }

  return monthNames[monthNumber - 1]
}
