import { CompanyApiRemoteService } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCompanyVendorsUseCase implements IUsecase<string, string[]> {
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle(companyId: string): Promise<Result<string[]>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response = await this.companyApi.getCompanyVendors(companyId);

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados dos clientes e fornecedores.'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados '))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
