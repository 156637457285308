import { ArrowDropDown } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FC } from 'react'
interface Props {
  showCheckbox?: boolean
  options: string[]
  value: string
  showLabel?: boolean
  onSelect: (data: string) => void
}

export const BaseInputSelect: FC<Props> = ({
  options,
  showCheckbox = true,
  onSelect,
  value,
  showLabel = true,
  ...rest
}) => {
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event

    if (onSelect) {
      onSelect(value)
    }
  }
  return (
    <div>
      <FormControl
        sx={{ width: '100%', marginTop: 2, marginBottom: 2 }}
        {...rest}
      >
        <Select
          value={value}
          onChange={handleChange}
          IconComponent={() => (
            <ArrowDropDown sx={{ color: '#4865CC', marginRight: 3 }} />
          )} // Altera a cor do ícone da seta
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
          sx={{
            color: '#4865CC',
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '100px', // Arredonda os cantos
              borderColor: '#4865CC', // Muda a cor da borda
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4865CC', // Cor da borda ao passar o mouse
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4865CC', // Cor da borda quando o campo está focado
            },
          }}
        >
          {options.map((name) => (
            <MenuItem key={name} value={name}>
              {showCheckbox && (
                <Checkbox
                  checked={value.toLowerCase() === name.toLowerCase()}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: '#4865CC', // Cor da borda quando o checkbox está marcado
                    },
                    '&.Mui-checked': {
                      color: '#4865CC', // Cor do ícone e borda quando marcado
                    },
                  }}
                />
              )}
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
