import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import 'reflect-metadata'
import { Modal } from 'src/component/base-component/base-modal'
import {
  ToastComponent,
  ToastProvider,
} from 'src/component/base-component/base-snackbar'
import { ModalProvider } from 'src/component/modal/modal-provider'
import { UserProvider } from 'src/context/context-user'
import { CompanyMassImportProvider } from 'src/shared/context/company-mass-import-provider'
import CustomTheme from 'src/shared/util/theme/theme'
import './index.css'
import reportWebVitals from './report-web-vitals'
import Router from './routes'
import { SidebarProvider } from './shared/context/sidebar-provider'
import ReactQueryProvider from './shared/query/query-provider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={CustomTheme}>
      <ReactQueryProvider>
        <UserProvider>
          <SidebarProvider>
            <CompanyMassImportProvider>
              <ModalProvider>
                <ToastProvider>
                  <RouterProvider router={Router} />
                  <ToastComponent />
                  <Modal />
                </ToastProvider>
              </ModalProvider>
            </CompanyMassImportProvider>
          </SidebarProvider>
        </UserProvider>
      </ReactQueryProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
