export const CalendarIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
  >
    <path
      stroke="#4865CC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18.847 4.777H6.403c-.982 0-1.778.796-1.778 1.778V19c0 .981.796 1.777 1.778 1.777h12.444c.982 0 1.778-.796 1.778-1.777V6.555c0-.982-.796-1.778-1.778-1.778zM16.18 3v3.556M9.07 3v3.556M4.624 10.11h16"
    ></path>
  </svg>
)
