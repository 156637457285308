import React, { createContext, useContext, useMemo, useState } from 'react'

export type SidebarContextType = {
  opened: boolean
  setOpened: (opened: boolean) => void
}

const SidebarContext = createContext({} as SidebarContextType)

export const SidebarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [opened, setOpened] = useState<boolean>(true)

  const dataProviderValue = useMemo(
    () => ({ opened, setOpened }),
    [opened, setOpened],
  )

  return (
    <SidebarContext.Provider value={dataProviderValue}>
      {children}
    </SidebarContext.Provider>
  )
}

export function useSidebarContext() {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error('Sidebar must be used within a DataProvider')
  }
  return context
}
