import {
  CompanyApiRemoteService,
  TAccountClassificationRuleCreateDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { array, boolean, object, string } from 'yup'

export class CreateAccountClassificationRuleUseCase
  implements
    IFormUsecase<
      { companyId: string; data: TAccountClassificationRuleCreateDTO },
      void
    >
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle({
    companyId,
    data,
  }: {
    companyId: string
    data: TAccountClassificationRuleCreateDTO
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.createClassificationRule(
        companyId,
        data,
      )

      if (response.isFailure) {
        return response
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object().shape({
    code: string()
      .required('Defina um código para a nova regra')
      .test(
        'code-validation',
        'Formato inválido. Apenas números, sem sequência de pontos, sem começar ou terminar com pontos.',
        (value) =>
          !value || // Existe
          /^[0-9.]+$/.test(value?.trim()) || // Apenas numero e pontos
          value.startsWith('.') || // Não pode começar com .
          value.endsWith('.') || // Não pode terminar com .
          value.match(/\.\./) !== null, // Não pode ter sequencia de .. ou mais juntos
      ),
    name: string(),
    credit: string().optional(),
    debit: string().optional(),
    historic: array().of(string().optional()).optional().default([]).nullable(),
    financialCategory: string().optional(),
  })
}
