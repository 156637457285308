import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export type TContactUsDTO = {
  name: string
  phone: string
  email: string
  message: string
}

export class ContactUsUseCase implements IFormUsecase<TContactUsDTO, void> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public async handle({
    name,
    email,
    phone,
    message,
  }: TContactUsDTO): Promise<Result<void>> {
    try {
      const subject = `Contato de ${name}`
      const body = `Nome: ${name}\nTelefone: ${phone}\nEmail: ${email}\nMensagem: ${message}`

      const mailtoUrl = `mailto:contato@openi.com.br?subject=${encodeURIComponent(
        subject,
      )}&body=${encodeURIComponent(body)}`

      window.open(mailtoUrl)

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    name: string().required('Nome é obrigatório'),
    phone: string().required('Telefone é obrigatório'),
    email: string().required('Email é obrigatório').email('Email inválido'),
    message: string().required('Mensagem é obrigatória'),
  })
}
