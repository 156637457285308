import { Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TCompanyInfoResponse } from 'src/service/service-company'
import { BaseMultipleSelectInput } from '../../base-component/base-multiple-select'

export const SelectCompanyModal: React.FC<{
  companies: TCompanyInfoResponse['companies'][]
  handle: (companies: string[]) => Promise<void>
  confirmationStep?: boolean
  confirmationText?: string
  confirmationButtonText?: string
}> = ({
  handle,
  companies,
  confirmationStep,
  confirmationText,
  confirmationButtonText = 'Confirmar',
}) => {
  const { HideModal } = ModalFullContext()
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
  const [isInConfirmationMode, setIsInConfirmationMode] =
    useState<boolean>(false)

  const handleClose = () => {
    setSelectedCompanies([])
    setIsInConfirmationMode(false)
    HideModal()
  }

  const handleReply = () => {
    setSelectedCompanies([])
    setIsInConfirmationMode(false)
    handle && handle(selectedCompanies)
  }

  const handlePreReply = () => {
    if (confirmationStep && !isInConfirmationMode) {
      return setIsInConfirmationMode(true)
    }

    return handleReply()
  }

  return (
    <Stack
      width="500px"
      height={!isInConfirmationMode ? '200px' : '150px'}
      gap="16px"
    >
      <Typography fontSize="14px" fontWeight={400} color="#1E1E1E">
        {isInConfirmationMode
          ? confirmationText
          : 'Selecione a(s) empresa(s) que deseja'}
      </Typography>
      {!isInConfirmationMode && (
        <BaseMultipleSelectInput
          label="Empresas"
          labelBackground="transparent"
          options={companies?.map((company) => {
            return { value: company.id, label: company.name }
          })}
          fullWidth
          error=""
          value={selectedCompanies}
          setState={(e) => setSelectedCompanies(e)}
          allowSearchValues={true}
        />
      )}

      <Stack
        direction="row"
        gap="16px"
        marginTop="24px"
        justifyContent="end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleClose()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          variant={selectedCompanies?.length <= 0 ? 'outlined' : 'contained'}
          disabled={selectedCompanies?.length <= 0}
          onClick={handlePreReply}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          {isInConfirmationMode ? 'Entendi' : confirmationButtonText}
        </Button>
      </Stack>
    </Stack>
  )
}
