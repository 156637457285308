import { Box, Divider, Stack, Typography } from '@mui/material'
import {
  BankAccountPersonTypeEnum,
  BankAccountTypeEnum,
  BankAccountTypeEnumUtil,
  BankAccountPersonTypeEnumUtil,
} from 'src/service/service-account'
import { GetBankLogo } from 'src/utils/normalizedBankLogos'
import logo from '../../assets/bank/itau-2-1.jpg'
import { Logo } from '../base-component/base-logo-component'
import { DeleteTableIcon } from '../icons/delete'
import { EditTableIcon } from '../icons/edit'
import { UploadIcon } from '../icons/upload'
import { UsersIcon } from '../icons/users'
import { WalletIcon } from '../icons/wallet'

export type AccountCardProps = {
  id: string
  title: string
  accountType: BankAccountTypeEnum
  personType: BankAccountPersonTypeEnum
  isOpenFinance: boolean
  bankName: string
  editButtonAction?: (accountId: string) => void
  deleteButtonAction?: (accountId: string) => void
  importButtonAction?: (accountId: string) => void
  bankLogo?: string
}

export const AccountCard = ({
  id,
  title,
  accountType,
  personType,
  isOpenFinance = false,
  bankName,
  editButtonAction,
  deleteButtonAction,
  importButtonAction,
  bankLogo,
}: AccountCardProps) => {
  return (
    <Stack
      key={`account-card-${id}`}
      width="267px"
      height="280px"
      borderRadius="8px"
      border="1px solid #B9BFC7"
      position="relative"
    >
      {/* Header */}
      <Stack padding="12px 24px" gap="12px" alignItems="center" direction="row">
        <Box width="40px" height="40px">
          <Logo name={bankName} width={40} height={40} logo={bankLogo} />
        </Box>
        <Typography
          key={`account-card-title-${id}`}
          fontWeight="700"
          fontSize="16px"
        >
          {title}
        </Typography>
      </Stack>
      <Divider sx={{ margin: '24px', marginTop: '0px', marginBottom: '0px' }} />
      {/* Body */}
      <Stack gap="24px" padding="24px">
        <Stack width="100%" direction="row" gap="12px" alignItems="center">
          <WalletIcon />

          <Typography
            display="flex"
            key={`account-card-type-${id}`}
            fontWeight="600"
            fontSize="14px"
            gap="12px"
          >
            {BankAccountTypeEnumUtil.NAMES[accountType]}
          </Typography>
        </Stack>
        <Stack width="100%" direction="row" gap="12px" alignItems="center">
          <UsersIcon />

          <Typography
            display="flex"
            key={`account-card-person-${id}`}
            fontWeight="600"
            fontSize="14px"
            gap="12px"
          >
            {BankAccountPersonTypeEnumUtil.toString(personType)}
          </Typography>
        </Stack>
      </Stack>
      {/* Footer */}
      <Stack width="100%" gap="8px" justifyContent="center" alignItems="center">
        <Stack
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap="36px"
          color="#4865CC"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <EditTableIcon />
            <Typography
              key={`account-card-edit-${id}`}
              fontWeight="600"
              fontSize="14px"
              onClick={() => editButtonAction && editButtonAction(id)}
            >
              Editar
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <DeleteTableIcon />
            <Typography
              key={`account-card-exclude-${id}`}
              fontWeight="600"
              fontSize="14px"
              onClick={() => deleteButtonAction && deleteButtonAction(id)}
            >
              Excluir
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          color={isOpenFinance ? '#CCCCCC' : '#4865CC'}
          onClick={() =>
            isOpenFinance ? null : importButtonAction && importButtonAction(id)
          }
          sx={{
            cursor: isOpenFinance ? 'no-drop' : 'pointer',
          }}
        >
          {!isOpenFinance && <UploadIcon />}
          <Typography
            key={`account-card-import-${id}`}
            fontWeight="600"
            fontSize="14px"
          >
            Importar extrato
          </Typography>
        </Stack>
      </Stack>
      {/* Via OpenFinance */}
      {isOpenFinance && (
        <Stack
          position="absolute"
          bottom="0px"
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: '#03B575',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <Typography
            key={`account-card-is-openfinance-${id}`}
            fontWeight="400"
            fontSize="12px"
            color="#ffffff"
          >
            Open Finance ativo
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}
