import { Button, ButtonProps } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'

export type BaseButtonFilterProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
} & ButtonProps
export const FilterButton = ({ onClick, ...props }: BaseButtonFilterProps) => {
  return (
    <Button
      variant="contained"
      onClick={onClick && onClick}
      sx={{ height: '40px' }}
      startIcon={<TuneIcon />}
      {...props}
    >
      Filtrar
    </Button>
  )
}
