export const ClockIcon = (): JSX.Element => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1667 17.0506V9.83366H16.1667V16.3337V16.6184L16.4116 16.7637L21.9764 20.0654L21.4983 20.8495L15.1667 17.0506ZM16.0001 27.167C18.9617 27.167 21.802 25.9905 23.8961 23.8964C25.9903 21.8022 27.1667 18.9619 27.1667 16.0003C27.1667 13.0387 25.9903 10.1985 23.8961 8.1043C21.802 6.01014 18.9617 4.83366 16.0001 4.83366C13.0385 4.83366 10.1982 6.01014 8.10406 8.1043C6.0099 10.1985 4.83341 13.0387 4.83341 16.0003C4.83341 18.9619 6.0099 21.8022 8.10406 23.8964C10.1982 25.9905 13.0385 27.167 16.0001 27.167ZM16.0001 3.16699C17.6854 3.16699 19.3542 3.49894 20.9112 4.14387C22.4682 4.78881 23.8829 5.7341 25.0746 6.92579C26.2663 8.11747 27.2116 9.53221 27.8565 11.0892C28.5015 12.6462 28.8334 14.315 28.8334 16.0003C28.8334 19.4039 27.4813 22.6681 25.0746 25.0749C22.6679 27.4816 19.4037 28.8337 16.0001 28.8337C8.90387 28.8337 3.16675 23.0585 3.16675 16.0003C3.16675 12.5967 4.51883 9.33251 6.92554 6.92579C9.33226 4.51907 12.5965 3.16699 16.0001 3.16699Z"
      fill="white"
      stroke="white"
    />
  </svg>
)
