import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { useEffect, useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { useNavigate } from 'react-router-dom'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { UserFullContext } from 'src/context/context-user'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
import { BaseSwitch } from 'src/component/base-component/base-switch'
import { RecoveryEmailUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-email'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecoveryEmailPageProps {
  useCase: RecoveryEmailUseCase
}

export function RecoveryEmailPage(props: RecoveryEmailPageProps): any {
  const { ShowToast } = ToastFullContext()

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [twoFactorAuth, setTwoFactorAuth] = useState<string | undefined | null>(
    undefined,
  )

  const form = CreateForm({
    fields: ['email', 'confirmEmail'],
    validation: props.useCase.validation,
  })

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleRegisterEmailUsecase = async () => {
    try {
      setLoading(true)

      const usecaseResult = await props.useCase.handle({
        email: form.fields.email.value,
      })

      setLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao atualizar o email. Tente novamente mais tarde.',
        )
      }

      ShowToast('success', 'Email cadastrado com sucesso!')

      if (twoFactorAuth) {
        navigate('/auth/recovery/setup/2fa')
      } else {
        navigate('/auth/sign-in')
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      ShowToast(
        'error',
        'Ocorreu um erro ao atualizar o email. Tente novamente mais tarde.',
      )
    }
  }

  const handleRegisterEmail = async () => {
    try {
      const validationResult = await form.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      handleRegisterEmailUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao atualizar o email. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const twoFactorAuthValue = searchParams.get('2fa')
    if (twoFactorAuthValue) {
      setTwoFactorAuth(twoFactorAuthValue)
    }
  }, [location.search])

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Cadastro
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            marginTop: '32px',
          }}
        >
          Digite seu email para o método de segurança.
        </Typography>
        <Box
          sx={{
            marginTop: '24px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: 'max-content',
              gap: '24px',
              width: '440px',
            }}
          >
            <BaseInput
              label="Digite o email"
              fullWidth
              {...form.fields.email}
            />
            <BaseInput
              label="Repetir email"
              fullWidth
              {...form.fields.confirmEmail}
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '32px',
            width: '440px',
          }}
        >
          {!loading && (
            <Button fullWidth variant="contained" onClick={handleRegisterEmail}>
              Cadastrar email
            </Button>
          )}
          {loading && (
            <CircularProgress
              sx={{
                justifySelf: 'center',
                justifyItems: 'center',
              }}
            />
          )}
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
