import { useState } from 'react'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import {
  IBankAccountTransfer,
  IBankAccountTransferResponse,
} from 'src/service/service-bank'
import { ListTransfersUseCase } from 'src/usecase/transfers/list-transfers'

export function useTransfers(listTransfersUseCase: ListTransfersUseCase) {
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState(false)

  const [transfers, setTransfers] = useState<IBankAccountTransfer[]>([])
  const [balance, setBalance] = useState(0)

  const handleListTransfersUsecase = async ({
    accountsIds,
    date,
  }: {
    date?: string[]
    accountsIds?: string[]
  }) => {
    try {
      setIsLoading(true)
      const usecaseResult = await listTransfersUseCase.handle({
        accountsIds,
        date,
      })
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro buscar as transferências. Tente novamente mais tarde.',
        )
      }

      const transfersData =
        usecaseResult.getValue() as IBankAccountTransferResponse

      setTransfers(transfersData.transfers)
      setBalance(Number(transfersData.balance))
    } catch (error) {
      setIsLoading(false)
      ShowToast('error', 'Ocorreu um erro ao buscar as transferências.')
    }
  }

  const handleListTransfers = async ({
    accountsIds,
    date,
  }: {
    date?: string[]
    accountsIds?: string[]
  }) => {
    try {
      handleListTransfersUsecase({ accountsIds, date })
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as transferências. Tente novamente mais tarde.',
      )
    }
  }

  return {
    isLoading,
    transfers,
    setIsLoading,
    handleListTransfers,
    balance,
  }
}
