import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { EditOutlined, ArrowBack } from '@mui/icons-material'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TermsOfUseModal } from 'src/component/modal/modal-terms-of-use'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { BaseRadio } from 'src/component/base-component/base-radio'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { useNavigate } from 'react-router-dom'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
// import { RecoveryWith2FAUseCase } from 'src/usecase/authentication/usecase-authentication-form-contact-us'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { object } from 'yup'
import { RecoveryWith2FAUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-with-2fa'

interface RecoveryWith2FAPageProps {
  useCase: RecoveryWith2FAUseCase
}

export function RecoveryWith2FAPage(props: RecoveryWith2FAPageProps): any {
  const { ShowToast } = ToastFullContext()

  const navigate = useNavigate()
  const theme = useTheme()

  const recovery2FAForm = CreateForm({
    fields: ['username', 'code', 'password', 'confirmPassword'],
    validation: props.useCase.validation,
  })
  const [loading, setLoading] = useState(false)

  const handleRecoveryWith2FAUsecase = async () => {
    try {
      setLoading(true)
      const usecaseResult = await props.useCase.handle({
        username: recovery2FAForm.fields.username.value,
        code: recovery2FAForm.fields.code.value,
        password: recovery2FAForm.fields.password.value,
      })
      if (usecaseResult.isFailure) {
        setLoading(false)
        switch ((usecaseResult.error as any)?.type) {
          case 'UserNotFoundError':
            ShowToast('error', 'Usuário não encontrado')
            break

          case 'UserTwoAuthRecoveryInvalidCodeError':
            ShowToast('error', 'Código de recuperação inválido')
            break

          case 'UserRecovery2FACodeDisabledError':
            ShowToast('error', 'Recuperação via 2FA desabilitada')
            break

          default:
            ShowToast(
              'error',
              'Ocorreu um erro ao recuperar sua senha via 2FA. Tente novamente mais tarde.',
            )
            break
        }
        return
      }
      setLoading(false)
      ShowToast('success', 'Senha atualizda com sucesso!.')
      navigate('/auth/sign-in')
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao recuperar sua senha via 2FA. Tente novamente mais tarde.',
      )
    }
  }

  const handleRecoveryWith2FA = async () => {
    try {
      console.log(recovery2FAForm.fields.code.value)

      const validationResult = await recovery2FAForm.validate()

      if (validationResult.isFailure) {
        ShowToast(
          'error',
          validationResult?.error?.message ??
            'Verifique se preencheu os campos corretamente',
        )
        return
      }

      handleRecoveryWith2FAUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao recuperar sua senha via 2FA. Tente novamente mais tarde.',
      )
    }
  }

  const handleRecoveryWithEmail = () => {
    navigate('/auth/recovery/email')
  }

  const handleCancel = () => {
    navigate('/auth/recovery/backup-code')
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          width: '0px',
          height: '0px',
          marginBottom: '64px',
          marginRight: '71px',
        }}
      >
        <Button variant="text" onClick={handleCancel}>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: '8px',
              alignItems: 'center',
              gridAutoColumns: 'max-content',
            }}
          >
            <ArrowBack sx={{ color: theme.palette.primary.main }} />
            <span>Voltar</span>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Recuperar a senha
        </Typography>
        <Typography
          sx={{
            marginTop: '32px',
            marginBottom: '16px',
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          Insira o username cadastrado para recuperar a senha
        </Typography>
        <BaseInput
          label="Digite o username"
          fullWidth
          {...recovery2FAForm.fields.username}
        />
        <Typography
          sx={{
            marginTop: '32px',
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          Para recuperar sua senha insira o código de 2 fatores cadastrado no
          seu aplicativo de autenticação, caso esteja habilitado
        </Typography>
        <Box
          sx={{
            marginTop: '32px',
            justifySelf: 'center',
            justifyItems: 'center',
          }}
        >
          <BaseInputCode length={6} {...recovery2FAForm.fields.code} />
          <Button
            sx={{
              marginTop: '32px',
            }}
            variant="text"
            fullWidth
            onClick={handleRecoveryWithEmail}
          >
            Não tenho ou não sei o código 2FA. Redefinir senha de outra forma
          </Button>
        </Box>
        <Typography
          sx={{
            marginTop: '32px',
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          Defina sua nova senha
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            gap: '32px',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <BaseInput
            label="Digite a nova senha"
            type="password"
            fullWidth
            {...recovery2FAForm.fields.password}
          />
          <BaseInput
            label="Repetir senha"
            type="password"
            fullWidth
            {...recovery2FAForm.fields.confirmPassword}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '32px',
            width: '100%',
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: 'grid',
                justifyItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              fullWidth
              onClick={handleRecoveryWith2FA}
            >
              Recuperar senha
            </Button>
          )}
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
