export const ArrowUpIcon = (): JSX.Element => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.104509 3.86679C0.038883 3.93931 0.00418966 4.03461 0.00782819 4.13235C0.0114667 4.23009 0.0531491 4.32254 0.123985 4.38999C0.194822 4.45743 0.289206 4.49453 0.387008 4.49337C0.48481 4.49222 0.578289 4.45289 0.647509 4.38379L3.62451 1.25829V8.62529C3.62451 8.72474 3.66402 8.82013 3.73434 8.89045C3.80467 8.96078 3.90005 9.00029 3.99951 9.00029C4.09896 9.00029 4.19435 8.96078 4.26467 8.89045C4.335 8.82013 4.37451 8.72474 4.37451 8.62529V1.25829L7.35201 4.38379C7.38596 4.41948 7.4266 4.44813 7.47162 4.46811C7.51664 4.48809 7.56515 4.499 7.61439 4.50023C7.66363 4.50146 7.71263 4.49298 7.75859 4.47528C7.80455 4.45757 7.84657 4.43099 7.88226 4.39704C7.91794 4.36309 7.9466 4.32245 7.96658 4.27743C7.98656 4.23241 7.99747 4.1839 7.9987 4.13466C7.99993 4.08542 7.99145 4.03642 7.97375 3.99046C7.95604 3.9445 7.92946 3.90248 7.89551 3.86679L4.36201 0.156789C4.28497 0.0755897 4.1828 0.0227424 4.07201 0.00678913C4.0241 -0.00242827 3.97485 -0.0022585 3.92701 0.00728909C3.81658 0.0234566 3.71479 0.0762875 3.63801 0.157289L0.104509 3.86679Z"
      fill="#03B575"
    />
  </svg>
)
