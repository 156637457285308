import { Button, Stack, Typography } from '@mui/material'

export const SimpleTextModal: React.FC<{
  firstButtonAction: () => void
  secondButtonAction: () => void
  firstButtonText: string
  secondButtonText: string
  text: string
  height: number
  width: number
}> = ({
  firstButtonAction,
  secondButtonAction,
  firstButtonText,
  secondButtonText,
  text,
  height,
  width,
}) => {
  return (
    <Stack
      width="100%"
      maxWidth={`${width}px`}
      height={`${height}px`}
      gap="32px"
    >
      <Typography fontSize="16px" fontWeight={400} color="#1E1E1E">
        {text}
      </Typography>
      <Stack
        direction="row"
        gap="16px"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => firstButtonAction()}
        >
          {firstButtonText}
        </Button>

        <Button
          fullWidth={false}
          variant="contained"
          onClick={() => secondButtonAction()}
        >
          {secondButtonText}
        </Button>
      </Stack>
    </Stack>
  )
}
