import styled, { css } from 'styled-components'

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  color: #1e3466;
  width: 60px;
  height: 60px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  gap: 8px;
`

export const Circle = styled.div<{ isActive?: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;

  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #b9bfc7;
  color: #b9bfc7;
  font-size: 16px;
  font-weight: 700;
  aspect-ratio: 1 / 1;
  gap: 8px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #5ed1a2;
      border: 1px solid #5ed1a2;
      color: #ffffff;
    `}
`

export const Line = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 40px;
  left: 50%;
  width: 2px;
  height: 80px;
  background-color: #b9bfc7;
  content: '';

  ${(props) =>
    props.isActive &&
    css`
      background-color: #5ed1a2;
    `}
`
