import { BorderBottom } from '@mui/icons-material'
import { Box, Input, TextField, useTheme } from '@mui/material'

export type BaseInputCodeProps = {
  setState: (value: string) => void
  error: string | null
  value: string
  length: number
  textColor?: string
}
export const BaseInputCode = ({
  setState,
  error,
  value,
  length,
  textColor = '#000000',
}: BaseInputCodeProps) => {
  const theme = useTheme()
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const newValue = event.target.value
    const updatedValue = value.split('')

    if (updatedValue.length >= length) {
      return
    }

    updatedValue[index] = newValue
    setState(updatedValue.join(''))

    if (newValue.length === 1 && index < length - 1) {
      const inputs = Array.from(document.querySelectorAll('input'))
      const currentIndex = inputs.findIndex((input) => input === event.target)
      const nextInput = inputs[currentIndex + 1] as HTMLInputElement
      nextInput.focus()
    }
  }

  const handleBackspace = (
    event: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
    >,
    index: number,
  ) => {
    if (event.key === 'Backspace' && index > 0) {
      const inputs = Array.from(document.querySelectorAll('input'))
      const currentIndex = inputs.findIndex((input) => input === event.target)
      const previousInput = inputs[currentIndex - 1] as HTMLInputElement
      previousInput.focus()

      const updatedValue = value.split('')
      updatedValue[index] = ''
      setState(updatedValue.join(''))
    }
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '8px',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {length > 0 &&
        Array.from({ length }).map((_, index) => (
          <Box
            sx={{
              display: 'grid',
              alignItems: 'center',
              alignContent: 'center',
              alignSelf: 'center',
              justifyItems: 'center',
              justifySelf: 'center',
              justifyContent: 'center',
              backgroundColor: '#FAFAFA',
              border: '1px solid #BBC3C4',
              borderRadius: '10px',
              height: '56px',
              width: '40px',
              textAlign: 'center',
              overflow: 'hidden',
            }}
            key={index}
          >
            <TextField
              sx={{
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
                justifyItems: 'center',
                justifySelf: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '56px',
                width: '40px',
                borderRadius: '10px',
                border: 'unset',
                fontWeight: '700',
                fontSize: '24px',
                color: textColor,
                overflow: 'hidden',
                padding: '0px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  borderColor: 'transparent',
                  overflow: 'hidden',
                  padding: '0px',
                  textAlign: 'center',
                },
                '& .MuiInputBase-input': {
                  fontWeight: '700',
                  fontSize: '24px',
                  color: textColor,
                  overflow: 'hidden',
                  padding: '0px',
                  textAlign: 'center',
                },
              }}
              value={value?.[index] || ''}
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(event) => handleInputChange(event, index)}
              onKeyDown={(event) => handleBackspace(event, index)}
            />
          </Box>
        ))}
    </Box>
  )
}
