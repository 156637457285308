import { ListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'

export const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #5ed1a2;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  width: 40px;
  height: 40px;
  overflow: hidden;
  gap: 8px;
`

export const CircleBox = styled.div`
  display: flex;
  position: absolute;
  right: -55px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #d8eef5;
  font-size: 16px;
  font-weight: 600;
  z-index: 997;
  color: #4865cc;
  width: 40px;
  height: 40px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  gap: 8px;
  cursor: pointer;
`

export const IconBox = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e3466;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  width: 30px;
  height: 30px;
  overflow: hidden;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  ${(props) =>
    props.isActive &&
    css`
      background-color: #ffffff !important;
      color: #4865cc !important;
      box-shadow: none;

      .MuiSvgIcon-root {
        color: #4865cc !important;
      }
    `}

  &:hover {
    background-color: #ffffff !important;
    color: #4865cc !important;
    box-shadow: none;

    .MuiSvgIcon-root {
      color: #4865cc !important;
    }
  }
`

export const CustomItemList = styled(ListItemButton)<{ isActive?: boolean }>`
  display: flex;
  justify-content: flex-start;
  min-height: 48px;
  border-radius: 8px !important;
  background-color: #1e3466;
  color: #ffffff !important;
  text-color: #ffffff;
  font-size: 12px !important;
  width: 176px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 16px;

  span {
    font-size: 12px !important;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #ffffff !important;
      color: #1e3466 !important;
      box-shadow: none !important;

      span {
        font-weight: 600 !important;
      }

      .MuiSvgIcon-root {
        color: #1e3466 !important;
      }
    `}

  &:hover {
    background-color: #ffffff !important;
    color: #1e3466 !important;
    box-shadow: none !important;

    span {
      font-weight: 600 !important;
    }

    .MuiSvgIcon-root {
      color: #1e3466 !important;
    }
  }
`
