import {
  AuthenticationApiRemoteService,
  TAuthRegisterDTO,
  UserPersonTypeEnum,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, ref, string } from 'yup'

export class RegisterUseCase implements IFormUsecase<TAuthRegisterDTO, void> {
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(data: TAuthRegisterDTO): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.register(data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    name: string().required('Nome é obrigatório'),
    companyName: string().required('Nome da empresa é obrigatório'),
    personType: string().required().oneOf(Object.values(UserPersonTypeEnum)),
    cpfCnpj: string().required('CPF/CNPJ é obrigatório'),
    username: string().required('Usuário é obrigatório'),
    password: string()
      .required('Senha é obrigatória')
      .min(6)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])/,
        'Senha deve conter ao menos 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
      ),
    confirmPassword: string()
      .required('Confirmação de senha é obrigatória')
      .oneOf([ref('password')], 'Senhas não conferem'),
  })
}
