import { Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { ColumnsCommon, Table } from 'src/component/table/table'
import { IBankTransfersImport } from 'src/service/service-account'
import { DeleteTransferImportUseCase } from 'src/usecase/transfers/delete-transfer-import'
import { ListTransfersImportHistoryUseCase } from 'src/usecase/transfers/list-transfer-import-history'
import BlueHeader from '../../assets/background/header-blue.png'

interface IBankAccountExtractProps {
  listTransfersImportHistoryUseCase: ListTransfersImportHistoryUseCase
  deleteTransferImportUseCase: DeleteTransferImportUseCase
}

export function BankAccountExtractList(props: IBankAccountExtractProps) {
  const { companyId, accountId } = useParams()
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [importedTransfers, setImportedTransfers] = useState<
    IBankTransfersImport[]
  >([])
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([])

  const handleListDataUsecase = async () => {
    try {
      setIsLoading(true)
      const importedTransfersResult =
        await props.listTransfersImportHistoryUseCase.handle({
          bankAccountId: accountId as string,
          companyId: companyId as string,
        })
      setIsLoading(false)

      if (importedTransfersResult.isFailure) {
        ShowToast(
          'error',
          'Erro ao recuperar dados das regras de classificação relacionadas a empresas',
        )
      }

      const importedTransfersData = importedTransfersResult.getValue()
      console.log(importedTransfersData)
      setImportedTransfers(
        importedTransfersData as unknown as IBankTransfersImport[],
      )
    } catch (error) {
      setIsLoading(false)
      ShowToast('error', 'Erro ao recuperar dados de histórico de importações')
    }
  }

  const handleListTransfersImport = async () => {
    try {
      handleListDataUsecase()
    } catch (error) {
      ShowToast('error', 'Erro ao recuperar dados de histórico de importações')
    }
  }

  const handleDeleteAccountModal = async (ids: string[]) => {
    if (ids.length === 0) {
      return ShowToast('error', 'Nenhum registro informado para ser deletado.')
    }

    setIsLoading(true)
    await Promise.all(
      ids.map(async (currentId) => {
        return await props.deleteTransferImportUseCase.handle({
          data: {
            bankAccountId: accountId as string,
            companyId: companyId as string,
            importedTransfersId: currentId,
          },
        })
      }),
    )
    ShowToast('success', 'Registros excluídos com sucesso')
    handleListDataUsecase()
    setIsLoading(false)
  }

  useEffect(() => {
    handleListTransfersImport()
  }, [companyId])

  const columns: GridColDef[] = [
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'createdAt',
        headerName: 'Data e Hora',
      },
      renderCell: (params) =>
        `${format(params.row.createdAt, 'dd/MM/yyyy HH:mm') || ''}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      align: 'left',
      ...{
        field: 'name',
        headerName: 'Conta Sistêmica',
      },
      renderCell: (params) => `${params.row.bankAccount.name}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      align: 'left',
      ...{
        field: 'type',
        headerName: 'Tipo de Importação',
      },
      renderCell: (params) => `${params.row.type}`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      minWidth: 320,
      ...{
        field: 'id',
        headerName: 'Ações',
        align: 'left',
        renderCell: (params) => (
          <Stack
            direction="row"
            width="calc(100% - 24px)"
            justifyContent="space-between"
            alignItems="center"
            paddingRight="24px"
            height="100%"
          >
            <Stack
              justifyContent="center"
              height="100%"
              alignItems="center"
              sx={{ cursor: 'pointer', color: '#4865CC' }}
              onClick={(e) => {
                e.preventDefault()
                handleDeleteAccountModal([params.row.id])
              }}
            >
              <Typography>Excluir</Typography>
            </Stack>
          </Stack>
        ),
      },
    },
  ]

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${companyId}` },
          {
            title: 'Extrato',
          },
          { title: 'Excluir' },
        ]}
      />

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        gap="32px"
      >
        <Stack
          width="100%"
          height="calc(100% - 64px)"
          padding="32px"
          gap="48px"
        >
          <BaseInternalHeader
            leftComponent={<BasePageTitle color="#1E3466" text="Extratos" />}
          />

          <Stack width="calc(100% - 64px)" height="100%">
            {!isLoading && (
              <Table
                columns={columns}
                rows={importedTransfers}
                loadMore={() => console.log('press me')}
                totalPages={1}
                currentPage={1}
                totalRegisters={importedTransfers?.length}
                allowSelectAll={true}
                loading={false}
                selectedItemActions={[
                  {
                    title: 'Excluir Selecionados',
                    action: () => handleDeleteAccountModal(selectedGridIds),
                  },
                ]}
                showFilterButton={true}
                showSearchButton={true}
                setSelectedGridIds={setSelectedGridIds}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
