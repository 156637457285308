import {
  CompanyApiRemoteService,
  TCompanyInfoResponse,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCompanyClassificationRulesUseCase
  implements
    IUsecase<
      { companyId: string; query?: string },
      TCompanyInfoResponse['classificationRules'][]
    >
{
  constructor(private userApi: CompanyApiRemoteService) {}

  public async handle({
    companyId,
    query,
  }: {
    companyId: string
    query?: string
  }): Promise<Result<TCompanyInfoResponse['classificationRules'][]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.getCompanyClassificationRules(
        companyId,
        query,
      )

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Erro ao recuperar dados das regras de classificação relacionadas a empresas',
          ),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(
          new Error(
            'Erro ao recuperar dados das regras de classificação relacionadas a empresas',
          ),
        )
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
