import { AlertProps, Snackbar, useTheme } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

// SNACKBAR PROVIDER
export interface ISimpleSnackbarProps {
  open: boolean
  marginTop?: number
  message: string
  type: 'success' | 'error' | 'warning'
  controlShowModal?: (value: React.SetStateAction<boolean>) => void
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    props,
    ref,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />,
)

export function SimpleSnackbar(props: ISimpleSnackbarProps) {
  const { HideToast } = ToastFullContext()
  const [open, setOpen] = React.useState(props.open)

  const theme = useTheme()

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    HideToast()
    setOpen(false)
    if (props.controlShowModal) {
      props.controlShowModal(false)
    }
  }

  return (
    <Snackbar
      style={{
        maxWidth: '400px',
        marginTop: props.marginTop,
      }}
      open={open}
      autoHideDuration={5000} // Remove auto hide duration
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Alert
        sx={{
          width: '100%',
          backgroundColor: '#1E1E1E',
          color: theme.palette.common.white,
          fontSize: '16px',
          borderRadius: '8px',
        }}
        icon={false} // Remove the icon
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}

// TOAST PROVIDER
export type ToastContextType = {
  ShowToast: (
    toastName: 'success' | 'error' | 'warning',
    message: string,
  ) => void
  HideToast: () => void
  GetToastInformations: () => {
    toastName: '' | 'success' | 'error' | 'warning'
    message: string
  }
}

const ToastContext = createContext({} as ToastContextType)

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toastState, setToastState] = useState({
    toastName: '',
    message: '',
  } as any)

  const ShowToast = (
    toastName: 'success' | 'error' | 'warning',
    message: string,
  ) => {
    setToastState({
      toastName,
      message,
    })
  }

  const GetToastInformations = () => toastState

  const HideToast = () => {
    setToastState({
      toastName: '',
      message: '',
    })
  }

  const toastProviderValue = useMemo(
    () => ({ ShowToast, GetToastInformations, HideToast }),
    [ShowToast, GetToastInformations, HideToast],
  )

  return (
    <ToastContext.Provider value={toastProviderValue}>
      {children}
    </ToastContext.Provider>
  )
}

export function ToastFullContext() {
  const context = useContext(ToastContext)
  return context
}

// TOAST COMPONENT
export const ToastComponent = () => {
  const { GetToastInformations } = ToastFullContext()
  if (!GetToastInformations().toastName) return <div />

  return (
    <>
      <SimpleSnackbar
        type={GetToastInformations().toastName as any}
        marginTop={80}
        message={GetToastInformations().message}
        open
      />
    </>
  )
}
