import {
  BankApiRemoteService,
  ICashFlow,
  ICashFlowResponse,
} from 'src/service/service-bank'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCashFlowUseCase
  implements
    IUsecase<
      { companiesIds?: string[]; accountIds?: string[]; date?: string[] },
      ICashFlowResponse
    >
{
  constructor(private userApi: BankApiRemoteService) {}

  public async handle({
    companiesIds,
    accountIds,
    date,
  }: {
    companiesIds?: string[]
    accountIds?: string[]
    date?: string[]
  }): Promise<Result<ICashFlowResponse>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response = await this.userApi.listCashFlow({
        companiesIds,
        accountIds,
        date,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados do fluxo de caixa.'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
