import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import BBLogo from 'src/assets/bank/bb.jpeg'
import ItauLogo from 'src/assets/bank/itau-medium.jpg'
import NuLogo from 'src/assets/bank/nubank-outlined.png'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BaseMultipleSelectInput } from 'src/component/base-component/base-multiple-select'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { CascadeButton } from 'src/component/buttons/cascade-button'
import { DefaultHeader } from 'src/component/header/header'
import { MenuDotIcon } from 'src/component/icons/menu-dot'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { ModalClassifierExport } from 'src/component/modal/classifier/modal-classifier-export'
import { ModalClassifierPaymentEntry } from 'src/component/modal/classifier/modal-classifier-payment-entry'
import { ModalClassifierPendency } from 'src/component/modal/classifier/modal-classifier-pendency'
import { ModalClassifierSuccess } from 'src/component/modal/classifier/modal-classifier-success'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { Table } from 'src/component/table/table'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { CreateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-create-classification-rule'
import { DeleteClassificationRuleUseCase } from 'src/usecase/company/usecase-company-delete-classification-rule'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { UpdateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-update-classification-rule'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import { useUserAccount } from '../systemic-accounts/useUserAccount'
import { useClassifier } from './useClassifier'
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list'
import { BorderBottom } from '@mui/icons-material'
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update'
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { GridColDef } from '@mui/x-data-grid'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

interface TemporaryProps {
  listCompaniesUseCase: ListCompanyUseCase
  addUserToCompanyUseCase: CreateCompanyUsersUseCase
  listUsersUseCase: UserListUseCase
  leaveCompanyUseCase: LeaveCompanyUseCase
  listClassificationRulesUseCase: ListCompanyClassificationRulesUseCase
  createClassificationRuleUseCase: CreateAccountClassificationRuleUseCase
  deleteClassificationRuleUseCase: DeleteClassificationRuleUseCase
  updateClassificationRuleUseCase: UpdateAccountClassificationRuleUseCase
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase
  listAccountingEntriesUseCase: ListAccountingEntriesUseCase
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase
  listCompanyVendorsUserCase: ListCompanyVendorsUseCase
  listCompanyDebitsUserCase: ListCompanyDebitUseCase
}

export function ClassifierPage(props: TemporaryProps) {
  const { companyId } = useParams()
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [showResults, setShowResults] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const getLogo = [NuLogo, ItauLogo, BBLogo, ItauLogo, BBLogo, NuLogo] // provisorio
  const ContainerRef = useRef<HTMLDivElement>(null)
  const { ShowModal, HideModal } = ModalFullContext()

  const {
    handleListUserCompaniesAccounts,
    userCompaniesAccounts: CompanyBankAccountsFiltered,
  } = useUserAccount(props.listUserCompaniesAccountsUseCase)

  const {
    searchQuery,
    columns,
    isLoading,
    handleListAccountingEntries,
    setSearchQuery,
    setSelectedGridIds,
    accountingEntries,
    handleListCompanyVendors,
    handleListCompanyDebit,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    currentPage,
    informations,
  } = useClassifier(
    props.listAccountingEntriesUseCase,
    props.updateAccountingEntriesUseCase,
    props.listCompanyVendorsUserCase,
    props.listCompanyDebitsUserCase,
    companyId as string,
  )

  useEffect(() => {
    if (companyId) {
      handleListCompanyVendors(companyId)
      handleListUserCompaniesAccounts([companyId])
      handleListAccountingEntries(companyId, 1)
    }
  }, [companyId])

  const handleShowResults = () => {
    if (accountsSelected?.length) {
      setShowResults(true)
    }
  }

  const bigNumbersNames = [
    {
      name: 'Saldo do extrato',
    },
    {
      name: 'Saldo dos lançamentos',
    },
    {
      name: 'Variação de caixa',
    },
    {
      name: 'Total de crédito',
    },
    {
      name: 'Total de débito',
    },
  ]

  useEffect(() => {
    if (ContainerRef.current) {
      setContainerWidth(ContainerRef.current.offsetWidth)
    }
  }, [])

  const ExportOptions = useMemo(() => {
    const normalizeOptions = [
      { name: 'Bling', premium: false },
      { name: 'DBCorp', premium: true },
    ]?.map((option, index) => {
      return {
        label: option.name,
        value: option.name,
        component: (
          <Stack direction="row" alignItems="center" padding="0 20px">
            <img
              src={getLogo[index]}
              style={{
                objectFit: 'contain',
                width: '24px',
                height: '24px',
              }}
              alt="bank-brand"
            />
            <Typography marginLeft="15px" fontSize={14} fontWeight={400}>
              {option.name}
            </Typography>
            {option.premium && (
              <Stack
                alignItems="center"
                justifyContent="center"
                bgcolor="#FCD24C"
                borderRadius="16px"
                padding="8px 16px"
                marginLeft="10px"
              >
                <Typography fontSize="12px" fontWeight={700} color="#1E3466">
                  Premium
                </Typography>
              </Stack>
            )}
          </Stack>
        ),
      }
    })

    return normalizeOptions
  }, [])

  const handleExport = (type: string) => {
    console.log('type handleExport: ', type)

    if (type === 'excel') {
      ShowModal({
        content: (
          <ModalClassifierPendency handle={() => console.log('press me')} />
        ),
        title: 'Classificação pendente',
      })
    }

    // gatilho fake temporário
    // if (type === "excel") {
    //   ShowModal({
    //     content: (
    //       <ModalClassifierAlreadyDone handle={() => console.log("press me")} />
    //     ),
    //     title: "Exportação já realizada",
    //   });
    // }

    if (type === 'erp') {
      ShowModal({
        content: <ModalClassifierSuccess totalExported={54} totalFailed={14} />,
        title: '',
      })
    }
  }

  const companyAccountsOptions = useMemo(() => {
    return CompanyBankAccountsFiltered.map((account, index) => {
      return {
        label: account.name,
        value: account.id,
        icon: (
          <img
            src={getLogo[index]}
            style={{
              objectFit: 'contain',
              width: '24px',
              height: '24px',
            }}
            alt="bank-brand"
          />
        ),
      }
    })
  }, [CompanyBankAccountsFiltered])

  const handleReturnBigNumber = (name: string) => {
    switch (name) {
      case 'Saldo do extrato':
        return informations?.bankBalance

      case 'Saldo dos lançamentos':
        return informations?.accountingBalance

      case 'Variação de caixa':
        return informations?.bankCashflow.total
      case 'Total de crédito':
        return informations?.bankCashflow.inflow
      case 'Total de débito':
        return informations?.bankCashflow.outflow

      default:
        return '0'
    }
  }

  return (
    <Stack
      sx={{ backgroundColor: '#F4F8FA', overflowX: 'hidden', flex: 1 }}
      ref={ContainerRef}
    >
      <DefaultHeader
        breadcumbItems={[{ title: 'Empresa XYZ' }, { title: 'Classificador' }]}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>

            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <MenuDotIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack width="100%" direction="row" gap="32px">
        <Stack width="100%" padding="32px" gap="48px">
          <BaseInternalHeader
            leftComponent={
              <BasePageTitle color="#1E3466" text="Classificador" />
            }
            rightComponent={
              <Box width="232px">
                <BaseInput
                  fullWidth
                  labelBackground="#F4F8FA"
                  type="text"
                  label="Buscar"
                  setState={(event) => {
                    if (!event) {
                      return setSearchQuery && setSearchQuery('')
                    }

                    setSearchQuery && setSearchQuery(event)
                  }}
                  value={searchQuery ?? ''}
                  error={null}
                  iconStart={<SearchIcon />}
                />
              </Box>
            }
          />

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" width="351px">
              <Stack flexDirection="row" alignItems="flex-end" gap="18px">
                <Stack width="280px">
                  <BaseMultipleSelectInput
                    label="Conta bancária"
                    labelBackground="transparent"
                    options={companyAccountsOptions}
                    fullWidth
                    error=""
                    value={accountsSelected}
                    setState={(e) => setAccountsSelected(e)}
                  />
                </Stack>
                <Button
                  variant="contained"
                  sx={{ width: '92px', height: '40px' }}
                  onClick={handleShowResults}
                >
                  Buscar
                </Button>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" gap="18px">
              <Button
                variant="text"
                // startIcon={<FileDownloadOutlined />}
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() =>
                  ShowModal({
                    content: (
                      <ModalClassifierExport
                        handle={(e) => handleExport(e)}
                        options={ExportOptions}
                      />
                    ),
                    title: 'Exportar',
                  })
                }
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Exportar
                </Typography>
              </Button>

              <Button
                variant="outlined"
                // startIcon={<FileDownloadOutlined />}
                sx={{ minWidth: '123px', height: '40px' }}
                // onClick={() =>
                //   ShowModal({
                //     content: (
                //       <ModalReportsExport
                //         handle={() => console.log('press me')}
                //       />
                //     ),
                //     title: 'Exportar',
                //   })
                // }
                onClick={() => console.log('click me')}
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Aplicar regras
                </Typography>
              </Button>

              <CascadeButton
                variant="primary"
                direction="left"
                options={[
                  {
                    label: 'Criar lançamento',
                    onClick: () => console.log('go to my account'),
                    subItens: [
                      {
                        label: 'Realizada',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={companyAccountsOptions}
                                handle={() => console.log('press me')}
                                type="made"
                                companyId={String(companyId)}
                                listCompanyDebitsUseCase={
                                  props.listCompanyDebitsUserCase
                                }
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Provisão',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={companyAccountsOptions}
                                handle={() => console.log('press me')}
                                type="provision"
                                companyId={String(companyId)}
                                listCompanyDebitsUseCase={
                                  props.listCompanyDebitsUserCase
                                }
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Transferência',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={companyAccountsOptions}
                                handle={() => console.log('press me')}
                                type="transfer"
                                companyId={String(companyId)}
                                listCompanyDebitsUseCase={
                                  props.listCompanyDebitsUserCase
                                }
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                    ],
                  },
                  {
                    label: 'Colunas',
                    onClick: () => console.log('press me'),
                    subItensSelected: columnsTableSelected,
                    subItens: [
                      {
                        label: 'Data',
                        onClick: () => handleSelectColumns('date'),
                        type: 'multi',
                        default: 'checked',
                        value: 'date',
                      },
                      {
                        label: 'Descrição',
                        onClick: () => handleSelectColumns('description'),
                        type: 'multi',
                        value: 'description',
                      },
                      {
                        label: 'Valor',
                        onClick: () => handleSelectColumns('value'),
                        type: 'multi',
                        value: 'value',
                      },
                      {
                        label: 'Débito',
                        onClick: () => handleSelectColumns('debit'),
                        type: 'multi',
                        value: 'debit',
                      },
                      {
                        label: 'Crédito',
                        onClick: () => handleSelectColumns('credit'),
                        type: 'multi',
                        value: 'credit',
                      },
                    ],
                  },
                ]}
                iconButton
              />
            </Stack>
          </Stack>

          {!accountsSelected?.length && (
            <Stack alignItems="center" justifyContent="center">
              <Typography fontWeight={400} fontSize="16px" marginTop="50px">
                Selecione uma ou mais contas bancárias para visualizar os
                lançamentos
              </Typography>
            </Stack>
          )}

          {showResults && (
            <>
              <Stack
                direction="row"
                bgcolor="#FFFFFF"
                borderRadius="16px"
                padding=" 16px 24px 16px 24px"
                justifyContent="space-between"
                alignItems="center"
                marginTop="-10px"
              >
                {bigNumbersNames?.map((item) => (
                  <Box key={item.name}>
                    <Typography
                      color="#777C84"
                      fontSize="14px"
                      fontWeight={600}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      color="#1E3466"
                      fontSize="24px"
                      fontWeight={700}
                    >
                      {formatMoney(Number(handleReturnBigNumber(item.name)))}
                    </Typography>
                  </Box>
                ))}
              </Stack>

              {!isLoading && (
                <Table
                  columns={columns}
                  // rows={
                  //   !searchQuery
                  //     ? accountingEntries
                  //     : accountingEntries.filter((f) =>
                  //         (f.description ?? '')
                  //           .toLowerCase()
                  //           .includes(searchQuery.toLowerCase()),
                  //       )
                  // }
                  rows={accountingEntries}
                  loadMore={(valuePage) =>
                    handleListAccountingEntries(String(companyId), valuePage)
                  }
                  tableStyle={{
                    width: `calc(${containerWidth}px - 80px)`,
                    // maxHeight: '650px',
                    // minHeight: '500px',
                    // borderBottom: '0px',
                  }}
                  tableContainerStyle={{
                    maxHeight: '650px',
                    // overflow: 'auto',
                  }}
                  totalPages={totalPages}
                  totalRegisters={totalRegisters}
                  currentPage={currentPage}
                  allowSelectAll={true}
                  loading={false}
                  setSearchQuery={setSearchQuery}
                  searchQuery={searchQuery}
                  showFilterButton={true}
                  showSearchButton={true}
                  setSelectedGridIds={setSelectedGridIds}
                  showNoResultsOverlay={false}
                  leftActions={
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        sx={{
                          color: '#4865CC',
                          '&.Mui-checked': {
                            color: '#4865CC',
                          },
                        }}

                        // checked={value.indexOf(option.value.toString()) > -1}
                      />
                      <ListItemText
                        primary="Mostrar apenas lançamentos classificados"
                        sx={{ marginLeft: '5px' }}
                      />
                    </Stack>
                  }
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
