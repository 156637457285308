import {
  AccountApiRemoteService,
  TAccountCSVImportTransactionsResponse,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object } from 'yup'

export class AccountCSVImportTransactionUseCase
  implements
    IFormUsecase<
      {
        file: File
        accountId: string
        companyId: string
        matchingColumns: { [key: string]: string }[]
      },
      TAccountCSVImportTransactionsResponse
    >
{
  constructor(private repository: AccountApiRemoteService) {}

  public async handle({
    file,
    accountId,
    companyId,
    matchingColumns,
  }: {
    file: File
    accountId: string
    companyId: string
    matchingColumns: { [key: string]: string }[]
  }): Promise<Result<TAccountCSVImportTransactionsResponse>> {
    // eslint-disable-next-line no-useless-catch
    const formatMatchingColumns = matchingColumns.reduce((acc, current) => {
      return { ...acc, ...current }
    }, {})

    try {
      const response = await this.repository.uploadCSVTransactions(
        file,
        companyId,
        accountId,
        JSON.stringify(formatMatchingColumns),
      )

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Houve um problema na validação das colunas do arquivo. Tente novamente.',
          ),
        )
      }

      const result = response.getValue()

      return Result.ok(result as TAccountCSVImportTransactionsResponse)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object()
}
