import {
  CompanyApiRemoteService,
  TCompanyInfoResponse,
  TCompanyUpdateDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class UpdateCompanyUseCase
  implements IFormUsecase<TCompanyUpdateDTO, TCompanyInfoResponse['companies']>
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle(
    data: TCompanyUpdateDTO,
  ): Promise<Result<TCompanyInfoResponse['companies']>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.companyApi.update(data)

      if (response.isFailure) {
        return Result.fail(response.error as Error)
      }

      return Result.ok(response.getValue() as TCompanyInfoResponse['companies'])
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    name: string().required('Nome é obrigatório'),
    cnpj: string().required('CNPJ é obrigatório'),
  })
}
