export class StorageLocalstorage {
  public static set(key: 'auth' | 'user' | 'currentCompany', value: any): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  public static get(key: 'auth' | 'user' | 'currentCompany'): any {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }
}
