export const UploadIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    fill="none"
    viewBox="0 0 19 18"
  >
    <path
      fill="#4865CC"
      d="M5 15c-.412 0-.766-.147-1.06-.441A1.442 1.442 0 013.5 13.5v-2.25H5v2.25h9v-2.25h1.5v2.25c0 .412-.147.766-.441 1.06-.294.294-.647.44-1.059.44H5zm3.75-3V5.888L6.8 7.838 5.75 6.75 9.5 3l3.75 3.75-1.05 1.088-1.95-1.95V12h-1.5z"
    ></path>
  </svg>
)
