import { AccountApiRemoteService } from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object } from 'yup'

export interface TAccountImportOfxFileResponse {
  isSuccess: boolean
  totalRegister: number
  totalImported: number
  totalFailed: number
  amount: string
}

export class AccountImportOfxFileUseCase
  implements
    IFormUsecase<
      { file: File; accountId: string; companyId: string },
      TAccountImportOfxFileResponse
    >
{
  constructor(private repository: AccountApiRemoteService) {}

  public async handle({
    file,
    accountId,
    companyId,
  }: {
    file: File
    accountId: string
    companyId: string
  }): Promise<Result<TAccountImportOfxFileResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.uploadOfxFile(
        file,
        companyId,
        accountId,
      )

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Houve um problema na importação do arquivo. Tente novamente.',
          ),
        )
      }

      const result = response.getValue()

      const responseData = {
        amount: '0',
        isSuccess: response.isSuccess,
        totalRegister: 0,
        totalImported: 0,
        totalFailed: 0,
      }

      let amount = 0

      result?.transactions.map((resultItem) => {
        responseData.totalRegister += 1
        if (resultItem.severity === 'ERROR') {
          responseData.totalFailed += 1
        } else {
          responseData.totalImported += 1
          if (resultItem.amount) {
            const convertAmount = parseFloat(
              resultItem.amount.replace(/[^\d.]/g, ''),
            )
            amount += convertAmount
          }
        }

        return responseData
      })

      responseData.amount = amount.toString()

      return Result.ok(responseData as TAccountImportOfxFileResponse)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object()
}
