export const MockData = {
  companies: [
    {
      name: 'Empresa A',
      accounts: [
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
      ],
    },
    {
      name: 'Empresa B',
      accounts: [
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
      ],
    },
    {
      name: 'Empresa C',
      accounts: [
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
      ],
    },
    {
      name: 'Empresa D',
      accounts: [
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
        {
          id: Math.random(),
          amount: 'R$ 50.000,00',
          agency: '1234',
          account: '123456',
          bankName: 'Itau',
          bankLogo: '',
        },
      ],
    },
  ],
  legends: [
    {
      label: 'Entrada',
      color: '#5ED1A2',
    },
    {
      label: 'Saída',
      color: '#DB2D25',
    },
    {
      label: 'Variação',
      color: '#FCD24C',
    },
  ],
  extract: {
    all: [
      {
        date: '23/10',
        type: 'inside',
        name: 'Transferência TED',
        value: 'R$ 50,00',
      },
      {
        date: '23/10',
        type: 'inside',
        name: 'Importação de extrato',
        value: 'R$ 500,00',
      },
      {
        date: '23/10',
        type: 'outside',
        name: 'Transferência TED',
        value: '-R$ 50,00',
      },
      {
        date: '23/10',
        type: 'outside',
        name: 'Transferência TED',
        value: '-R$ 50,00',
      },
      {
        date: '23/10',
        type: 'outside',
        name: 'Transferência TED',
        value: '-R$ 50,00',
      },
    ],
  },
}
