import { ErrorOutlineOutlined } from '@mui/icons-material'
import {
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from '@mui/material'
import { useMemo } from 'react'
import ReactInputMask from 'react-input-mask'

export type BaseInputProps = {
  setState: (value: string) => void
  error: string | null
  label: string
  value: string
  disabled?: boolean
  type?: string
  fullWidth?: boolean
  mask?: string
  iconEnd?: React.ReactNode
  iconStart?: React.ReactNode
  multiline?: boolean
  lines?: number
  labelBackground?: string
  containerStyle?: SxProps<Theme>
  min?: number
  max?: number
}

export const BaseInput = ({
  setState,
  error,
  label,
  value,
  type,
  fullWidth,
  mask,
  iconEnd,
  iconStart,
  lines,
  multiline,
  labelBackground,
  disabled = false,
  containerStyle = {},
  min,
  max,
}: BaseInputProps) => {
  const theme = useTheme()

  const customStyles: any = useMemo(() => {
    return iconStart
      ? {
          '& .MuiOutlinedInput-input': {
            padding: '18.5px 16px 18.5px 0px',
          },
          '& .MuiInputLabel-root': {
            backgroundColor: labelBackground || 'transparent',
            paddingRight: '4px',
            width: 'auto',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
          },
        }
      : {
          '& .MuiInputLabel-root': {
            backgroundColor: labelBackground || 'transparent',
            paddingRight: '4px',
            width: 'auto',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'clip',
          },
        }
  }, [iconStart, labelBackground])

  return (
    <TextField
      sx={{
        ...customStyles,
        ...(containerStyle as SxProps<Theme>),
      }}
      disabled={disabled}
      variant="outlined"
      type={type || 'text'}
      label={label}
      fullWidth={fullWidth}
      multiline={multiline ?? undefined}
      rows={lines ?? undefined}
      value={value}
      onChange={(event) => setState(event.target.value)}
      inputProps={{
        min: type === 'number' ? min : undefined,
        max: type === 'number' ? max : undefined,
      }}
      InputProps={{
        inputComponent: mask ? (ReactInputMask as any) : undefined,
        inputProps: mask
          ? {
              mask,
            }
          : undefined,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : undefined,
        endAdornment: error ? (
          <InputAdornment position="end">
            <ErrorOutlineOutlined style={{ color: theme.palette.error.main }} />
          </InputAdornment>
        ) : iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : undefined,
      }}
      helperText={error}
      error={!!error}
    />
  )
}
