import { AccountApiRemoteService } from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class DeleteBankAccountUseCase
  implements
    IFormUsecase<{ data: { companyId: string; accountId: string } }, void>
{
  constructor(private repository: AccountApiRemoteService) {}

  public async handle({
    data,
  }: {
    data: {
      companyId: string
      accountId: string
    }
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.delete({
        accountId: data.accountId,
        companyId: data.companyId,
      })

      if (response.isFailure) {
        return response
      }
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companyId: string().required('Empresa é obrigatória'),
  })
}
