import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BaseMultipleSelectInput } from 'src/component/base-component/base-multiple-select'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ColorCircle } from 'src/component/base-component/colored-circle'
import { DefaultHeader } from 'src/component/header/header'
import { MenuDotIcon } from 'src/component/icons/menu-dot'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { UserFullContext } from 'src/context/context-user'
import BlueHeader from '../../assets/background/header-blue.png'
import { useConciliator } from './useConciliator'
import { BaseInput } from 'src/component/base-component/base-input'
import SearchIcon from '@mui/icons-material/Search'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// interface PageProps {}

export function ConciliatorList() {
  const { companyId } = useParams()
  const { GetCompanyData } = UserFullContext()

  const {
    isLoading,
    conciliated,
    despised,
    notConciliated,
    partialConciliated,
    setConciliated,
    setDespised,
    setNotConciliated,
    setPartialConciliated,
  } = useConciliator(companyId as string)

  const [selectedOptions, setselectedOptions] = useState<string[]>([])
  const options = [
    {
      label: 'Receitas de vendas',
      value: '1',
    },
    {
      label: 'Receitas diversas',
      value: '2',
    },
    {
      label: 'Alimentação',
      value: '3',
    },
    {
      label: 'Materiais',
      value: '4',
    },
  ]

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          {
            title: GetCompanyData().name as string,
            navigateTo: `/company/${GetCompanyData().id}`,
          },
          {
            title: 'Conciliador',
          },
        ]}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>

            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <MenuDotIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        padding="32px"
        gap="24px"
        overflow="hidden"
      >
        <Stack width="100%" height="calc(100% - 64px)" gap="24px">
          <BaseInternalHeader
            leftComponent={<BasePageTitle color="#1E3466" text="Conciliador" />}
          />
          <Stack
            flexDirection="row"
            width="calc(100% - 64px)"
            alignItems="center"
            gap="24px"
          >
            <Stack width="280px">
              <BaseMultipleSelectInput
                options={options}
                setState={(e) => setselectedOptions(e)}
                error=""
                value={selectedOptions}
                label="Conta contábil"
              />
            </Stack>
            <Button
              variant="contained"
              sx={{ width: '92px', height: '40px', marginTop: '17px' }}
            >
              Buscar
            </Button>
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
              gap="24px"
            >
              <Button
                variant="outlined"
                sx={{ width: '260px', height: '40px' }}
              >
                Conciliar automaticamente
              </Button>
              <Button
                variant="outlined"
                sx={{ width: '128px', height: '40px' }}
              >
                Exportar
              </Button>
            </Stack>
          </Stack>

          <Stack
            width="calc(100% - 64px)"
            height="100%"
            justifyContent="flex-start"
          >
            {(isLoading || selectedOptions.length === 0) && (
              <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  fontWeight="600"
                  fontSize="16px"
                  color="#000000"
                  textAlign="center"
                >
                  Selecione uma ou mais contas contábeis para visualizar os
                  lançamentos
                </Typography>
              </Stack>
            )}
            {!isLoading && selectedOptions.length > 0 && (
              <Stack
                width="100%"
                height="100%"
                direction="column"
                gap="24px"
                justifyContent="flex-start"
              >
                <Stack
                  height="40px"
                  width="100%"
                  gap="24px"
                  direction="row"
                  justifyContent="flex-start"
                >
                  <Stack
                    gap="8px"
                    width="auto"
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <Checkbox
                      sx={{
                        color: '#4865CC',
                        '&.Mui-checked': {
                          color: '#4865CC',
                        },
                      }}
                      value={conciliated}
                      defaultChecked
                      onChange={(e, checked) => {
                        e.stopPropagation()
                        setConciliated(checked)
                      }}
                    />
                    <ColorCircle color="#03B575" width={8} height={8} />
                    <Typography
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="19.07px"
                    >
                      Conciliados
                    </Typography>
                  </Stack>

                  <Stack
                    gap="8px"
                    width="auto"
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <Checkbox
                      sx={{
                        color: '#4865CC',
                        '&.Mui-checked': {
                          color: '#4865CC',
                        },
                      }}
                      value={partialConciliated}
                      defaultChecked
                      onChange={(e, checked) => {
                        e.stopPropagation()
                        setPartialConciliated(checked)
                      }}
                    />
                    <ColorCircle color="#E7B92D" width={8} height={8} />
                    <Typography
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="19.07px"
                    >
                      Parcialmente conciliados
                    </Typography>
                  </Stack>

                  <Stack
                    gap="8px"
                    width="auto"
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <Checkbox
                      sx={{
                        color: '#4865CC',
                        '&.Mui-checked': {
                          color: '#4865CC',
                        },
                      }}
                      value={notConciliated}
                      defaultChecked
                      onChange={(e, checked) => {
                        e.stopPropagation()
                        setNotConciliated(checked)
                      }}
                    />
                    <ColorCircle color="#DB2D25" width={8} height={8} />
                    <Typography
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="19.07px"
                    >
                      Não conciliados
                    </Typography>
                  </Stack>

                  <Stack
                    gap="8px"
                    width="auto"
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <Checkbox
                      sx={{
                        color: '#4865CC',
                        '&.Mui-checked': {
                          color: '#4865CC',
                        },
                      }}
                      value={despised}
                      defaultChecked
                      onChange={(e, checked) => {
                        e.stopPropagation()
                        setDespised(checked)
                      }}
                    />
                    <ColorCircle color="#B9BFC7" width={8} height={8} />
                    <Typography
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="19.07px"
                    >
                      Desprezados
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  width="100%"
                  height="100%"
                  direction="row"
                  gap="24px"
                  sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  <Stack
                    width="50%"
                    height="50vh"
                    sx={{
                      backgroundColor: '#ffffff',
                      borderBottomLeftRadius: '16px',
                      borderBottomRightRadius: '16px',
                    }}
                  >
                    <Stack
                      width="100%"
                      direction="row"
                      height="64px"
                      sx={{
                        backgroundColor: '#4865CC',
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                      }}
                    >
                      <Stack
                        width="100%"
                        padding="12px"
                        direction="row"
                        alignItems="center"
                        gap="24px"
                      >
                        <Typography
                          fontWeight="700"
                          fontSize="16px"
                          color="#ffffff"
                          textAlign="center"
                        >
                          Débito
                        </Typography>

                        <Stack
                          width="100%"
                          justifyContent="flex-end"
                          alignItems="center"
                          gap="24px"
                          direction="row"
                        >
                          <Stack
                            width="165px"
                            height="40px"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="24px"
                            border="1px solid #ffffff"
                          >
                            <BaseInput
                              fullWidth
                              type="text"
                              label={'Buscar'}
                              setState={() => console.log('press.me')}
                              value={''}
                              error={null}
                              iconEnd={<SearchIcon sx={{ color: '#FFFFFF' }} />}
                              containerStyle={{
                                color: '#FFFFFF',
                              }}
                            />
                          </Stack>
                          <Button
                            variant="outlined"
                            sx={{
                              minWidth: '123px',
                              height: '40px',
                              color: '#FFFFFF',
                              border: '1px solid #FFFFFF',
                            }}
                            onClick={() => console.log('press me')}
                          >
                            <Typography
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="16.41px"
                            >
                              Período
                            </Typography>
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      width="100%"
                      direction="row"
                      height="56px"
                      sx={{ backgroundColor: '#F9FDFE' }}
                    >
                      <Stack
                        width="100%"
                        padding="12px"
                        alignItems="center"
                        justifyContent="center"
                        direction="row"
                        gap="24px"
                      >
                        <Stack
                          alignItems="center"
                          width="140px"
                          direction="row"
                          gap="8px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <UndoOutlinedIcon sx={{ color: '#B9BFC7' }} />
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                            color="#B9BFC7"
                          >
                            Desconciliar
                          </Typography>
                        </Stack>

                        <Stack
                          alignItems="center"
                          width="140px"
                          direction="row"
                          gap="8px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <DeleteOutlinedIcon sx={{ color: '#B9BFC7' }} />
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                            color="#B9BFC7"
                          >
                            Desprezar
                          </Typography>
                        </Stack>

                        <Stack
                          width="180px"
                          direction="row"
                          alignItems="center"
                          gap="8px"
                          sx={{ color: '#4865CC', cursor: 'pointer' }}
                        >
                          <AddOutlinedIcon />
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Criar lançamento
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      height="56px"
                      sx={{
                        backgroundColor: '#5ED1A2',
                      }}
                    >
                      <Stack
                        width="70%"
                        padding="12px"
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                        gap="56px"
                        sx={{
                          color: '#FFFFFF',
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Data
                          </Typography>
                          <FilterListOutlinedIcon />
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Valor
                          </Typography>
                          <FilterListOutlinedIcon />
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Histórico
                          </Typography>
                          <FilterListOutlinedIcon />
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack
                      width="100%"
                      height="calc(100% - 176px)"
                      sx={{ overflowY: 'auto', overflowX: 'hidden' }}
                    >
                      <Stack
                        width="100%"
                        height="auto"
                        borderBottom="1px dashed #CCCCCC"
                      >
                        <Stack
                          width="calc(100% - 40px)"
                          height="100%"
                          padding="20px"
                          gap="32px"
                        >
                          <Typography
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="19.07px"
                          >
                            Despesas Gerais
                          </Typography>

                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#03B575"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/01/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 500,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#E7B92D"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/08/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 600,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack
                        width="100%"
                        height="auto"
                        borderBottom="1px dashed #CCCCCC"
                      >
                        <Stack
                          width="calc(100% - 40px)"
                          height="100%"
                          padding="20px"
                          gap="32px"
                        >
                          <Typography
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="19.07px"
                          >
                            Despesas Gerais
                          </Typography>

                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#03B575"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/01/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 500,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#E7B92D"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/08/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 600,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack
                    width="50%"
                    height="50vh"
                    sx={{
                      backgroundColor: '#ffffff',
                      borderBottomLeftRadius: '16px',
                      borderBottomRightRadius: '16px',
                    }}
                  >
                    <Stack
                      width="100%"
                      direction="row"
                      height="64px"
                      sx={{
                        backgroundColor: '#4865CC',
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                      }}
                    >
                      <Stack
                        width="100%"
                        padding="12px"
                        direction="row"
                        alignItems="center"
                        gap="24px"
                      >
                        <Typography
                          fontWeight="700"
                          fontSize="16px"
                          color="#ffffff"
                          textAlign="center"
                        >
                          Débito
                        </Typography>

                        <Stack
                          width="100%"
                          justifyContent="flex-end"
                          alignItems="center"
                          gap="24px"
                          direction="row"
                        >
                          <Stack
                            width="165px"
                            height="40px"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="24px"
                            border="1px solid #ffffff"
                          >
                            <BaseInput
                              fullWidth
                              type="text"
                              label={'Buscar'}
                              setState={() => console.log('press.me')}
                              value={''}
                              error={null}
                              iconEnd={<SearchIcon sx={{ color: '#FFFFFF' }} />}
                              containerStyle={{
                                color: '#FFFFFF',
                              }}
                            />
                          </Stack>
                          <Button
                            variant="outlined"
                            sx={{
                              minWidth: '123px',
                              height: '40px',
                              color: '#FFFFFF',
                              border: '1px solid #FFFFFF',
                            }}
                            onClick={() => console.log('press me')}
                          >
                            <Typography
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="16.41px"
                            >
                              Período
                            </Typography>
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      width="100%"
                      direction="row"
                      height="56px"
                      sx={{ backgroundColor: '#F9FDFE' }}
                    >
                      <Stack
                        width="100%"
                        padding="12px"
                        alignItems="center"
                        justifyContent="center"
                        direction="row"
                        gap="24px"
                      >
                        <Stack
                          alignItems="center"
                          width="140px"
                          direction="row"
                          gap="8px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <UndoOutlinedIcon sx={{ color: '#B9BFC7' }} />
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                            color="#B9BFC7"
                          >
                            Desconciliar
                          </Typography>
                        </Stack>

                        <Stack
                          alignItems="center"
                          width="140px"
                          direction="row"
                          gap="8px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <DeleteOutlinedIcon sx={{ color: '#B9BFC7' }} />
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                            color="#B9BFC7"
                          >
                            Desprezar
                          </Typography>
                        </Stack>

                        <Stack
                          width="180px"
                          direction="row"
                          alignItems="center"
                          gap="8px"
                          sx={{ color: '#4865CC', cursor: 'pointer' }}
                        >
                          <AddOutlinedIcon />
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Criar lançamento
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      height="56px"
                      sx={{
                        backgroundColor: '#5ED1A2',
                      }}
                    >
                      <Stack
                        width="70%"
                        padding="12px"
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                        gap="56px"
                        sx={{
                          color: '#FFFFFF',
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Data
                          </Typography>
                          <FilterListOutlinedIcon />
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Valor
                          </Typography>
                          <FilterListOutlinedIcon />
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="16.41px"
                          >
                            Histórico
                          </Typography>
                          <FilterListOutlinedIcon />
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack
                      width="100%"
                      height="calc(100% - 176px)"
                      sx={{ overflowY: 'auto', overflowX: 'hidden' }}
                    >
                      <Stack
                        width="100%"
                        height="auto"
                        borderBottom="1px dashed #CCCCCC"
                      >
                        <Stack
                          width="calc(100% - 40px)"
                          height="100%"
                          padding="20px"
                          gap="32px"
                        >
                          <Typography
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="19.07px"
                          >
                            Despesas Gerais
                          </Typography>

                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#03B575"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/01/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 500,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#E7B92D"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/08/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 600,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack
                        width="100%"
                        height="auto"
                        borderBottom="1px dashed #CCCCCC"
                      >
                        <Stack
                          width="calc(100% - 40px)"
                          height="100%"
                          padding="20px"
                          gap="32px"
                        >
                          <Typography
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="19.07px"
                          >
                            Alimentação
                          </Typography>

                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#03B575"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/01/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 500,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                          <Stack
                            width="100%"
                            gap="12px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              gap="12px"
                              direction="row"
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{
                                  color: '#4865CC',
                                  '&.Mui-checked': {
                                    color: '#4865CC',
                                  },
                                }}
                              />
                              <ColorCircle
                                color="#E7B92D"
                                width={8}
                                height={8}
                              />
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                01/08/2024
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                R$ 600,00
                              </Typography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              direction="row"
                              gap="16px"
                            >
                              <Typography
                                fontWeight={400}
                                fontSize="14px"
                                lineHeight="19.07px"
                              >
                                Pagamento materiais
                              </Typography>
                              <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
                              <EditOutlinedIcon sx={{ color: '#4865CC' }} />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack width="100%" gap="16px">
                  <Typography
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="21.79px"
                    color="#03B575"
                  >
                    Não existem diferenças para os valores selecionados
                  </Typography>

                  <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    borderRadius="16px"
                    width="100%"
                    height="65px"
                    sx={{ backgroundColor: '#FFFFFF' }}
                  >
                    <Typography
                      marginLeft="16px"
                      fontWeight="700"
                      fontSize="24px"
                      lineHeight="32.69px"
                      color="#1E3466"
                    >
                      Saldo: R$ 457,99
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
