import { Stack } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import { useEffect, useRef } from 'react'
import Chart from 'react-apexcharts'

export interface IVariationData {
  x: string
  y: number[] | string[]
}
interface Props {
  series: {
    name: string
    data: IVariationData[]
  }[]
  categories: string[]
  min: number
  max: number
}

const VariationsChart = ({ series, max, min, categories }: Props) => {
  const chartRef = useRef<any>(null)
  const chartLineRef = useRef<any>(null)

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    series,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%', // Ajusta a largura das colunas
      },
    },
    xaxis: {
      categories,
      type: 'category',
    },
    yaxis: {
      max, // Define o valor máximo do eixo y
      min,
    },
    colors: ['#03B575', '#DB2D25'],
    grid: {
      show: false,
    },
  }

  const optionsLine: ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'sales',
        data: [{ x: 15, y: -3 }],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight', // Alterado de 'smooth' para 'straight'
    },
    title: {
      text: 'Product Trends by Month',
      align: 'left',
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      min: 15,
      max: 40,
      labels: {
        show: false,
      },
    },
  }

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = new ApexCharts(chartRef.current, options)
      chartInstance.render()
    }
    if (chartLineRef.current) {
      const chartInstance = new ApexCharts(chartLineRef.current, optionsLine)
      chartInstance.render()
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
      if (chartLineRef.current) {
        chartLineRef.current.destroy()
      }
    }
  }, [])

  return (
    <Stack id="chart" width="100%" position="relative">
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
        }}
      >
        <Chart
          options={options}
          series={options.series}
          type="bar"
          height={350}
        />
      </div>
      {/* <WrapperLineChart
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
          width: '100%',
        }}
      >
        <Chart
          options={options}
          series={options.series}
          type="line"
          height={350}
        />
      </WrapperLineChart> */}
    </Stack>
  )
}

export { VariationsChart }
