import { Button, Stack, Typography } from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import { useState } from 'react'
import { BaseDateSelect } from '../base-component/base-date-select'
import { ToastFullContext } from '../base-component/base-snackbar'

export const ModalSelectPriod: React.FC<{
  onSelect: (dates: string[]) => void
  setInitialDate: (data: Date | undefined) => void
  setFinalDate: (data: Date | undefined) => void
  initialDate: Date | undefined
  finalDate: Date | undefined
}> = ({ onSelect, finalDate, initialDate, setFinalDate, setInitialDate }) => {
  const { ShowToast } = ToastFullContext()
  const [internalControlInitialDate, setInternalControlIInitialDate] = useState<
    Date | undefined
  >(initialDate)
  const [internalControlIFinalDate, setInternalControlIFinalDate] = useState<
    Date | undefined
  >(finalDate)

  const handleSelectDate = (e: Date | undefined, type: 'initial' | 'final') => {
    if (type === 'initial') {
      setInitialDate(e)
      setInternalControlIInitialDate(e)
      return
    }

    setFinalDate(e)
    setInternalControlIFinalDate(e)
  }

  const handleSubmit = () => {
    if (!internalControlIFinalDate || !internalControlInitialDate) return
    if (
      !!internalControlIFinalDate &&
      isAfter(internalControlInitialDate, internalControlIFinalDate)
    ) {
      return ShowToast(
        'error',
        'A Data inicial não pode ser maior do que a data final',
      )
    }
    if (
      !!internalControlInitialDate &&
      isBefore(internalControlIFinalDate, internalControlInitialDate)
    ) {
      return ShowToast(
        'error',
        'A Data final não pode ser menor do que a data inicial',
      )
    }
    const dates = [
      internalControlInitialDate.toString(),
      internalControlIFinalDate.toString(),
    ]
    return onSelect && onSelect(dates)
  }

  const handleReset = () => {
    setFinalDate(undefined)
    setInitialDate(undefined)
    onSelect([])
  }

  return (
    <Stack width="450px" height="160px">
      <Typography mt="-10px">
        Selecione o período que gostaria de visualizar
      </Typography>

      <Stack
        direction="row"
        paddingBottom="12px"
        marginTop="20px"
        justifyContent="space-between"
      >
        <BaseDateSelect
          title="Data inicial"
          onSelect={(e) => handleSelectDate(e, 'initial')}
          selectedDate={internalControlInitialDate}
          useCalendarPosition={false}
        />

        <BaseDateSelect
          title="Data final"
          onSelect={(e) => handleSelectDate(e, 'final')}
          selectedDate={internalControlIFinalDate}
          useCalendarPosition={false}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        gap="12px"
        marginTop="20px"
      >
        <Button
          variant="outlined"
          sx={{ width: '135px', height: '40px', padding: 0 }}
          onClick={handleReset}
        >
          <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
            Limpar filtro
          </Typography>
        </Button>

        <Button
          variant="contained"
          sx={{ width: '100px', height: '40px', padding: 0 }}
          disabled={!internalControlIFinalDate && !internalControlInitialDate}
          onClick={handleSubmit}
        >
          <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
            Aplicar
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
