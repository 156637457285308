import { ErrorOutlineOutlined, Search } from '@mui/icons-material'
import {
  InputAdornment,
  MenuItem,
  TextField,
  useTheme,
  Checkbox,
  ListItemText,
  Stack,
  ListSubheader,
  styled,
  InputBase,
} from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'

const containsText = (text: string, searchText: string) =>
  text
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\./g, '')
    .toLowerCase()
    .indexOf(
      searchText
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\./g, '')
        .toLowerCase(),
    ) > -1

export const SearchInput = styled(InputBase)(({ theme }) => ({
  marginTop: '12px',
  background: 'transparent',
  borderRadius: '12px',
  border: `1px solid ${theme.palette.primary.main}`,
  '& .MuiInputBase-formControl': {
    background: theme.palette.primary.main,
  },
  '& .MuiInputBase-input': {
    padding: '12px 16px',
    fontSize: theme.typography.labelSmall,
    color: theme.palette.primary.main,
  },
  '& .MuiInputAdornment-root': {
    marginRight: 8,
  },
  '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
    color: '#1E1E1E',
  },
  '& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon': {
    color: '#1E1E1E',
  },
  '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon.Mui-disabled': {
    color: '#1E1E1E',
  },
}))

export type BaseInputProps = {
  setState: (value: string[]) => void
  error: string | null
  label?: string
  value: string[]
  fullWidth?: boolean
  options: { value: string | number; label: string; icon?: React.ReactNode }[]
  iconEnd?: React.ReactNode
  iconStart?: React.ReactNode
  labelBackground?: string
  allowSearchValues?: boolean
}

export const BaseMultipleSelectInput = ({
  setState,
  error,
  label,
  value,
  fullWidth,
  options,
  iconEnd,
  iconStart,
  labelBackground,
  allowSearchValues = false,
}: BaseInputProps) => {
  const theme = useTheme()
  const [searchText, setSearchText] = useState('')
  const searchTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setSearchText(newValue)

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    searchTimeout.current = setTimeout(() => {
      setSearchText(newValue)
    }, 1000)
  }

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [options, searchText],
  )

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = event.target.value as string[]
    setState(selectedValues)
  }

  return (
    <TextField
      sx={
        iconStart
          ? {
              '& .MuiOutlinedInput-input': {
                padding: '18.5px 16px 18.5px 0px',
              },
              '& .MuiInputLabel-root': {
                backgroundColor: labelBackground || 'transparent',
                paddingRight: '4px',
              },
              '& .MuiMenu-paper': {
                marginTop: '12px',
              },
            }
          : {
              '& .MuiInputLabel-root': {
                backgroundColor: labelBackground || 'transparent',
                paddingRight: '4px',
              },
              '& .MuiMenu-paper': {
                marginTop: '12px',
              },
            }
      }
      variant="outlined"
      label={label}
      fullWidth={fullWidth}
      select
      value={value}
      onChange={handleChange}
      SelectProps={{
        multiple: true,
        renderValue: (selected) =>
          (selected as string[])
            .map(
              (selectedValue) =>
                options.find((option) => option.value === selectedValue)?.label,
            )
            .join(', '),
      }}
      InputProps={{
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : undefined,
        endAdornment: error ? (
          <InputAdornment position="end">
            <ErrorOutlineOutlined style={{ color: theme.palette.error.main }} />
          </InputAdornment>
        ) : iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : undefined,
      }}
      helperText={error}
      error={!!error}
    >
      {allowSearchValues && (
        <ListSubheader>
          <SearchInput
            size="small"
            autoFocus
            placeholder="Buscar"
            fullWidth
            aria-pressed
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            onChange={handleSearchChange}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation()
              }
            }}
          />
        </ListSubheader>
      )}

      {displayedOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option?.icon ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <Stack direction="row">
                {option?.icon}
                <ListItemText
                  primary={option.label}
                  sx={{ marginLeft: '10px' }}
                />
              </Stack>

              <Checkbox
                sx={{
                  color: '#4865CC',
                  '&.Mui-checked': {
                    color: '#4865CC',
                  },
                }}
                checked={value.indexOf(option.value.toString()) > -1}
              />
            </Stack>
          ) : (
            <>
              <Checkbox
                sx={{
                  color: '#4865CC',
                  '&.Mui-checked': {
                    color: '#4865CC',
                  },
                }}
                checked={value.indexOf(option.value.toString()) > -1}
              />
              <ListItemText primary={option.label} />
            </>
          )}
        </MenuItem>
      ))}
    </TextField>
  )
}
