import {
  AccountApiRemoteService,
  IBankAccountOpenFinanceFinalFlowResponse,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class UpdateAccountOpenFinanceDisconnectUseCase
  implements
    IUsecase<
      {
        companyId: string
        accountId: string
      },
      void
    >
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle({
    accountId,
    companyId,
  }: {
    companyId: string
    accountId: string
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.openFinanceDisconnect({
        companyId,
        accountId,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Ocorreu um erro ao desconectar da Open Finance. Tente novamente mais tarde',
          ),
        )
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
