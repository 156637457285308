export const TotalizerPlanIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    fill="none"
    viewBox="0 0 48 49"
  >
    <circle cx="24" cy="24" r="24" fill="#5ED1A2"></circle>
    <path
      stroke="#fff"
      d="M19.845 32.564l-.001.001h0zm1.478 2.01h-.034l-.034.005a1.695 1.695 0 01-1.448-.503 1.538 1.538 0 01.251-1.445l.024-.03.019-.034a.8.8 0 00.108-.344.566.566 0 00-.28-.533.59.59 0 00-.544-.01.947.947 0 00-.28.21h0l-.004.004c-.52.563-1.03 1.137-1.526 1.722-.544.631-.724.836-.806.91l-.023.021-.02.024a.318.318 0 01-.368.084.271.271 0 01-.115-.295l.006-.021.003-.021.01-.042c.005-.028.014-.064.024-.11l.088-.36a855.417 855.417 0 011.117-4.363l-.92-.37c-.715 1.268-2.896 4.926-3.254 5.526l-.045.076a.122.122 0 01-.223-.033l-.485.122.485-.122a.124.124 0 01-.003-.048l-.494-.072.494.072a.124.124 0 01.016-.045h0l.002-.003.001-.001a.784.784 0 01.01-.016l.027-.047.102-.17.357-.6c.296-.497.69-1.163 1.097-1.85.806-1.363 1.673-2.838 1.873-3.223l.009-.018.008-.018a.767.767 0 01.941-.431.669.669 0 01.372.822l-.005.015-.004.014c-.32 1.235-.646 2.5-.898 3.487l.862.451c.408-.47.824-.943 1.12-1.247l.012-.014.012-.014a1.188 1.188 0 011.39-.33.552.552 0 01.28.602c-.069.24-.167.47-.292.686l-.005.01-.005.01a1.35 1.35 0 00-.113.895.96.96 0 00.37.554c.205.148.456.212.729.217h6.945a.122.122 0 01.086.208.122.122 0 01-.086.036h-6.936zM15.61 16.29h13.222v.422H15.611v-.422zm0 7.111H21.8v.422H15.61V23.4zm0-3.556h11.963l-.422.423h-11.54v-.423z"
    ></path>
    <path
      fill="#fff"
      stroke="#fff"
      d="M34.167 28.264v9.57a.389.389 0 01-.114.274l.353.354-.353-.354a.389.389 0 01-.275.114H10.667a.39.39 0 01-.39-.389V11.167a.389.389 0 01.39-.39h23.11a.388.388 0 01.39.39v3.404a4.33 4.33 0 00-.447.385l-.002.002-.33.329v-3.732H11.057v25.89h22.333v-9.098c.262.005.523-.023.778-.083z"
    ></path>
    <path
      fill="#fff"
      stroke="#fff"
      d="M38.01 19.424l-.027-.027-.465-.465-.308-.308.262-.348a1.187 1.187 0 00-.05-1.494 1.239 1.239 0 00-1.72.12l-.01.01-.01.01-11.038 11.037-.581 2.183 2.176-.588 10.298-10.3.354-.353.354.354.438.44s0 0 0 0a.83.83 0 010 1.174m.327-1.445l-.68 1.091m.68-1.091a1.396 1.396 0 00-.027-.028s0 0 0 0l.352-.353m-.325.38l.826.827v-.182a1.753 1.753 0 00-.499-1.025s0 0 0 0h0-.002m-.652 1.826l-.353-.354m.353.354h0l-.353-.354m.353.354l-4.37 4.37m4.017-4.724l-4.37 4.37m.353.354l-.354-.353m.354.353h0l-.354-.353m.354.353l-.354-.353m3.932-5.278l.353.354h0l-.353-.354zm0 0l-.354.354h0l.354-.354zm1.444-.565h0l-.464-.465c.107-.14.19-.296.247-.46l-.282-.283v1.208h.5zm.001 2.477l-.353-.353s0 0 0 0l.353.353z"
    ></path>
  </svg>
)
