import {
  AuthenticationApiRemoteService,
  TAuthRecoveryEmailDTO,
  TAuthRecoveryWithEmailDTO,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, ref, string } from 'yup'

export class RecoveryWithEmailUseCase
  implements IFormUsecase<TAuthRecoveryWithEmailDTO, void>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(data: TAuthRecoveryWithEmailDTO): Promise<Result<void>> {
    try {
      const response = await this.repository.recoveryWithEmailStart(data)

      if (response.isFailure) {
        return response
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    email: string().required('Email é obrigatório').email('Email inválido'),
  })
}
