import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import { ModalFullContext } from './modal-provider'

export const ImportExtractSuccessModal: React.FC<{
  totalRegister: number
  totalImported: number
  totalFailed: number
  finalBalance: string
}> = ({ totalFailed, totalImported, finalBalance }) => {
  const theme = useTheme()
  const { HideModal } = ModalFullContext()

  return (
    <Stack
      width="400px"
      height="280px"
      gap="16px"
      justifyContent="center"
      alignItems="center"
    >
      <CheckCircleIcon
        sx={{
          width: '48px',
          height: '48px',
          color: theme.palette.success.main,
        }}
      />
      <Typography
        fontSize="24px"
        fontWeight={700}
        maxWidth="320px"
        textAlign="center"
      >
        Importação de extrato concluída com sucesso!
      </Typography>

      <Stack
        width="100%"
        gap="16px"
        justifyContent="flex-start"
        alignItems="center"
        maxWidth="350px"
        direction="row"
      >
        <CheckCircleOutlineOutlinedIcon
          sx={{ width: 16, height: 16, color: theme.palette.success.dark }}
        />
        <Typography fontSize="14px" fontWeight={400} color="#51515">
          {totalImported > 0
            ? `${totalImported} lançamentos importados, sendo ${totalImported} novos lançamentos`
            : 'Nehum novo lançamento importado!'}
        </Typography>
      </Stack>

      <Stack
        width="100%"
        gap="16px"
        justifyContent="flex-start"
        alignItems="center"
        maxWidth="350px"
        direction="row"
      >
        <CancelOutlinedIcon
          sx={{ width: 16, height: 16, color: theme.palette.error.main }}
        />
        <Typography fontSize="14px" fontWeight={400} color="#51515">
          {`${totalFailed} lançamentos não importados`}
        </Typography>
      </Stack>

      <Stack
        width="100%"
        gap="16px"
        justifyContent="flex-start"
        alignItems="center"
        maxWidth="350px"
        direction="row"
      >
        <CheckCircleOutlineOutlinedIcon
          sx={{ width: 16, height: 16, color: theme.palette.success.dark }}
        />
        <Typography fontSize="14px" fontWeight={400} color="#51515">
          {`Saldo final informado foi de ${new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(finalBalance))}`}
        </Typography>
      </Stack>

      <Stack
        width="100%"
        gap="16px"
        justifyContent="center"
        alignItems="center"
        maxWidth="350px"
        direction="row"
      >
        <Button
          fullWidth={false}
          variant="contained"
          onClick={HideModal}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Entendi
        </Button>
      </Stack>
    </Stack>
  )
}
