import React from 'react'

interface LogoProps {
  bankName: string
  width?: number
  height?: number
}

const logos = [
  'banco-da-amazonia.svg',
  'banco-do-brasil.svg',
  'banco-santander.svg',
  'brankofamerica.svg',
  'banrisul.svg',
  'bradesco.svg',
  'btg-pactual.svg',
  'c6-bank.svg',
  'caixa-economica-federal-1.svg',
  'inter.svg',
  'itau-unibanco-sa.svg',
  'lgo-ailos.svg',
  'logo-safra.svg',
  'logo-sicredi-preto.svg',
  'mercado-pago.svg',
  'nubank-logo-2021.svg',
  'sicoob-minimalista-com.svg',
  'stone.svg',
]

const getInitials = (name: string) => {
  const words = name.split(' ')
  if (words.length === 1) {
    return name.substring(0, 2).toUpperCase()
  }
  return words[0][0] + (words[1] ? words[1][0] : '').toUpperCase()
}

export const extractBankName = (description: string): string => {
  const dashIndex = description.indexOf('-')

  if (dashIndex === -1) return ''

  const bankName = description.substring(dashIndex + 1).trim() // Remove espaços no início e fim

  return bankName
}

const normalizeBankNameUtil = (name: string): string => {
  return name
    .normalize('NFD') // Normaliza para decomposição canônica
    .replace(/[\u0300-\u036f]/g, '') // Remove acentuação
    .toLowerCase() // Converte para minúsculas
    .replace(/[^a-z0-9\s-]/g, '') // Remove caracteres especiais
    .replace(/\s+/g, '-') // Substitui múltiplos espaços por hífen
    .replace(/-+$/, '') // Remove hífens no final
}

export const GetBankLogo: React.FC<LogoProps> = ({
  bankName,
  width = 48,
  height = 48,
}) => {
  console.log('1', bankName)
  const normalizedBankName = normalizeBankNameUtil(bankName)
  console.log('2', normalizedBankName)
  const logoFile = logos.find((logo) => logo.includes(normalizedBankName))

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: logoFile ? 'none' : '1px solid red',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: logoFile ? `url(/path/to/logos/${logoFile})` : 'none',
      }}
    >
      {logoFile && <img src={logoFile} className="App-logo" alt="logo" />}
      {!logoFile && (
        <span style={{ color: 'red', fontWeight: 'bold' }}>
          {getInitials(bankName)}
        </span>
      )}
    </div>
  )
}
