import { Stack } from '@mui/material'
import styled from 'styled-components'

export const Header = styled(Stack)`
  display: flex;
  justify-content: flex-start;
  height: 140px;
  width: 100%;
  opacity: 0.8;
  background-image: url('../../assets/background/background-login.png');
  background-size: cover;
  background-position: center;
`

export const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  color: #1e3466;
  width: 60px;
  height: 60px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  gap: 8px;
`
