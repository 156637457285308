import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { EditOutlined } from '@mui/icons-material'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TermsOfUseModal } from 'src/component/modal/modal-terms-of-use'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { BaseRadio } from 'src/component/base-component/base-radio'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { useNavigate } from 'react-router-dom'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
import { ContactUsUseCase } from 'src/usecase/authentication/usecase-authentication-form-contact-us'

interface ContactUsPageProps {
  useCase: ContactUsUseCase
}

export function ContactUsPage(props: ContactUsPageProps): any {
  const { ShowModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()

  const theme = useTheme()
  const navigate = useNavigate()

  const loginForm = CreateForm({
    fields: ['name', 'phone', 'email', 'message'],
    validation: props.useCase.validation,
  })

  const handleContactUsUsecase = async () => {
    try {
      const usecaseResult = await props.useCase.handle({
        name: loginForm.fields.name.value,
        phone: loginForm.fields.phone.value,
        email: loginForm.fields.email.value,
        message: loginForm.fields.message.value,
      })

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao enviar o formulário. Tente novamente mais tarde.',
        )
      }

      ShowToast(
        'success',
        'Finalize no seu aplicativo de email do seu sistema.',
      )
      navigate('/auth/sign-in')
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao enviar o formulário. Tente novamente mais tarde.',
      )
    }
  }

  const handleContactUs = async () => {
    try {
      const validationResult = await loginForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      handleContactUsUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao enviar o formulário. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate('/auth/sign-in')
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Fale conosco
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '64px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: 'max-content',
              gap: '32px',
              width: '100%',
            }}
          >
            <BaseInput label="Nome" fullWidth {...loginForm.fields.name} />
            <BaseInput label="Telefone" fullWidth {...loginForm.fields.phone} />
            <BaseInput label="Email" fullWidth {...loginForm.fields.email} />
            <BaseInput
              label="Mensagem"
              fullWidth
              multiline
              lines={4}
              {...loginForm.fields.message}
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: 'max-content',
              gridAutoFlow: 'column',
              gap: '24px',
              marginTop: '48px',
              width: 'max-content',
              justifySelf: 'end',
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button variant="contained" fullWidth onClick={handleContactUs}>
              Enviar
            </Button>
          </Box>
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
