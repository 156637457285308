import {
  AccountApiRemoteService,
  IBankAccount,
  TAccountUpdateDTO,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class UpdateAccountUseCase
  implements IFormUsecase<TAccountUpdateDTO, IBankAccount>
{
  constructor(private repository: AccountApiRemoteService) {}

  public async handle(data: TAccountUpdateDTO): Promise<Result<IBankAccount>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.update(data)

      if (response.isFailure) {
        return response
      }
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    name: string().required('Defina um nome para a nova conta'),
    personType: string().required('Tipo de pessoa é obrigatório'),
    bankNumber: string().required('A instituição financeira é obrigatória'),
    agencyNumber: string().required('A agência bancária é obrigatória'),
    accountNumber: string().required(
      'O número da conta bancária é obrigatório',
    ),
  })
}
