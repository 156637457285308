import {
  Box,
  Checkbox,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import {
  CompanyUserPermissionTypeEnum,
  CompanyUserPermissionTypeEnumUtil,
} from 'src/service/service-company'
import { DeleteTableIcon } from '../icons/delete'
import { EditTableIcon } from '../icons/edit'
import { UsersIcon } from '../icons/users'
import { WalletIcon } from '../icons/wallet'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'

export type CompanyUserCardProps = {
  id: string
  name: string
  userName: string
  permission: CompanyUserPermissionTypeEnum
  isSelected: boolean
  editButtonAction?: (companyUserId: string) => void
  deleteButtonAction?: (companyUserId: string) => void
  handleSelectionChange: (companyUserId: string) => void
}

export const CompanyUserCard = ({
  id,
  name,
  userName,
  permission,
  isSelected,
  editButtonAction,
  deleteButtonAction,
  handleSelectionChange,
}: CompanyUserCardProps) => {
  const theme = useTheme()
  return (
    <Stack
      key={`account-card-${id}`}
      width="267px"
      height="250px"
      borderRadius="8px"
      border="1px solid #B9BFC7"
      position="relative"
    >
      {/* Header */}
      <Stack
        padding="12px 24px"
        width="calc(100% - 32px)"
        alignItems="flex-start"
      >
        <Stack
          gap="12px"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Typography
            key={`account-card-name-${id}`}
            fontWeight="700"
            fontSize="16px"
          >
            {name}
          </Typography>
          <Checkbox
            sx={{
              color: '#4865CC',
              '&.Mui-checked': {
                color: '#4865CC',
              },
            }}
            onClick={() => handleSelectionChange(id)}
            checked={isSelected}
          />
        </Stack>
        <Typography
          key={`account-card-username-${id}`}
          fontWeight="400"
          color="#777C84"
          fontSize="12px"
        >
          @{userName}
        </Typography>
      </Stack>
      <Divider sx={{ margin: '24px', marginTop: '0px', marginBottom: '0px' }} />
      {/* Body */}
      <Stack gap="24px" padding="24px">
        <Stack width="100%" gap="12px" alignItems="flex-start">
          <Stack
            width="100%"
            direction="row"
            gap="12px"
            alignItems="flex-start"
          >
            <PeopleOutlinedIcon sx={{ color: theme.palette.primary.main }} />

            <Typography
              display="flex"
              key={`account-card-permission-title-${id}`}
              fontWeight="600"
              fontSize="14px"
              gap="12px"
            >
              Tipo de usuário
            </Typography>
          </Stack>
          <Typography
            display="flex"
            key={`account-card-person-${id}`}
            fontWeight="400"
            fontSize="14px"
            gap="12px"
          >
            {CompanyUserPermissionTypeEnumUtil.toString(permission)}
          </Typography>
        </Stack>
      </Stack>
      {/* Footer */}
      <Stack width="100%" gap="8px" justifyContent="center" alignItems="center">
        <Stack
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap="36px"
          color="#4865CC"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <EditTableIcon />
            <Typography
              key={`account-card-edit-${id}`}
              fontWeight="600"
              fontSize="14px"
              onClick={() => editButtonAction && editButtonAction(id)}
            >
              Editar
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <DeleteTableIcon />
            <Typography
              key={`account-card-exclude-${id}`}
              fontWeight="600"
              fontSize="14px"
              onClick={() => deleteButtonAction && deleteButtonAction(id)}
            >
              Excluir
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
