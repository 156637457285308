import { ArrowBack } from '@mui/icons-material'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { CompanyUserCard } from 'src/component/company/user-card'
import { DefaultHeader } from 'src/component/header/header'
import { DeleteTableIcon } from 'src/component/icons/delete'
import {
  CompanyCreateUsersModal,
  TModalAddUserType,
} from 'src/component/modal/company/modal-company-create-users'
import { CompanyEditUserModal } from 'src/component/modal/company/modal-company-edit-user'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import {
  CompanyUserPermissionTypeEnum,
  ICompanyUser,
  TCompanyUserCreateDTO,
} from 'src/service/service-company'
import { TListUsersResponse } from 'src/service/service-user'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { CompanyUseDetailsUseCase } from 'src/usecase/company/usecase-company-details'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import BlueHeader from '../../assets/background/header-blue.png'
import SearchIcon from '@mui/icons-material/Search'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { SimpleTextModal } from 'src/component/modal/simple-modal-text'

interface CompanyUsersEditPageProps {
  companyUseDetailsUseCase: CompanyUseDetailsUseCase
  addUserToCompanyUseCase: CreateCompanyUsersUseCase
  listUsersUseCase: UserListUseCase
}

export function CompanyUsersEditPage(props: CompanyUsersEditPageProps): any {
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([])
  const { companyId: currentCompanyId } = useParams()
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [usersData, setUsersData] = useState<TListUsersResponse['users'][]>([])
  const [ownerId, setOwnerId] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleAddUsersToCompany = async (
    users: {
      userId: string
      permission: CompanyUserPermissionTypeEnum
    }[],
  ) => {
    setIsLoading(true)
    const concatUsers = companyUsers.map((user) => {
      return {
        userId: user.user.id,
        permission: user.permission,
      }
    })

    users.map((user) => {
      return concatUsers.push(user)
    })

    const data = {
      companyId: currentCompanyId,
      users: concatUsers,
    } as TCompanyUserCreateDTO

    return await props.addUserToCompanyUseCase.handle(data)
  }

  const handleEditCompanyUser = async (userToChange: {
    userId: string
    permission: CompanyUserPermissionTypeEnum
  }) => {
    setIsLoading(true)
    const remapUsers = companyUsers.map((user) => {
      return {
        userId: user.user.id,
        permission:
          user.user.id === userToChange.userId
            ? userToChange.permission
            : user.permission,
      }
    })

    const data = {
      companyId: currentCompanyId,
      users: remapUsers,
    } as TCompanyUserCreateDTO

    return await props.addUserToCompanyUseCase.handle(data)
  }

  const handleListCompanyUsers = async () => {
    try {
      setIsLoading(true)
      const result = await props.companyUseDetailsUseCase.handle({
        companyId: currentCompanyId as string,
      })

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro retornar os dados dos usuários da empresa. Tente novamente mais tarde.',
        )
        setIsLoading(false)
        return navigate('/home')
      }
      const data = result.getValue()
      setOwnerId(data?.owner.id as string)
      setCompanyUsers(data?.users ?? [])

      const usersData = await props.listUsersUseCase.handle()

      if (usersData.isFailure) {
        return
      }

      const users = usersData.getValue()
      setUsersData(users as TListUsersResponse['users'][])
      setIsLoading(false)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro retornar os dados dos usuários da empresa. Tente novamente mais tarde.',
      )
      setIsLoading(false)
      return navigate('/home')
    }
  }

  const handleHideAndRefetchCompanyUsers = async () => {
    await handleListCompanyUsers()
    setIsLoading(false)
    HideModal()
  }

  const handleAddUsersModal = () => {
    const modalUsers = usersData
      .filter(
        (f) =>
          f.id !== ownerId && !companyUsers.find((obj) => obj.user.id === f.id),
      )
      .map((user) => {
        return {
          id: user.id,
          name: user.name,
          username: user.username,
          alreadyConfiguredUser: false,
          permission: CompanyUserPermissionTypeEnum.ANALISTA,
        } as TModalAddUserType
      })

    ShowModal({
      content: (
        <CompanyCreateUsersModal
          users={modalUsers}
          handle={handleAddUsersToCompany}
          handleHideAndRefetchCompanyUsers={handleHideAndRefetchCompanyUsers}
        />
      ),
      title: 'Adicionar usuário',
      closeButton: false,
    })
  }

  const handleEditUserModal = (user: ICompanyUser) => {
    ShowModal({
      content: (
        <CompanyEditUserModal
          user={user}
          handle={handleEditCompanyUser}
          handleHideAndRefetchCompanyUsers={handleHideAndRefetchCompanyUsers}
        />
      ),
      title: 'Editar usuário',
      closeButton: false,
    })
  }

  useEffect(() => {
    handleListCompanyUsers()
  }, [currentCompanyId])

  const handleRemoveCompanyUser = async (userIds: string[]) => {
    setIsLoading(true)
    const remapUsers = companyUsers
      .filter((f) => !userIds.includes(f.user.id))
      .map((user) => {
        return {
          userId: user.user.id,
          permission: user.permission,
        }
      })

    const data = {
      companyId: currentCompanyId,
      users: remapUsers,
    } as TCompanyUserCreateDTO

    return props.addUserToCompanyUseCase.handle(data)
  }

  const handleDeleteCompanyUser = async (companyUserId?: string) => {
    let usersToRemove: string[] = []

    if (companyUserId) {
      usersToRemove = [companyUserId]
    } else {
      usersToRemove = selectedUsers
    }

    if (usersToRemove.length === 0) {
      return ShowToast('error', 'Nenhum usuário selecionado para remoção.')
    }

    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Excluir"
          firstButtonAction={() => HideModal()}
          secondButtonAction={async () => {
            await handleRemoveCompanyUser(usersToRemove)
            HideModal()
            setIsLoading(false)
            handleListCompanyUsers()
          }}
          text="Deseja realmente excluir o usuário?"
          height={120}
          width={270}
        />
      ),
      title: 'Excluir usuário',
      closeButton: false,
    })
  }

  const handleSelectionChange = async (companyUserId: string) => {
    if (selectedUsers.includes(companyUserId)) {
      const newArray = selectedUsers.filter((id) => id !== companyUserId)
      return setSelectedUsers(newArray)
    } else {
      const newArray = [...selectedUsers]
      newArray.push(companyUserId)
      return setSelectedUsers(newArray)
    }
  }

  return (
    <Stack
      width="100%"
      height="100vh"
      sx={{ backgroundColor: '#F4F8FA' }}
      gap="24px"
    >
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${currentCompanyId}` },
          {
            title: 'Editar Empresa',
            navigateTo: `/company/${currentCompanyId}/edit`,
          },
          { title: 'Gerência de Usuários' },
        ]}
      />
      <Stack direction="column" gap="24px" sx={{ overflow: 'hidden' }}>
        {isLoading && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && (
          <Stack
            width="100%"
            height="90%"
            gap="36px"
            padding="24px"
            paddingTop="0px"
          >
            <Stack width="100%">
              <BaseInternalHeader
                leftComponent={
                  <Button
                    variant="text"
                    startIcon={<ArrowBack />}
                    onClick={() =>
                      navigate(`/company/${currentCompanyId}/edit`)
                    }
                  >
                    Voltar
                  </Button>
                }
              />
              <Stack
                width="calc(100% - 48px)"
                height="100%"
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                alignContent="center"
              >
                <Typography fontWeight="700" fontSize="32px" color="#1E1E1E">
                  Gerência de usuários
                </Typography>

                <Stack gap="24px" direction="row">
                  <Button
                    fullWidth={false}
                    variant="contained"
                    onClick={handleAddUsersModal}
                    sx={{
                      padding: '12px 24px 12px 16px',
                      gap: '8px',
                      width: '240px',
                      height: '56px',
                      direction: 'row',
                    }}
                  >
                    <AddOutlinedIcon />
                    Adicionar usuário
                  </Button>
                  <Box width="280px">
                    <BaseInput
                      fullWidth
                      labelBackground="#F4F8FA"
                      type="text"
                      label="Buscar"
                      setState={(event) => {
                        if (!event) {
                          return setSearchQuery && setSearchQuery('')
                        }

                        setSearchQuery && setSearchQuery(event)
                      }}
                      value={searchQuery ?? ''}
                      error={null}
                      iconStart={<SearchIcon />}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              width="calc(100% - 48px)"
              height="100vh"
              gap="24px"
              direction="row"
              flexWrap="wrap"
              sx={{ overflowY: 'auto', overflowX: 'hidden' }}
            >
              {!isLoading &&
                companyUsers.length > 0 &&
                companyUsers
                  .filter((f) =>
                    searchQuery
                      ? f.user.username
                          .toLowerCase()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      : !!f.id,
                  )
                  .map((user) => {
                    return (
                      <CompanyUserCard
                        id={user.id}
                        isSelected={selectedUsers.includes(user.id)}
                        name={user.user.name}
                        userName={user.user.username}
                        permission={user.permission}
                        deleteButtonAction={() =>
                          handleDeleteCompanyUser(user.user.id)
                        }
                        editButtonAction={() => handleEditUserModal(user)}
                        handleSelectionChange={handleSelectionChange}
                        key={user.id}
                      />
                    )
                  })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
