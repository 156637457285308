import { useState } from 'react'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { IBankAccount } from 'src/service/service-account'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'

export function useUserAccount(
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase,
) {
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState(false)
  const [newAccount, setNewAccount] = useState<Partial<IBankAccount> | null>(
    null,
  )
  const [userCompaniesAccounts, setUserCompaniesAccounts] = useState<
    IBankAccount[]
  >([])

  const handleListUserCompaniesUsecase = async (companiesIds?: string[]) => {
    try {
      setIsLoading(true)
      const usecaseResult = await listUserCompaniesAccountsUseCase.handle({
        companiesIds,
      })
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro buscar as contas das empresas. Tente novamente mais tarde.',
        )
      }

      const companiesData = usecaseResult.getValue() as IBankAccount[]

      setUserCompaniesAccounts(companiesData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleListUserCompaniesAccounts = async (companiesIds?: string[]) => {
    try {
      handleListUserCompaniesUsecase(companiesIds)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas bancárias das empresas. Tente novamente mais tarde.',
      )
    }
  }

  return {
    isLoading,
    newAccount,
    setIsLoading,
    setNewAccount,
    userCompaniesAccounts,
    handleListUserCompaniesAccounts,
  }
}
