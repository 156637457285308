import {
  AuthenticationApiRemoteService,
  TAuthRecoveryEmailDTO,
  TAuthRecoveryWithPinDTO,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, ref, string } from 'yup'

export class RecoveryWithPinUseCase
  implements IFormUsecase<TAuthRecoveryWithPinDTO, void>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(data: TAuthRecoveryWithPinDTO): Promise<Result<void>> {
    try {
      const response = await this.repository.recoveryWithPin(data)

      if (response.isFailure) {
        return response
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('Username é obrigatório'),
    pin: string()
      .required('PIN é obrigatório')
      .max(6, 'PIN deve ter 6 dígitos')
      .min(6, 'PIN deve ter 6 dígitos'),
    password: string()
      .required('Senha é obrigatória')
      .min(6)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])/,
        'Senha deve conter ao menos 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
      ),
    confirmPassword: string()
      .required('Confirmação de senha é obrigatória')
      .oneOf([ref('password')], 'Senhas não conferem'),
  })
}
