import { useState } from 'react'

export function useConciliator(companyId: string) {
  const [isLoading, setIsLoading] = useState(false)
  const [conciliated, setConciliated] = useState(false)
  const [partialConciliated, setPartialConciliated] = useState(false)
  const [notConciliated, setNotConciliated] = useState(false)
  const [despised, setDespised] = useState(false)

  return {
    isLoading,
    conciliated,
    partialConciliated,
    notConciliated,
    despised,
    setConciliated,
    setPartialConciliated,
    setNotConciliated,
    setDespised,
  }
}
