import { CompanyApiRemoteService } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class DeleteClassificationRuleUseCase
  implements
    IFormUsecase<
      { data: { companyId: string; classificationRuleId: string } },
      void
    >
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle({
    data,
  }: {
    data: {
      companyId: string
      classificationRuleId: string
    }
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.deleteClassificationRule({
        classificationRuleId: data.classificationRuleId,
        companyId: data.companyId,
      })

      if (response.isFailure) {
        return response
      }
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companyId: string().required('Empresa é obrigatória'),
  })
}
