export const UsersIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#4865CC"
      d="M13 11a3 3 0 100-5.999A3 3 0 0013 11zm0-4a1 1 0 110 2 1 1 0 010-2zm4.11 3.86a5 5 0 000-5.72A2.9 2.9 0 0118 5a3 3 0 010 6c-.302 0-.602-.047-.89-.14zM13 13c-6 0-6 4-6 4v2h12v-2s0-4-6-4zm-4 4c0-.29.32-2 4-2 3.5 0 3.94 1.56 4 2m7 0v2h-3v-2a5.601 5.601 0 00-1.8-3.94C24 13.55 24 17 24 17zM8 12H5v3H3v-3H0v-2h3V7h2v3h3v2z"
    ></path>
  </svg>
)
