import {
  AccountApiRemoteService,
  IBankAccount,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListUserCompaniesAccountsUseCase
  implements IUsecase<{ companiesIds?: string[] }, IBankAccount[]>
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle({
    companiesIds,
  }: {
    companiesIds?: string[]
  }): Promise<Result<IBankAccount[]>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response =
        await this.userApi.getUserAccountsByCompaniesIds(companiesIds)

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados das contas bancárias'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
