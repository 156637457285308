import { BankApiRemoteService } from 'src/service/service-bank'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class DeleteTransferImportUseCase
  implements
    IFormUsecase<
      {
        data: {
          companyId: string
          bankAccountId: string
          importedTransfersId: string
        }
      },
      void
    >
{
  constructor(private repository: BankApiRemoteService) {}

  public async handle({
    data,
  }: {
    data: {
      companyId: string
      bankAccountId: string
      importedTransfersId: string
    }
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.deleteBankAccountsTransferHistory({
        companyId: data.companyId,
        bankAccountId: data.bankAccountId,
        importedTransfersId: data.importedTransfersId,
      })

      if (response.isFailure) {
        return response
      }
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companyId: string().required('Empresa é obrigatória'),
    bankAccountId: string().required('Conta é obrigatória'),
    importedTransfersId: string().required('ID da importação é obrigatório'),
  })
}
