import {
  CompanyApiRemoteService,
  TCompanyCreateDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object } from 'yup'

export class CreateCompanyValidateFileUseCase
  implements IFormUsecase<any, TCompanyCreateDTO[]>
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle(file: File): Promise<Result<TCompanyCreateDTO[]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.uploadFile(file)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      return Result.ok(result!)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object()
}
