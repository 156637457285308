/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ToastFullContext } from 'src/component/base-component/base-snackbar';
import {
  IAccountingEntries,
  IListAccountingEntriesInformations, IListAccountingEntriesResponseDTO
} from 'src/service/service-company';


import { format } from 'date-fns';
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list';
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update';
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list';
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list';
import { useClassifierColumns } from './useClassifierColumns';

export function useClassifier(
  listAccoutingEntriesUseCase: ListAccountingEntriesUseCase,
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase,
  listCompanyVendorsUseCase: ListCompanyVendorsUseCase,
  listCompanyDebitsUseCase: ListCompanyDebitUseCase,
  companyId: string,
) {
  const { ShowToast } = ToastFullContext();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([]);
  const [initialDate, setInitialDate] = useState<Date | undefined>(undefined);
  const [finalDate, setFinalDate] = useState<Date | undefined>(undefined);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [accountingEntries, setAccountingEntries] = useState<
    IAccountingEntries[]
  >([]);
  const [informations, setInformations] = useState<IListAccountingEntriesInformations>()
  const [vendors, setVendors] = useState<string[]>([]);
  const [debits, setDebits] = useState<string[]>([]);
  const [credits, setCredits] = useState<string>('');
  const [columnsTableSelected, setColumnsTableSelected] = useState<string[]>([
    'date',
    'description',
    'debit',
    'credit',
    'value',
  ]);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSelectColumns = (column: string) => {
    const exists = columnsTableSelected.find(i => i === column);

    if (exists) {
      const filtered = columnsTableSelected.filter(i => i !== column);
      setColumnsTableSelected(filtered);
    } else {
      setColumnsTableSelected(prev => [...prev, column]);
    }
  };

  const handleSelectDate = (e: Date | undefined, type: 'initial' | 'final') => {
    console.log('data', type, e)
    if (type === 'initial') {
      setInitialDate(e);
      return;
    }

    setFinalDate(e);
  };
  
  const handleUpdateAccountingEntry = async ({
    entryId,
    oldValue,
    newValue,
    field,
  }: {
    entryId: string;
    oldValue: string;
    newValue: string;
    field: string;
  }) => {
    try {
      const normalizeNewValue = newValue === '' ? null : newValue;
      if (oldValue === normalizeNewValue) return;

      const usecaseResult = await updateAccountingEntriesUseCase.handle({
        companyId,
        entryId,
        field,
        value: newValue,
      });

      if (usecaseResult.isFailure) {
        console.log('ERRO 1: ', usecaseResult.error);
        ShowToast(
          'error',
          'Ocorreu um erro ao atualizar os dados do classificador. Tente novamente mais tarde.',
        );
      }
    } catch (error) {
      console.log('error: ', error);
      ShowToast('error', 'Ocorreu um erro ao buscar atualizar os dados.');
    }
  };

  const vendorsOptions = useMemo(() => {
    const payload = [
      { value: 'all', label: 'Selecionar tudo' },
      { value: 'none', label: 'Sem fornecedor' },
    ];
    if (vendors?.length) {
      const normalize = vendors?.map(vendor => ({
        value: vendor,
        label: vendor,
      }));
      return [...payload, ...normalize];
    } else {
      return payload;
    }
  }, [vendors]);

  const handleListAccountingEntries = useCallback(
    async (companyId: string, page: number) => {
      try {
        setIsLoading(true);
  
        // Chamada à API para obter os dados
        const usecaseResult = await listAccoutingEntriesUseCase.handle({
          companyId,
          page,
          dateStart: initialDate,
          dateEnd: finalDate,
          query: '',
          valueMaximum: maxValue,
          valueMinimum: minValue,
        });
  
        setIsLoading(false);
  
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue() as IListAccountingEntriesResponseDTO;
        const normalize: IAccountingEntries[] =
          transfersData?.companyAccountingEntries?.map(entry => ({
            id: entry.id,
            date: format(new Date(entry.date), 'dd/MM/yyyy'),
            description: entry?.bankTransferPartie?.name || '',
            supplier: '', // Se tiver informações de fornecedor, atualize aqui
            value: entry?.value,
            debit: entry?.debit,
            credit: entry?.credit,
            historic: entry?.historic,
            financialCategory: entry?.financialCategory,
          })) || [];
  
        if (normalize.length) {
          setAccountingEntries(prev => {
            const updated = [...prev, ...normalize];
            return updated;
          });
        } else {
          setAccountingEntries([])
        }
  
        setInformations(transfersData.informations)
        setCurrentPage(page);
        setTotalPages(transfersData.pages);
        setTotalRegisters(transfersData.count);
      } catch (error) {
        setIsLoading(false);
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    },
    [initialDate, finalDate, maxValue, minValue] 
  );

  const handleListCompanyVendors = async (companyId: string) => {
    try {
      setIsLoading(true);
      const usecaseResult = await listCompanyVendorsUseCase.handle(companyId);
      setIsLoading(false);

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar os dados dos clientes e fornecedores. Tente novamente mais tarde.',
        );
      }

      const value = usecaseResult.getValue();

      if (value) {
        setVendors(value);
      }
    } catch (error) {
      setIsLoading(false);
      ShowToast('error', 'Ocorreu um erro ao buscar o dados dos clientes e fornecedores.');
    }
  };

  const handleListCompanyDebit = async (companyId: string, query: string) => {
    try {
      setIsLoading(true);
      const usecaseResult = await listCompanyDebitsUseCase.handle({
        companyId,
        query,
      });
      setIsLoading(false);

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas de débito. Tente novamente mais tarde.',
        );
      }

      const value = usecaseResult.getValue();

      if (value) {
        setDebits(value);
      }
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(false);
      ShowToast('error', 'Ocorreu um erro ao buscar as contas de débito.');
    }
  };

  const { columns } = useClassifierColumns(
    searchQuery,
    vendorsOptions,
    minValue,
    maxValue,
    companyId,
    debits,
    credits,
    columnsTableSelected,
    initialDate,
    finalDate,
    setSearchQuery,
    setMinValue,
    setMaxValue,
    setDebits,
    setCredits,
    listCompanyDebitsUseCase,
    handleSelectDate,
    handleListAccountingEntries
  )

  return {
    searchQuery,
    columns,
    isLoading,
    selectedGridIds,
    setSearchQuery,
    setSelectedGridIds,
    setIsLoading,
    handleListAccountingEntries,
    accountingEntries,
    handleListCompanyVendors,
    handleListCompanyDebit,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    currentPage,
    informations,
  };
}
