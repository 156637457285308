import {
  TListUsersResponse,
  UserApiRemoteService,
} from 'src/service/service-user'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class UserListUseCase
  implements IUsecase<void, TListUsersResponse['users'][]>
{
  constructor(private userApi: UserApiRemoteService) {}

  public async handle(): Promise<Result<TListUsersResponse['users'][]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.listUsers()

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao recuperar dados do usuário'))
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar dados do usuário'))
      }

      return Result.ok(result as TListUsersResponse['users'][])
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
