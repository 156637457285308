export const TotalizerUsedTimeIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="50"
    fill="none"
    viewBox="0 0 48 50"
  >
    <circle cx="24" cy="24.5" r="24" fill="#5ED1A2"></circle>
    <path
      fill="#fff"
      stroke="#fff"
      d="M23.167 26.05v-7.217h1v6.785l.245.145 5.564 3.302-.478.784-6.331-3.799zM24 36.167a11.166 11.166 0 100-22.333 11.166 11.166 0 000 22.333zm0-24a12.832 12.832 0 019.075 21.908A12.833 12.833 0 0124 37.833c-7.096 0-12.833-5.775-12.833-12.833A12.833 12.833 0 0124 12.167z"
    ></path>
  </svg>
)
