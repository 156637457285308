export const ArrowDownIcon = (): JSX.Element => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50008 0.125C3.59953 0.125 3.69492 0.164509 3.76524 0.234835C3.83557 0.305161 3.87508 0.400544 3.87508 0.5V7.595L6.23508 5.235C6.26941 5.19816 6.31081 5.16861 6.35681 5.14811C6.40281 5.12761 6.45246 5.11659 6.50282 5.1157C6.55317 5.11482 6.60318 5.12408 6.64988 5.14294C6.69657 5.1618 6.73899 5.18987 6.7746 5.22548C6.8102 5.26109 6.83828 5.30351 6.85714 5.3502C6.876 5.3969 6.88526 5.44691 6.88437 5.49726C6.88348 5.54761 6.87246 5.59727 6.85197 5.64327C6.83147 5.68927 6.80192 5.73067 6.76508 5.765L3.76508 8.765C3.69476 8.83523 3.59945 8.87467 3.50008 8.87467C3.4007 8.87467 3.30539 8.83523 3.23508 8.765L0.235077 5.765C0.198233 5.73067 0.168682 5.68927 0.148186 5.64327C0.12769 5.59727 0.116669 5.54761 0.115781 5.49726C0.114893 5.44691 0.124155 5.3969 0.143015 5.3502C0.161876 5.30351 0.189948 5.26109 0.225558 5.22548C0.261167 5.18987 0.303584 5.1618 0.350278 5.14294C0.396972 5.12408 0.446987 5.11482 0.497338 5.1157C0.547689 5.11659 0.597346 5.12761 0.643346 5.14811C0.689345 5.16861 0.730746 5.19816 0.765077 5.235L3.12508 7.595V0.5C3.12508 0.400544 3.16459 0.305161 3.23491 0.234835C3.30524 0.164509 3.40062 0.125 3.50008 0.125Z"
      fill="#DB2D25"
    />
  </svg>
)
