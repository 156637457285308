import Search from '@mui/icons-material/Search'
import { Button, InputAdornment, Stack, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { BaseSwitch } from 'src/component/base-component/base-switch'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { CompanyUserPermissionTypeEnum } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { SearchInput } from '../../base-component/base-multiple-select'

export type TModalAddUserType = {
  id: string
  name: string
  username: string
  permission: CompanyUserPermissionTypeEnum
  alreadyConfiguredUser: boolean
}

export const CompanyCreateUsersModal: React.FC<{
  users: TModalAddUserType[]
  handle: (
    users: {
      userId: string
      permission: CompanyUserPermissionTypeEnum
    }[],
  ) => Promise<Result<void>>
  handleHideAndRefetchCompanyUsers: () => void
}> = ({ users, handle, handleHideAndRefetchCompanyUsers }) => {
  const { HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const [modalUsers, setModalUsers] = useState<TModalAddUserType[]>(users)
  const [searchText, setSearchText] = useState('')
  const searchTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setSearchText(newValue)

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    searchTimeout.current = setTimeout(() => {
      setSearchText(newValue)
    }, 1000)
  }

  const handleExecute = async () => {
    try {
      const usersToSend = modalUsers
        .filter((f) => f.alreadyConfiguredUser)
        .map((map) => {
          return {
            userId: map.id,
            permission: map.permission,
          }
        })

      const result = await handle(usersToSend)

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao adicionar o(s) usuário(s) na empresa.',
        )
        return
      }

      ShowToast('success', 'Usuário(s) adicionado(s) com sucesso na empresa.')
      handleHideAndRefetchCompanyUsers()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao adicionar o(s) usuário(s) na empresa.',
      )
    }
  }

  const handleChangeUserPermission = (
    userId: string,
    permission: CompanyUserPermissionTypeEnum,
  ) => {
    const tempUsers = modalUsers.map((user) =>
      user.id === userId ? { ...user, permission } : user,
    )

    setModalUsers(tempUsers)
  }

  const handleAddOrRemoveUser = (
    userId: string,
    alreadyConfiguredUser: boolean,
  ) => {
    const tempUsers = modalUsers.map((user) =>
      user.id === userId ? { ...user, alreadyConfiguredUser } : user,
    )

    setModalUsers(tempUsers)
  }

  const allowContinue =
    modalUsers
      .filter((f) => f.alreadyConfiguredUser)
      .map((map) => {
        return {
          userId: map.id,
          permission: map.permission,
        }
      })?.length > 0

  return (
    <Stack
      width="500px"
      height="auto"
      maxHeight="595px"
      gap="24px"
      justifyContent="space-between"
    >
      <Stack width="100%" height="90%" gap="24px">
        <Typography fontSize="14px" fontWeight={400} color="#1E1E1E">
          Selecione e configure o(s) usuário(s) que deseja adicionar a empresa
        </Typography>
        <SearchInput
          size="small"
          autoFocus
          placeholder="Buscar"
          fullWidth
          aria-pressed
          startAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
          sx={{ marginTop: '0px', borderRadius: '100px' }}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />

        {modalUsers?.filter((user) =>
          searchText
            ? user.username
                .toLowerCase()
                .toLowerCase()
                .includes(searchText.toLowerCase())
            : user.alreadyConfiguredUser,
        )?.length > 0 && (
          <Stack
            width="calc(100% - 32px)"
            minHeight="80px"
            maxHeight="310px"
            borderRadius="16px"
            border="1px solid #777C84"
            padding="16px"
            direction="column"
          >
            <Stack width="100" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
              {modalUsers
                ?.filter((user) =>
                  searchText
                    ? user.username
                        .toLowerCase()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    : user.alreadyConfiguredUser,
                )
                .map((user) => {
                  return (
                    <Stack
                      key={user.id}
                      width="100%"
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      @{user.username}
                      <Stack
                        width="45%"
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                          color: '#4865CC',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '100px',
                            borderColor: 'transparent !important',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent !important',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent !important',
                          },
                        }}
                      >
                        <BaseInputSingleSelect
                          placeholder=""
                          options={[
                            {
                              label: 'Analista',
                              value: CompanyUserPermissionTypeEnum.ANALISTA,
                            },
                            {
                              label: 'Master',
                              value: CompanyUserPermissionTypeEnum.MASTER,
                            },
                          ]}
                          showLabel={false}
                          value={user.permission}
                          onSelect={(e) =>
                            handleChangeUserPermission(user.id, e)
                          }
                          marginBottom={0}
                          marginTop={0}
                        />
                        <BaseSwitch
                          checked={user.alreadyConfiguredUser}
                          setState={(e) => handleAddOrRemoveUser(user.id, e)}
                          error={null}
                          label=""
                        />
                      </Stack>
                    </Stack>
                  )
                })}
            </Stack>
          </Stack>
        )}
      </Stack>

      <Stack
        direction="row"
        gap="24px"
        justifyContent="flex-end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => HideModal()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          disabled={!allowContinue}
          variant="contained"
          onClick={handleExecute}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Convidar
        </Button>
      </Stack>
    </Stack>
  )
}
