export const ExtractIcon = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.53906 7.0769V4.61536"
      stroke="#1E3466"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.07812 13.641C7.07812 14.8718 8.17762 15.2821 9.53967 15.2821C10.9017 15.2821 12.0012 15.2821 12.0012 13.641C12.0012 11.1795 7.07812 11.1795 7.07812 8.71793C7.07812 7.0769 8.17762 7.0769 9.53967 7.0769C10.9017 7.0769 12.0012 7.7005 12.0012 8.71793"
      stroke="#1E3466"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53906 15.2821V17.7436"
      stroke="#1E3466"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2058 1.33337H4.61605C3.74559 1.33337 2.91078 1.67916 2.29528 2.29467C1.67977 2.91017 1.33398 3.74498 1.33398 4.61544V22.6668L5.43656 20.2052L9.53914 22.6668L13.6417 20.2052L17.7443 22.6668V3.79492C17.7443 3.14208 18.0036 2.51597 18.4653 2.05434C18.9269 1.59271 19.553 1.33337 20.2058 1.33337C20.8587 1.33337 21.4848 1.59271 21.9464 2.05434C22.408 2.51597 22.6674 3.14208 22.6674 3.79492V9.53853H17.7443"
      stroke="#1E3466"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
