import {
  TProfileUpdateDTO,
  TUserProfileResponse,
  UserApiRemoteService,
} from 'src/service/service-user'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class UpdateProfileUseCase
  implements IFormUsecase<TProfileUpdateDTO, TUserProfileResponse['user']>
{
  constructor(private userApi: UserApiRemoteService) {}

  public async handle(
    data: TProfileUpdateDTO,
  ): Promise<Result<TUserProfileResponse['user']>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.update(data)

      if (response.isFailure) {
        return Result.fail(response.error as Error)
      }

      return Result.ok(response.getValue() as TUserProfileResponse['user'])
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    name: string().required('Nome é um campo obrigatório'),
    username: string().required('Username é obrigatório'),
    email: string().notRequired(),
    password: string()
      .optional()
      .test(
        'is-empty-or-valid',
        'Senha deve ter no mínimo 6 caracteres e conter ao menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
        (value) => {
          if (!value) return true
          return (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])/.test(value) &&
            value.length >= 6
          )
        },
      ),
    oldPassword: string().optional(),
  })
}
