import {
  AccountApiRemoteService,
  TAccountCreateDTO,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class CreateAccountStepFourUseCase
  implements IFormUsecase<TAccountCreateDTO, void>
{
  constructor(private repository: AccountApiRemoteService) {}

  public async handle(data: TAccountCreateDTO): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    description: string().required('Descrição da conta é obrigatória'),
  })
}
