import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { EditOutlined, ArrowBack } from '@mui/icons-material'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { useEffect, useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TermsOfUseModal } from 'src/component/modal/modal-terms-of-use'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { BaseRadio } from 'src/component/base-component/base-radio'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { useNavigate } from 'react-router-dom'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
// import { RecoveryWithEmailCompleteUseCase } from 'src/usecase/authentication/usecase-authentication-form-contact-us'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { object } from 'yup'
import { RecoveryWithEmailCompleteUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-with-email-complete'

interface RecoveryWithEmailCompletePageProps {
  useCase: RecoveryWithEmailCompleteUseCase
}

export function RecoveryWithEmailCompletePage(
  props: RecoveryWithEmailCompletePageProps,
): any {
  const { ShowToast } = ToastFullContext()

  const navigate = useNavigate()
  const theme = useTheme()

  const recoveryEmailForm = CreateForm({
    fields: ['password', 'confirmPassword', 'code', 'email'],
    validation: props.useCase.validation,
  })
  const [loading, setLoading] = useState(false)

  const handleRecoveryWithEmailCompleteUsecase = async () => {
    try {
      setLoading(true)
      const usecaseResult = await props.useCase.handle({
        code: recoveryEmailForm.fields.code.value,
        password: recoveryEmailForm.fields.password.value,
        email: recoveryEmailForm.fields.email.value,
      })
      if (usecaseResult.isFailure) {
        setLoading(false)
        switch ((usecaseResult.error as any)?.type) {
          case 'UserNotFoundError':
            ShowToast('error', 'Usuário não encontrado')
            break

          default:
            ShowToast(
              'error',
              'Ocorreu um erro ao recuperar sua senha. Tente novamente mais tarde.',
            )
            break
        }
        return
      }
      setLoading(false)
      ShowToast('success', 'Senha alterada com sucesso!')
      navigate('/auth/sign-in')
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao recuperar sua senha. Tente novamente mais tarde.',
      )
    }
  }

  const handleRecoveryWithEmailComplete = async () => {
    try {
      const validationResult = await recoveryEmailForm.validate()

      if (validationResult.isFailure) {
        ShowToast(
          'error',
          validationResult?.error?.message ??
            'Verifique se informou todos os campos corretamente.',
        )
        return
      }

      handleRecoveryWithEmailCompleteUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao recuperar sua senha. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const code = urlParams.get('code')
    const email = urlParams.get('email')

    recoveryEmailForm.fields.code.setState(code)
    recoveryEmailForm.fields.email.setState(email)

    if (!code || !email) {
      ShowToast('error', 'Código ou email não informados na URL')
      navigate('/auth/sign-in')
    }
  }, [])

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Recuperar a senha
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '64px',
            width: '100%',
            gap: '32px',
          }}
        >
          <BaseInput
            label="Digite a nova senha"
            type="password"
            fullWidth
            {...recoveryEmailForm.fields.password}
          />
          <BaseInput
            label="Repetir senha"
            type="password"
            fullWidth
            {...recoveryEmailForm.fields.confirmPassword}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '32px',
            width: '100%',
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: 'grid',
                justifyItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              fullWidth
              onClick={handleRecoveryWithEmailComplete}
            >
              Cadastrar nova senha
            </Button>
          )}
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
