import {
  AuthenticationApiRemoteService,
  TAuthLoginDTO,
  TAuthLoginResponse,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { StorageLocalstorage } from 'src/shared/storage/storage-localstorage'
import { object, string } from 'yup'
import { Api } from '../../service/service-api'

export class LoginUseCase
  implements IFormUsecase<TAuthLoginDTO, TAuthLoginResponse>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(
    data: TAuthLoginDTO,
  ): Promise<Result<TAuthLoginResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.login(data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao realizar login'))
      }

      const { token, refresh } = result

      StorageLocalstorage.set('auth', {
        token,
        refresh,
      })

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('Username é obrigatório'),
    password: string()
      .required('Senha é obrigatória')
      .min(6, 'Senha deve ter no mínimo 6 caracteres'),
  })
}
