import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { useEffect, useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { useNavigate } from 'react-router-dom'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { UserFullContext } from 'src/context/context-user'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
import { BaseSwitch } from 'src/component/base-component/base-switch'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NewBackupCodeAfterRecoveryPageProps {
  userOwnData: UserOwnDataUseCase
}

export function NewBackupCodeAfterRecoveryPage(
  props: NewBackupCodeAfterRecoveryPageProps,
): any {
  const navigate = useNavigate()

  const [backupcode, setBackupcode] = useState('')

  useEffect(() => {
    props.userOwnData.handle().then((response) => {
      if (response.isSuccess) {
        const data = response.getValue()
        if (data) {
          setBackupcode(data.backupCode)
        }
      }
    })
  }, [])

  const handleContinue = () => {
    navigate('/auth/sign-in')
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Cadastro
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            marginTop: '8px',
            textAlign: 'center',
          }}
        >
          Seu cadastro de nova senha foi realizado com sucesso e um novo PIN foi
          gerado. Para sua segurança anote em local seguro. Ele poderá ser
          solicitado.
        </Typography>
        <Box
          sx={{
            marginTop: '64px',
          }}
        >
          <BaseInputCode
            length={6}
            value={backupcode}
            setState={() => console.log('ok')}
            error={null}
          />
        </Box>
        <Box
          sx={{
            margin: '64px 54.5px 0px 54.5px',
            width: '100%',
          }}
        >
          <Button fullWidth variant="contained" onClick={handleContinue}>
            Entrar
          </Button>
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
