import { Box, Button, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { BaseInputSelect } from '../base-component/base-input-select'

export const ModalReportsExport: React.FC<{
  handle: (selectedFormat: string, fullContent: boolean) => void
}> = ({ handle }) => {
  const { HideModal } = ModalFullContext()
  const [infoType, setInfoType] = useState<string>('Painel completo')
  const [formatType, setFormatType] = useState<string>('PDF')

  const handleConfirm = () => {
    handle(formatType, infoType === 'Painel completo')
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '45vw',
          height: '30vh',
          maxHeight: '400px',
          maxWidth: '488px',
          overflow: 'auto',

          borderRadius: '16px',
        }}
      >
        <Typography>Quais informações você gostaria de exportar?</Typography>

        <BaseInputSelect
          showCheckbox={false}
          onSelect={setInfoType}
          value={infoType}
          options={['Painel completo', 'Somente extrato']}
        />

        <Typography>Qual formato de exportaçao?</Typography>

        <BaseInputSelect
          showCheckbox={false}
          onSelect={setFormatType}
          value={formatType}
          showLabel={false}
          options={['PDF', 'Excel', 'OFX']}
        />
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleConfirm()
            HideModal()
          }}
          sx={{ width: '108px', height: '40px' }}
        >
          Exportar
        </Button>
      </Box>
    </Box>
  )
}
