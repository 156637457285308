import { Button, Stack } from '@mui/material'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ModalFullContext } from 'src/component/modal/modal-provider'

export const SyncSystemicAccountsModal: React.FC<{
  accounts: string[]
  currentSelectedAccount: string
  setLedgerAccount: (value: React.SetStateAction<string>) => void
  handle: (selectedAccount: string) => void
}> = ({ accounts, currentSelectedAccount, setLedgerAccount, handle }) => {
  const { HideModal } = ModalFullContext()

  const handleClose = () => {
    HideModal()
  }

  const handleSync = (account: string) => {
    if (!account) {
      return
    }
    handle && handle(account as string)
  }

  return (
    <Stack width="500px" height={'150px'} gap="16px">
      <BaseInputSingleSelect
        placeholder="Conta contábil"
        options={accounts.map((item) => {
          return {
            label: item,
            value: item,
          }
        })}
        showLabel={false}
        value={currentSelectedAccount}
        onSelect={(e) => setLedgerAccount(e)}
        marginBottom={0}
        marginTop={0}
      />
      <Stack
        direction="row"
        gap="16px"
        marginTop="24px"
        justifyContent="end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleClose()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          variant={
            [null, undefined, ''].includes(currentSelectedAccount)
              ? 'outlined'
              : 'contained'
          }
          disabled={[null, undefined, ''].includes(currentSelectedAccount)}
          onClick={() => handleSync(currentSelectedAccount)}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Vincular
        </Button>
      </Stack>
    </Stack>
  )
}
