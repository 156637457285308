import {
  AccountApiRemoteService,
  IBankAccount,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCompanyAccountUseCase
  implements IUsecase<{ companyId: string; accountId: string }, IBankAccount>
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle({
    companyId,
    accountId,
  }: {
    companyId: string
    accountId: string
  }): Promise<Result<IBankAccount>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.getCompanyAccount(
        companyId,
        accountId,
      )

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados das contas bancárias'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
