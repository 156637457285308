import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { useEffect, useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { useNavigate } from 'react-router-dom'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { UserFullContext } from 'src/context/context-user'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
import { BaseSwitch } from 'src/component/base-component/base-switch'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BackupCodePageProps {
  userOwnData: UserOwnDataUseCase
}

export function BackupCodePage(props: BackupCodePageProps): any {
  const navigate = useNavigate()

  const [backupcode, setBackupcode] = useState('')
  const [email, setEmail] = useState(false)
  const [auth2fa, setAuth2FA] = useState(false)

  useEffect(() => {
    props.userOwnData.handle().then((response) => {
      if (response.isSuccess) {
        const data = response.getValue()
        if (data) {
          setBackupcode(data.backupCode)
        }
      }
    })
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleContinue = () => {
    if (email) {
      if (auth2fa) {
        navigate('/auth/recovery/setup/email?2fa=true')
      } else {
        navigate('/auth/recovery/setup/email')
      }
    } else if (auth2fa) {
      navigate('/auth/recovery/setup/2fa')
    } else {
      navigate('/auth/sign-in')
    }
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '549px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Cadastro
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            width: '467px',
            marginTop: '8px',
            textAlign: 'center',
          }}
        >
          Seu cadastro foi realizado com sucesso. Para sua segurança anote, em
          local seguro, o PIN abaixo. Ele poderá ser solicitado.
        </Typography>
        <Box
          sx={{
            marginTop: '64px',
          }}
        >
          <BaseInputCode
            length={6}
            value={backupcode}
            setState={() => console.log('ok')}
            error={null}
          />
        </Box>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            marginTop: '64px',
            justifySelf: 'start',
          }}
        >
          Escolha seu método de segurança
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gap: '32px',
            gridAutoRows: 'max-content',
            marginTop: '32px',
            justifyItems: 'start',
            justifySelf: 'start',
          }}
        >
          <BaseSwitch
            checked={email}
            setState={setEmail}
            error={null}
            label="Enviar para email cadastrado"
          />
          <BaseSwitch
            checked={auth2fa}
            setState={setAuth2FA}
            error={null}
            label="Autenticação em 2 etapas"
          />
        </Box>
        <Box
          sx={{
            margin: '64px 54.5px 0px 54.5px',
            width: 'calc(100% - 54.5px - 54.5px)',
          }}
        >
          <Button fullWidth variant="contained" onClick={handleContinue}>
            Entrar
          </Button>
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
