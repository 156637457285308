import { Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { BaseSelectInput } from '../base-component/base-select-input'

export const ModalSelectSystemicAccount: React.FC<{
  onSelect: (data: string) => void
  options: {
    value: string | number
    label: string
    component?: React.ReactNode
  }[]
  type?: 'create' | 'delete'
}> = ({ onSelect, options, type = 'create' }) => {
  const { HideModal } = ModalFullContext()

  const [accountSelected, setAccountSelected] = useState('')

  return (
    <Stack width="488px" height="209px" position="relative">
      <Typography>
        {type === 'create'
          ? 'Selecione abaixo a conta sistêmica que você deseja importar o extrato'
          : 'Selecione abaixo a conta sistêmica que você deseja listar o extrato'}
      </Typography>

      <Stack
        flex={1}
        direction="row"
        overflow="hidden"
        paddingBottom="12px"
        position="relative"
        paddingTop="24px"
        justifyContent="space-between"
      >
        <BaseSelectInput
          label="Conta Sistêmica"
          labelBackground="#ffffff"
          options={options}
          fullWidth
          error=""
          value={accountSelected}
          setState={(e) => setAccountSelected(e)}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        gap="12px"
        marginTop="20px"
      >
        <Button
          variant="outlined"
          sx={{ width: '135px', height: '40px', padding: 0 }}
          onClick={HideModal}
        >
          <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
            Cancelar
          </Typography>
        </Button>

        <Button
          variant="contained"
          disabled={!accountSelected}
          sx={{ width: '100px', height: '40px', padding: 0 }}
          onClick={() => onSelect(accountSelected)}
        >
          <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
            Avançar
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
