import {
  AccountApiRemoteService,
  IBankAccountOpenFinanceFinalFlowResponse,
  TBankAccountOpenFinanceFinalFLowDTO,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class CreateAccountStepTwopluggyFinalFlowUseCase
  implements
    IUsecase<
      TBankAccountOpenFinanceFinalFLowDTO,
      IBankAccountOpenFinanceFinalFlowResponse
    >
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle(
    data: TBankAccountOpenFinanceFinalFLowDTO,
  ): Promise<Result<IBankAccountOpenFinanceFinalFlowResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.openFinanceFinalFlow(data)

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde',
          ),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
