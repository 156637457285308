import {
  AccountApiRemoteService,
  TPluggyCallback,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class UpdateAccountOpenFinanceConnectUseCase
  implements
    IUsecase<
      {
        companyId: string
        accountId: string
        editData: {
          bankLogo?: string
          consentedAccountId: string
        }
      },
      void
    >
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle({
    accountId,
    companyId,
    editData,
  }: {
    companyId: string
    accountId: string
    editData: {
      bankLogo?: string
      consentedAccountId: string
    }
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.openFinanceConnect({
        companyId,
        accountId,
        editData,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Ocorreu um erro ao conectar com o Open Finance. Tente novamente mais tarde',
          ),
        )
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
