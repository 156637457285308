import { Box, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import {
  IVariationData,
  VariationsChart,
} from 'src/component/charts/variations'
import { ChatIcon } from 'src/component/icons/chat'
import { MovimentationsIcon } from 'src/component/icons/movimentations'
import { BigNumbersWidget } from 'src/component/widgets/big-numbers'
import { MockData } from '../../../page/multi-company-reports-export/mock'
import { ICashFlowConsolidate } from 'src/service/service-bank'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

interface Props {
  cashflowConsolidate: ICashFlowConsolidate
  variationData: {
    name: string
    data: IVariationData[]
  }[]
  minVariation: number
  maxVariation: number
  categoriesVariations: string[]
}

const CashFlow = ({
  cashflowConsolidate,
  variationData,
  minVariation,
  maxVariation,
  categoriesVariations,
}: Props) => {
  return (
    <Stack gap="24px" bgcolor="#fff" borderRadius="16px" padding="24px">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <MovimentationsIcon />
          <Typography
            fontWeight={700}
            fontSize="24px"
            lineHeight="32.69px"
            color="#1E3466"
            ml={1}
          >
            Fluxo de entrada e saída
          </Typography>
        </Stack>

        {/* <ChatIcon /> */}
      </Stack>

      <Stack direction="row" justifyContent="space-between" mt="24px">
        <BigNumbersWidget
          label="Total de entradas"
          value={formatMoney(Number(cashflowConsolidate?.inflow))}
          valueColor="#5ED1A2"
        />

        <BigNumbersWidget
          label="Total de saídas"
          value={formatMoney(Number(cashflowConsolidate?.outflow))}
          valueColor="#DB2D25"
        />

        <BigNumbersWidget
          label="Variação de caixa"
          value={formatMoney(Number(cashflowConsolidate?.total))}
          valueColor="#FCD24C"
        />
      </Stack>

      <Stack
        alignItems="center"
        justifyContent="center"
        marginTop="20px"
        marginBottom="20px"
      >
        <VariationsChart
          series={variationData}
          categories={categoriesVariations}
          min={minVariation}
          max={maxVariation}
        />
      </Stack>

      <Stack
        direction="row"
        width="100%"
        justifyContent="center"
        gap="36px"
        marginTop="360px"
      >
        {MockData?.legends?.map((legend) => (
          <Stack direction="row" alignItems="center" key={legend.label}>
            <Box
              width="16px"
              height="16px"
              borderRadius="50%"
              bgcolor={legend.color}
              mr="5px"
            />
            <Typography
              color="#777C84"
              fontWeight={400}
              fontSize="14px"
              lineHeight="19.07px"
            >
              {legend.label}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export { CashFlow }
