import { BankApiRemoteService } from 'src/service/service-bank'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class SyncAccountOpenFinanceUseCase
  implements IUsecase<{ bankAccountId: string; companyId: string }, void>
{
  constructor(private userApi: BankApiRemoteService) {}

  public async handle({
    bankAccountId,
    companyId,
  }: {
    bankAccountId: string
    companyId: string
  }): Promise<Result<void>> {
    try {
      // eslint-disable-next-line prettier/prettier
        const response = await this.userApi.openFinanceSync({
        bankAccountId,
        companyId,
      })

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao atualizar a conta.'))
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao atualizar a conta.'))
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
