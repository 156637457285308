import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { DeleteTableIcon } from 'src/component/icons/delete'
import { EditTableIcon } from 'src/component/icons/edit'
import { UpdateCompanyMassImportModal } from 'src/component/modal/company/modal-company-mass-import-edit-register'
import { CompanyPlanModal } from 'src/component/modal/company/modal-company-plan'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { PlanTooltip } from 'src/component/plan/popover'
import { ColumnsCommon, Table } from 'src/component/table/table'
import { VerticalStepper } from 'src/component/vertical-stepper/vertical-stepper'
import { UserFullContext } from 'src/context/context-user'
import { useCompaniesMassImportContext } from 'src/shared/context/company-mass-import-provider'
import { formatCnpj } from 'src/shared/util/formatter/formatter-utils'
import { CreateCompanyMassImportUseCase } from 'src/usecase/company/usecase-company-mass-import-create'
import BlueHeader from '../../assets/background/header-blue.png'
import { MockPlans } from './mock'

interface CreateCompanyPageMassImportProps {
  useCase: CreateCompanyMassImportUseCase
}

export function CreateCompanyMassImportPage(
  props: CreateCompanyPageMassImportProps,
): any {
  const navigate = useNavigate()
  const { setCompanies, companies } = useCompaniesMassImportContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const { GetCompanyData } = UserFullContext()

  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [companiesToImport, setCompaniesToImpoort] =
    useState<{ id: number; name: string; cnpj: string }[]>(companies)

  const handleDeleteItems = (selectedItemsIds: number[]) => {
    const currentCompanies = [...companiesToImport].filter(
      (f) => !selectedItemsIds.includes(f.id),
    )
    setCompaniesToImpoort(currentCompanies)
  }

  const handleChangePlanFromSelected = (selectedItemsIds: number[]) => {
    ShowModal({
      content: (
        <CompanyPlanModal plans={MockPlans} handle={() => HideModal()} />
      ),
      title: 'Planos',
    })
  }

  const handleChangePlan = () => {
    ShowModal({
      content: (
        <CompanyPlanModal plans={MockPlans} handle={() => HideModal()} />
      ),
      title: 'Planos',
    })
  }

  const handleDeleteOneItem = (itemId: number) => {
    const currentCompanies = [...companiesToImport].filter(
      (f) => f.id !== itemId,
    )
    setCompaniesToImpoort(currentCompanies)
  }

  const handleConfirmEdit = (itemId: number, name: string, CNPJ: string) => {
    const editResult = companiesToImport.map((company) => {
      if (company.id === itemId) {
        return {
          ...company,
          name,
          cnpj: CNPJ,
        }
      }

      return company
    })
    setCompaniesToImpoort(editResult)
    HideModal()
  }

  const handleOpenEditModal = (itemId: number) => {
    const itemToEdit = companiesToImport.find((f) => f.id === itemId)

    if (!itemToEdit) {
      return ShowToast('warning', 'Nenhum item encontrado para a edição.')
    }

    ShowModal({
      content: (
        <UpdateCompanyMassImportModal
          id={itemId}
          companyName={itemToEdit.name}
          companyCNPJ={itemToEdit.cnpj}
          height={300}
          width={420}
          onConfirm={handleConfirmEdit}
        />
      ),
      title: 'Alterar dados da empresa',
    })
  }

  const handleCreateUsecase = async () => {
    try {
      setLoading(true)

      const usecaseResult = await props.useCase.handle(companiesToImport)

      setLoading(false)

      if (usecaseResult.isFailure) {
        return ShowToast(
          'error',
          'Ocorreu um erro ao cadastrar. Verifique os campos ou tente novamente mais tarde.',
        )
      }

      const result = usecaseResult.getValue()

      if (result?.length === 0) {
        return ShowToast(
          'error',
          'Ocorreu um erro ao cadastrar. Verifique os campos ou tente novamente mais tarde.',
        )
      }

      setCompanies([])
      ShowToast(
        'success',
        `Foram criadas ${result?.length} de ${companiesToImport.length} empresas.`,
      )
      navigate('/home')
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao cadastrar. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate('/home')
  }

  const columns: GridColDef[] = [
    {
      ...ColumnsCommon,
      flex: 2.5,
      ...{
        field: 'name',
        headerName: 'Nome do prestador',
      },
      renderCell: (params) => `${params.row.name}`,
    },
    {
      ...ColumnsCommon,
      flex: 1.5,
      ...{ field: 'cnpj', headerName: 'CNPJ' },
      renderCell: (params) => <>{formatCnpj(params.row.cnpj.toString())}</>,
    },
    {
      ...ColumnsCommon,
      flex: 1.5,
      ...{
        field: 'id',
        headerName: 'Ações',
        align: 'left',
        renderCell: (params) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap="32px"
            sx={{ cursor: 'pointer' }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => handleOpenEditModal(params.row.id)}
            >
              <EditTableIcon />
            </Box>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => handleDeleteOneItem(params.row.id)}
            >
              <DeleteTableIcon />
            </Box>
          </Stack>
        ),
      },
    },
    {
      ...ColumnsCommon,
      ...{ field: 'plan', headerName: 'Plano sugerido' },
      renderCell: (params) => (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="32px"
          sx={{ cursor: 'ponter' }}
        >
          <Typography color="#1E1E1E" fontWeight="400" fontSize="16px">
            Plano 1
          </Typography>

          <PlanTooltip
            planOptions={MockPlans[0].planOptions}
            showFooterButton
            butonAction={handleChangePlan}
          />
        </Stack>
      ),
    },
  ]

  return (
    <Stack
      width="100%"
      height="100%"
      overflow="hidden !important"
      sx={{ backgroundColor: '#F4F8FA' }}
    >
      <DefaultHeader
        containerStyle={{
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '32px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Painel multiempresas', navigateTo: '/home' },
          { title: 'Cadastro de empresa', navigateTo: '/company/new' },
          { title: 'Importar planilha' },
        ]}
        buttons={
          <Stack direction="row" alignItems="center">
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={() => navigate('/home')}
              sx={{
                width: '284px',
                height: '40px',
                color: '#fff',
                borderColor: '#fff',
                padding: 0,
                fontSize: '14px',
              }}
            >
              Voltar para o painel multiempresa
            </Button>
          </Stack>
        }
      />

      <Stack
        width="100%"
        height="100%"
        gap="32px"
        overflow="hidden !important"
        direction="row"
      >
        <Stack
          padding="32px"
          gap="32px"
          width="calc(100% - 240px - 64px - 64px)"
        >
          <BasePageTitle color="#1E3466" text="Cadastro de empresas" />

          <Table
            columns={columns}
            rows={companiesToImport}
            totalRegisters={companiesToImport.length}
            allowSelectAll={true}
            loading={false}
            loadMore={() => console.log('press me')}
            totalPages={1}
            currentPage={1}
            selectedItemActions={[
              { title: 'Alterar plano', action: handleChangePlanFromSelected },
              { title: 'Excluír', action: handleDeleteItems },
            ]}
          />

          <Stack
            width="100%"
            height="15%"
            justifyContent="flex-start"
            alignItems="flex-end"
            marginBottom="24px"
          >
            {!loading && (
              <Stack gap="24px" direction="row">
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{ width: '130px' }}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={companiesToImport.length === 0}
                  variant="contained"
                  onClick={handleCreateUsecase}
                  sx={{ width: '130px' }}
                >
                  Continuar
                </Button>
              </Stack>
            )}
            {loading && <CircularProgress />}
          </Stack>
        </Stack>
        <Stack width="240px" height="100%" alignItems="center">
          <VerticalStepper
            currentStep={currentStep}
            setCurrent={setCurrentStep}
            steps={[
              { title: 'Plano' },
              { title: 'Faturamento' },
              { title: 'Pagamento' },
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
