export const WalletIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#4865CC"
      d="M14.222 3.14a.376.376 0 01.529-.06l.81.65-2.962 3.77h1.908l2.226-2.833 1.878 1.505a.375.375 0 01.05.537l-.681.79h1.96a1.875 1.875 0 00-.391-2.498l-3.86-3.092a1.875 1.875 0 00-2.642.3l-4.19 5.29h1.913l3.452-4.359zm1.528 11.11a.75.75 0 000 1.5H18a.75.75 0 100-1.5h-2.25zm-11.25-9A2.25 2.25 0 002.25 7.5v10.874a3.375 3.375 0 003.375 3.375h12.75a3.375 3.375 0 003.375-3.375v-6.75a3.375 3.375 0 00-3.375-3.375H4.5a.75.75 0 110-1.5h3.435l1.197-1.5H4.5zm-.75 13.124V9.622c.235.082.487.127.75.127h13.875a1.875 1.875 0 011.875 1.875v6.75a1.875 1.875 0 01-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875z"
    ></path>
  </svg>
)
