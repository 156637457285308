import { Stack } from '@mui/material'
import { PlanCard } from './card'

export type PlanListProps = {
  plans: {
    id: number
    title: string
    subTitle: string
    price: string
    planOptions: {
      active: boolean
      option: string
    }[]
  }[]
  selectionButtonAction?: () => void
}

export const PlanList = ({
  plans = [],
  selectionButtonAction,
}: PlanListProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      direction="row"
      gap="32px"
      overflow="auto"
      paddingBottom="12px"
      sx={{
        flexWrap: 'wrap',
        '& > .MuiPaper-root': {
          width: 'calc(50% - 8px)',
          minWidth: '150px',
          marginBottom: '16px',
        },
      }}
    >
      {plans.map((plan, index) => {
        return (
          <PlanCard
            id={plan.id}
            title={plan.title}
            subTitle={plan.subTitle}
            price={plan.price}
            planOptions={plan.planOptions}
            key={index}
            selectionButtonAction={selectionButtonAction}
          />
        )
      })}
    </Stack>
  )
}
