import React, { useState, useRef } from 'react'
import {
  Checkbox,
  Input,
  ListItemText,
  Box,
  MenuItem,
  Paper,
  Popper,
  ClickAwayListener,
  TextField,
  InputAdornment,
} from '@mui/material'
import { useClassifier } from 'src/page/classifier/useClassifier'
import { ToastFullContext } from './base-snackbar'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { BaseInputProps } from './base-input'
import ReactInputMask from 'react-input-mask'
import theme from 'src/shared/util/theme/theme'
import { ErrorOutlineOutlined } from '@mui/icons-material'

interface BaseInputAutocompleteProps extends BaseInputProps {
  error: string | null
  companyId: string
  variant: 'debits' | 'credits' | 'vendors' | 'financialCategory'
  listCompanyDebitsUseCase: ListCompanyDebitUseCase
  inputVariant?: 'outlined' | 'bordered'
}

export const BaseInputAutocomplete: React.FC<BaseInputAutocompleteProps> = ({
  companyId,
  listCompanyDebitsUseCase,
  variant,
  inputVariant = 'outlined',
  error,
  label,
  setState,
  value,
  containerStyle,
  disabled,
  fullWidth,
  iconEnd,
  iconStart,
  labelBackground,
  lines,
  mask,
  multiline,
  type,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [options, setOptions] = useState<string[]>([])
  const { ShowToast } = ToastFullContext()

  const handleListCompanyDebit = async (companyId: string, query: string) => {
    try {
      // setIsLoading(true);
      const usecaseResult = await listCompanyDebitsUseCase.handle({
        companyId,
        query,
      })
      // setIsLoading(false);

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas de débito. Tente novamente mais tarde.',
        )
      }

      const value = usecaseResult.getValue()

      if (value) {
        setOptions(value)
      }
    } catch (error) {
      console.log('error: ', error)
      // setIsLoading(false);
      ShowToast('error', 'Ocorreu um erro ao buscar as contas de débito.')
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (event.target.value?.length > 0) {
      if (variant === 'debits') {
        handleListCompanyDebit(companyId, event.target.value)
      }

      if (variant === 'credits') {
        handleListCompanyDebit(companyId, event.target.value)
      }

      if (variant === 'financialCategory') {
        handleListCompanyDebit(companyId, event.target.value)
      }

      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleSelect = (optionValue: string) => {
    setInputValue(optionValue)
    setAnchorEl(null)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  return (
    <Box position="relative">
      {inputVariant === 'outlined' && (
        <Input
          value={inputValue}
          onChange={handleInputChange}
          inputRef={inputRef}
          id="autocomplete-input"
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl)}
          disableUnderline
        />
      )}

      {inputVariant === 'bordered' && (
        <TextField
          // sx={{
          //   ...customStyles,
          //   ...(containerStyle as SxProps<Theme>),
          // }}
          sx={{ width: '100%' }}
          variant="outlined"
          type={type || 'text'}
          label={label}
          // fullWidth={fullWidth}
          // multiline={multiline ?? undefined}
          // rows={lines ?? undefined}
          value={inputValue}
          onChange={handleInputChange}
          inputRef={inputRef}
          id="autocomplete-input"
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl)}
          InputProps={{
            inputComponent: mask ? (ReactInputMask as any) : undefined,
            inputProps: mask
              ? {
                  mask,
                }
              : undefined,
            startAdornment: iconStart ? (
              <InputAdornment position="start">{iconStart}</InputAdornment>
            ) : undefined,
            endAdornment: error ? (
              <InputAdornment position="end">
                <ErrorOutlineOutlined
                  style={{ color: theme.palette.error.main }}
                />
              </InputAdornment>
            ) : iconEnd ? (
              <InputAdornment position="end">{iconEnd}</InputAdornment>
            ) : undefined,
          }}
          helperText={error}
          error={!!error}
        />
      )}

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 99999999999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            style={{
              maxHeight: 400,
              overflowY: 'auto',
              //   width: inputRef.current?.clientWidth || 'auto',
              width: 'inherit',
              minWidth: '300px',
              marginTop: 9,
              marginLeft: -10,
            }}
          >
            {options
              .filter((option) =>
                option.toLowerCase().includes(inputValue.toLowerCase()),
              )
              .map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => handleSelect(option)}
                >
                  {/* <Checkbox
                    sx={{
                      color: '#4865CC',
                      '&.Mui-checked': {
                        color: '#4865CC',
                      },
                    }}
                    checked={value.includes(option.value)}
                  /> */}
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}
