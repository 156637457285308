import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'

export const UpdateProfileEmailModal: React.FC<{
  handleConfirmAction: (email: string) => void
  handleCancelAction: () => void
  height: number
  width: number
}> = ({ handleConfirmAction, handleCancelAction, height, width }) => {
  const { ShowToast } = ToastFullContext()
  const [email, setEmail] = useState<string>('')
  const [confirmEmail, setConfirmEmail] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    if (!email || !confirmEmail)
      return ShowToast('error', 'Preencha todos os campos')
    if (email !== confirmEmail)
      return ShowToast('error', 'Email e confirmação de email não conferem')
    setLoading(true)
    handleConfirmAction && (await handleConfirmAction(email))
    setLoading(false)
  }

  const handleCancel = () => {
    setEmail('')
    setConfirmEmail('')
    setLoading(false)
    handleCancelAction && handleCancelAction()
  }

  return (
    <Stack
      width="100%"
      maxWidth={`${width}px`}
      height={`${height}px`}
      gap="32px"
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '400',
        }}
      >
        Digite seu email para o método de segurança.
      </Typography>
      <Box
        sx={{
          marginTop: '24px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            gap: '24px',
            width: '440px',
          }}
        >
          <BaseInput
            label="Digite o email"
            fullWidth
            value={email}
            setState={(e) => setEmail(e)}
            error={null}
          />
          <BaseInput
            label="Repetir email"
            fullWidth
            value={confirmEmail}
            setState={(e) => setConfirmEmail(e)}
            error={null}
          />
        </Box>
      </Box>
      <Stack
        direction="row"
        gap="16px"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>

        {!loading && (
          <Button
            fullWidth={false}
            variant="contained"
            onClick={() => handleConfirm()}
          >
            Cadastrar
          </Button>
        )}
        {loading && (
          <CircularProgress
            sx={{
              justifySelf: 'center',
              justifyItems: 'center',
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
