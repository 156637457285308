export const DeleteTableIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      stroke="#4865CC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21.75 19v5.25M18.25 19v5.25m-3.5-8.75V26a1.75 1.75 0 001.75 1.75h7A1.75 1.75 0 0025.25 26V15.5M13 15.5h14m-11.375 0l1.75-3.5h5.25l1.75 3.5"
    ></path>
  </svg>
)
