import { Search } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { useRef, useState } from 'react'
import {
  BaseDateSelect,
  HandleBaseSelectInput,
} from 'src/component/base-component/base-date-select'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'

export const ModalClassifierPaymentEntry: React.FC<{
  handle: (data?: any) => void
  companyId: string
  type: 'transfer' | 'made' | 'provision'
  listCompanyDebitsUseCase: ListCompanyDebitUseCase
  accountsOptions: {
    value: string | number
    label: string
    icon?: React.ReactNode
  }[]
}> = ({
  handle,
  accountsOptions,
  type,
  companyId,
  listCompanyDebitsUseCase,
}) => {
  const { HideModal } = ModalFullContext()
  const [typeSelected, setTypeSelected] = useState('')
  const [erpSelected, setERPSelected] = useState('')

  const [searchFinancialCategory, setSearchFinancialCategory] = useState('')
  const [searchDebit, setSearchDebit] = useState('')
  const [searchCredit, setSearchCredit] = useState('')

  const [initialDate, setInitialDate] = useState<Date | undefined>(undefined)
  const [finalDate, setFinalDate] = useState<Date | undefined>(undefined)

  const DateInitialRef = useRef<HandleBaseSelectInput>(null)
  const DateFinalRef = useRef<HandleBaseSelectInput>(null)
  const [minValue, setMinValue] = useState('')

  const handleSelectDate = (e: Date | undefined, type: 'initial' | 'final') => {
    if (type === 'initial') {
      setInitialDate(e)
      return
    }

    setFinalDate(e)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '600px',

          borderRadius: '16px',
        }}
      >
        {/* Conta Origem */}

        {(type === 'transfer' || type === 'made') && (
          <BaseInputSingleSelect
            options={accountsOptions}
            onSelect={setTypeSelected}
            placeholder={type === 'made' ? 'Conta bancária' : 'Conta Origem'}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="18px"
        >
          {/* <BaseInput
            label="Cat. financeira"
            fullWidth
            setState={e => setSearchFinancialCategory(e)}
            error=""
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
          /> */}
          <BaseInputAutocomplete
            companyId={companyId}
            listCompanyDebitsUseCase={listCompanyDebitsUseCase}
            variant="debits"
            error=""
            label="Cat. financeira"
            fullWidth
            setState={(e) => setSearchFinancialCategory(e)}
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />

          {/* <BaseInput
            label="Conta débito"
            fullWidth
            setState={e => setSearchFinancialCategory(e)}
            error=""
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
          /> */}
          <BaseInputAutocomplete
            companyId={companyId}
            listCompanyDebitsUseCase={listCompanyDebitsUseCase}
            variant="debits"
            error=""
            label="Conta débito"
            fullWidth
            setState={(e) => setSearchFinancialCategory(e)}
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />

          {/* <BaseInput
            label="Conta crédito"
            fullWidth
            setState={e => setSearchFinancialCategory(e)}
            error=""
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
          /> */}
          <BaseInputAutocomplete
            companyId={companyId}
            listCompanyDebitsUseCase={listCompanyDebitsUseCase}
            variant="debits"
            error=""
            label="Conta crédito"
            fullWidth
            setState={(e) => setSearchFinancialCategory(e)}
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />
        </Stack>

        {type === 'transfer' && (
          <Box
            width="100%"
            borderBottom="dashed 1px #777C84"
            marginTop="20px"
          />
        )}

        {/* Conta Destino */}
        {type === 'transfer' && (
          <>
            <BaseInputSingleSelect
              options={accountsOptions}
              onSelect={setTypeSelected}
              placeholder="Conta Destino"
            />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="18px"
            >
              <BaseInputAutocomplete
                companyId={companyId}
                listCompanyDebitsUseCase={listCompanyDebitsUseCase}
                variant="debits"
                error=""
                label="Cat. financeira"
                fullWidth
                setState={(e) => setSearchFinancialCategory(e)}
                value={searchFinancialCategory}
                containerStyle={{ width: '100%' }}
                iconEnd={<Search />}
                inputVariant="bordered"
              />

              <BaseInputAutocomplete
                companyId={companyId}
                listCompanyDebitsUseCase={listCompanyDebitsUseCase}
                variant="debits"
                error=""
                label="Conta débito"
                fullWidth
                setState={(e) => setSearchFinancialCategory(e)}
                value={searchFinancialCategory}
                containerStyle={{ width: '100%' }}
                iconEnd={<Search />}
                inputVariant="bordered"
              />

              <BaseInputAutocomplete
                companyId={companyId}
                listCompanyDebitsUseCase={listCompanyDebitsUseCase}
                variant="debits"
                error=""
                label="Conta crédito"
                fullWidth
                setState={(e) => setSearchFinancialCategory(e)}
                value={searchFinancialCategory}
                containerStyle={{ width: '100%' }}
                iconEnd={<Search />}
                inputVariant="bordered"
              />
            </Stack>
          </>
        )}

        {type === 'transfer' && (
          <Box
            width="100%"
            borderBottom="dashed 1px #777C84"
            marginTop="20px"
            marginBottom="20px"
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="18px"
          marginTop="18px"
        >
          <BaseDateSelect
            title="Data"
            onSelect={(e) => handleSelectDate(e, 'initial')}
            ref={DateInitialRef}
            containerStyle={{ width: '50%' }}
            buttonStyle={{ width: '100%' }}
          />

          <BaseInput
            label="Valor"
            fullWidth
            setState={(e) => setMinValue(e)}
            error=""
            value={minValue}
            containerStyle={{ width: '50%' }}
          />
        </Stack>

        <Stack direction="column" gap="18px" marginY="18px">
          <BaseInputAutocomplete
            companyId={companyId}
            listCompanyDebitsUseCase={listCompanyDebitsUseCase}
            variant="vendors"
            error=""
            label="Clientes e fornecedores"
            fullWidth
            setState={(e) => setSearchFinancialCategory(e)}
            value={searchFinancialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />

          {(type === 'transfer' || type === 'made') && (
            <BaseInput
              label="Descrição"
              fullWidth
              setState={(e) => setMinValue(e)}
              error=""
              value={minValue}
              containerStyle={{ width: '100%' }}
            />
          )}

          <BaseInput
            label="Histórico"
            fullWidth
            setState={(e) => setMinValue(e)}
            error=""
            value={minValue}
            containerStyle={{ width: '100%' }}
          />
        </Stack>
      </Box>

      {/* Footer */}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            HideModal()
            handle(typeSelected)
          }}
          sx={{ width: '175px', height: '40px' }}
        >
          Criar lançamento
        </Button>
      </Box>
    </Box>
  )
}
