import { createTheme } from '@mui/material'
import { colors } from './theme-colors'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    customVariant: React.CSSProperties
    bodyLargeRegular: React.CSSProperties
    bodyLargeBold: React.CSSProperties
    bodyMediumRegular: React.CSSProperties
    bodyMediumBold: React.CSSProperties
    bodySmallRegular: React.CSSProperties
    bodySmallBold: React.CSSProperties
    labelLarge: React.CSSProperties
    labelMedium: React.CSSProperties
    labelSmall: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    customVariant?: React.CSSProperties
    bodyLargeRegular?: React.CSSProperties
    bodyLargeBold?: React.CSSProperties
    bodyMediumRegular?: React.CSSProperties
    bodyMediumBold?: React.CSSProperties
    bodySmallRegular?: React.CSSProperties
    bodySmallBold?: React.CSSProperties
    labelLarge?: React.CSSProperties
    labelMedium?: React.CSSProperties
    labelSmall?: React.CSSProperties
  }
}

// Add the custom variant to the theme
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    customVariant: true
    bodyLargeRegular: true
    bodyLargeBold: true
    bodyMediumRegular: true
    bodyMediumBold: true
    bodySmallRegular: true
    bodySmallBold: true
    labelLarge: true
    labelMedium: true
    labelSmall: true
  }
}

const CustomTheme = createTheme({
  palette: {
    primary: {
      main: colors.palette.primaryBranding,
      light: colors.palette.primaryLighter,
      dark: colors.palette.primaryDarker,
    },
    secondary: {
      main: colors.palette.secondaryYellow,
      light: colors.palette.secondaryYellow,
      dark: colors.palette.secondaryYellow,
    },
    error: {
      main: colors.palette.feedbackError,
    },
    warning: {
      main: colors.palette.feedbackWarning,
    },
    success: {
      main: colors.palette.feedbackSuccess,
    },
    text: {
      primary: colors.palette.textBlack,
      secondary: colors.palette.textWhite,
      disabled: colors.palette.textGrey,
    },
    background: {
      default: colors.background,
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: '32px',
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: '8px 6px',
            transitionDuration: '300ms',
            color: '#B9BFC7',
            '&.Mui-checked': {
              transform: 'translateX(20px)',
              color: '#fff',
              margin: 4,
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                boxShadow: 'none',
                width: 24,
                height: 24,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: colors.palette.primaryBranding,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: colors.palette.primaryBranding,
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#E7E7E7',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            boxShadow: 'none',
            width: 16,
            height: 16,
          },
          '& .MuiSwitch-track': {
            width: '52px',
            height: '32px',
            backgroundColor: '#E7E7E7',
            borderRadius: '999px',
            opacity: 1,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.text,
            fontFamily: 'Noto Sans',
            fontWeight: 600,
            fontSize: '16px',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.palette.primaryBranding,
          '&.Mui-checked': {
            color: colors.palette.primaryBranding,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '100px',
            borderColor: colors.palette.primaryBranding,
            fontWeight: 400,
            fontSize: '14px',
            color: colors.palette.primaryDarker,
            '&.Mui-focused': {
              borderColor: colors.palette.primaryBranding,
              color: colors.palette.primaryDarker,
            },
            '&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
              {
                borderColor: colors.palette.primaryBranding,
              },
          },
          '& .MuiInputBase-multiline': {
            borderRadius: '32px;',
          },
          '& .MuiOutlinedInput-input': {
            padding: '18.5px 16px',
            borderColor: colors.palette.primaryBranding,
          },
          '& .MuiInputLabel-root': {
            color: colors.palette.primaryDarker,
            borderColor: colors.palette.primaryBranding,
            backgroundColor: colors.background,
            '& .Mui-focused ': {
              color: colors.palette.primaryDarker,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: colors.palette.primaryBranding,
          fontSize: '14px',
          fontWeight: 400,
          '& p': {
            color: colors.palette.primaryBranding,
            fontSize: '14px',
            fontWeight: 400,
            position: 'relative',
            left: '4px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderRadius: '100px',
          borderColor: colors.palette.primaryBranding,
          padding: '12px 24px 12px 24px !important',
          fontWeight: 600,
          fontSize: '14px',
          boxShadow: 'none',
          textTransform: 'none',
          '&.MuiButton-text': {
            border: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Noto Sans',
    h1: {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '38px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '29px',
    },
    customVariant: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19.3px',
      color: 'red',
    },
    bodyLargeRegular: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19.3px',
    },
    bodyLargeBold: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19.3px',
    },
    bodyMediumRegular: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16.8px',
    },
    bodyMediumBold: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16.8px',
    },
    bodySmallRegular: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14.4px',
    },
    bodySmallBold: {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14.4px',
    },
    labelLarge: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16.8px',
    },
    labelMedium: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14.4px',
    },
    labelSmall: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
})

// eslint-disable-next-line import/no-default-export
export default CustomTheme
export const Theme = colors
