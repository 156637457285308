import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import OpenI from '../../assets/logo/logo-white-yellow.png'
import Background from '../../assets/background/background-login.png'

interface ReactPageBaseComponentProps {
  children: React.ReactNode
}

export const ReactPageBaseWithSloganComponent: React.FC<
  ReactPageBaseComponentProps
> = ({ children }) => {
  const theme = useTheme()

  return (
    <ReactPageBaseComponentContainer>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            justifyItems: 'center',
            alignItems: 'center',
            justifySelf: 'center',
            alignSelf: 'center',
            height: '100%',
            width: '100%',
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              justifyItems: 'center',
              alignItems: 'center',
              justifySelf: 'center',
              alignSelf: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: theme.palette.text.secondary,
              }}
            >
              Automatize o que é possivel, deixe o impossivel com a
            </Typography>
            <img
              src={OpenI}
              alt="Openi"
              style={{ width: '30vw', maxWidth: '581px', marginTop: '32px' }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            height: '100%',
            width: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            justifySelf: 'center',
            justifyItems: 'center',
            display: 'grid',
          }}
        >
          <Box
            sx={{
              paddingTop: '80px',
              paddingBottom: '64px',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ReactPageBaseComponentContainer>
  )
}

const ReactPageBaseComponentContainer = styled.div`
  height: 100vh;
  width: 100%;
`
