import { ErrorOutlineOutlined } from '@mui/icons-material'
import { InputAdornment, MenuItem, TextField, useTheme } from '@mui/material'
import React, { useState } from 'react'

export type BaseInputProps = {
  setState: (value: string) => void
  error: string | null
  label?: string
  value: string
  fullWidth?: boolean
  options: {
    value: string | number
    label: string
    component?: React.ReactNode
  }[]
  iconEnd?: React.ReactNode
  iconStart?: React.ReactNode
  labelBackground?: string
  showSearchInput?: boolean
  disabled?: boolean
}

export const BaseSelectInput: React.FC<BaseInputProps> = ({
  setState,
  error,
  label,
  value,
  fullWidth,
  options,
  iconEnd,
  iconStart,
  labelBackground,
  showSearchInput = false,
  disabled = false,
}) => {
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <TextField
      sx={
        iconStart
          ? {
              '& .MuiOutlinedInput-input': {
                padding: '18.5px 16px 18.5px 0px',
              },
              '& .MuiInputLabel-root': {
                backgroundColor: labelBackground || 'transparent',
                paddingRight: '4px',
              },
              '& .MuiMenu-paper': {
                marginTop: '12px',
              },
            }
          : {
              '& .MuiInputLabel-root': {
                backgroundColor: labelBackground || 'transparent',
                paddingRight: '4px',
              },
              '& .MuiMenu-paper': {
                marginTop: '12px',
              },
            }
      }
      variant="outlined"
      label={label}
      fullWidth={fullWidth}
      select
      disabled={disabled}
      value={value}
      onChange={(event) => setState(event.target.value as string)}
      InputProps={{
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : undefined,
        endAdornment: error ? (
          <InputAdornment position="end">
            <ErrorOutlineOutlined style={{ color: theme.palette.error.main }} />
          </InputAdornment>
        ) : iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : undefined,
      }}
      helperText={error}
      error={!!error}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: 300,
              minWidth: '500px !important',
            },
          },
        },
        renderValue: (selected) => {
          const selectedOption = options.find(
            (option) => option.value === selected,
          )
          return selectedOption ? selectedOption.label : ''
        },
      }}
    >
      {showSearchInput && (
        <MenuItem
          disableRipple
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.background.paper,
            },
            '&.Mui-selected': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .Mui-focused': {
              backgroundColor: theme.palette.background.paper,
            },
          }}
        >
          <TextField
            autoFocus
            placeholder="Pesquisar"
            fullWidth
            value={searchTerm}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => setSearchTerm(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
            InputProps={{
              sx: {
                fontSize: '0.875rem',
                minWidth: '500px',
              },
            }}
          />
        </MenuItem>
      )}
      {filteredOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option?.component || option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}
