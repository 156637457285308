import { BankApiRemoteService, IBank } from 'src/service/service-bank'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListBanksUseCase implements IUsecase<{ void: string }, IBank[]> {
  constructor(private bankApi: BankApiRemoteService) {}

  public async handle(): Promise<Result<IBank[]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.bankApi.listBanks()

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados das bancos disponíveis'),
        )
      }
      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
