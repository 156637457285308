import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { EditOutlined, ArrowBack } from '@mui/icons-material'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TermsOfUseModal } from 'src/component/modal/modal-terms-of-use'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { BaseRadio } from 'src/component/base-component/base-radio'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { useNavigate } from 'react-router-dom'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
// import { RecoveryWithEmailUseCase } from 'src/usecase/authentication/usecase-authentication-form-contact-us'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { object } from 'yup'
import { RecoveryWithEmailUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-with-email'

interface RecoveryWithEmailPageProps {
  useCase: RecoveryWithEmailUseCase
}

export function RecoveryWithEmailPage(props: RecoveryWithEmailPageProps): any {
  const { ShowToast } = ToastFullContext()

  const navigate = useNavigate()
  const theme = useTheme()

  const recoveryEmailForm = CreateForm({
    fields: ['email'],
    validation: props.useCase.validation,
  })
  const [loading, setLoading] = useState(false)

  const handleRecoveryWithEmailUsecase = async () => {
    try {
      setLoading(true)
      const usecaseResult = await props.useCase.handle({
        email: recoveryEmailForm.fields.email.value,
      })
      if (usecaseResult.isFailure) {
        setLoading(false)
        switch ((usecaseResult.error as any)?.type) {
          case 'UserNotFoundError':
            ShowToast('error', 'Usuário não encontrado')
            break

          default:
            ShowToast(
              'error',
              'Ocorreu um erro ao recuperar sua senha via email. Tente novamente mais tarde.',
            )
            break
        }
        return
      }
      setLoading(false)
      ShowToast(
        'success',
        'Email de recuperação enviado com sucesso! Verifique sua caixa de entrada',
      )
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao recuperar sua senha via email. Tente novamente mais tarde.',
      )
    }
  }

  const handleRecoveryWithEmail = async () => {
    try {
      const validationResult = await recoveryEmailForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique se inseriu o email corretamente')
        return
      }

      handleRecoveryWithEmailUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao recuperar sua senha via email. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate('/auth/recovery/2fa')
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          width: '0px',
          height: '0px',
          marginBottom: '64px',
          marginRight: '71px',
        }}
      >
        <Button variant="text" onClick={handleCancel}>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: '8px',
              alignItems: 'center',
              gridAutoColumns: 'max-content',
            }}
          >
            <ArrowBack sx={{ color: theme.palette.primary.main }} />
            <span>Voltar</span>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Recuperar a senha
        </Typography>
        <Typography
          sx={{
            marginTop: '32px',
            marginBottom: '16px',
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          Insira o email cadastrado para recuperar a senha
        </Typography>
        <BaseInput
          label="Digite o email"
          fullWidth
          {...recoveryEmailForm.fields.email}
        />
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '32px',
            width: '100%',
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: 'grid',
                justifyItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              fullWidth
              onClick={handleRecoveryWithEmail}
            >
              Recuperar senha
            </Button>
          )}
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
