import { Box, Grid, Stack, Typography } from '@mui/material'
import { AccountTypeCard } from 'src/component/account/account-type-card'
import { BankAccountTypeEnum } from 'src/service/service-account'
import AplicacaoAutomatica from '../../assets/account-type/aplicacao-automatica.jpg'
import Caixinha from '../../assets/account-type/caixinha.jpg'
import Cartao from '../../assets/account-type/cartao.jpg'
import ContaCorrente from '../../assets/account-type/conta-corrente.jpg'
import Investimentos from '../../assets/account-type/investimento.jpg'
import Outros from '../../assets/account-type/outros.jpg'
import Poupanca from '../../assets/account-type/poupanca.jpg'
import Recebimentos from '../../assets/account-type/recebimento.jpg'

interface CreateAccountPageProps {
  handleSelect: (accountType: BankAccountTypeEnum) => void
}

export function CreateSystemicAccountStepOnePage(
  props: CreateAccountPageProps,
) {
  const handleSelectAccountType = (accountType: BankAccountTypeEnum) => {
    props.handleSelect && props.handleSelect(accountType)
  }

  const accounts = [
    {
      id: 1,
      title: 'Conta Corrente',
      handleSelect: () =>
        handleSelectAccountType(BankAccountTypeEnum.CONTA_CORRENTE),
      imageUrl: ContaCorrente,
    },
    {
      id: 2,
      title: 'Caixinha',
      handleSelect: () => handleSelectAccountType(BankAccountTypeEnum.CAIXINHA),
      imageUrl: Caixinha,
    },
    {
      id: 3,
      title: 'Poupança',
      handleSelect: () =>
        handleSelectAccountType(BankAccountTypeEnum.CONTA_POUPANCA),
      imageUrl: Poupanca,
    },
    {
      id: 4,
      title: 'Investimentos',
      handleSelect: () =>
        handleSelectAccountType(BankAccountTypeEnum.INVESTIMENTOS),
      imageUrl: Investimentos,
    },
    {
      id: 5,
      title: 'Aplicação automática',
      handleSelect: () =>
        handleSelectAccountType(BankAccountTypeEnum.APLICACAO_AUTOMATICA),
      imageUrl: AplicacaoAutomatica,
    },
    {
      id: 6,
      title: 'Meios de recebimento',
      handleSelect: () =>
        handleSelectAccountType(BankAccountTypeEnum.MEIOS_DE_RECEBIMENTO),
      imageUrl: Recebimentos,
    },
    {
      id: 7,
      title: 'Cartão',
      handleSelect: () =>
        handleSelectAccountType(BankAccountTypeEnum.CARTAO_CREDITO),
      imageUrl: Cartao,
    },
    {
      id: 8,
      title: 'Outros tipos de conta',
      handleSelect: () => handleSelectAccountType(BankAccountTypeEnum.OUTROS),
      imageUrl: Outros,
    },
  ]

  return (
    <Stack
      width="100%"
      height="100%"
      sx={{ backgroundColor: '#F4F8FA' }}
      marginTop="32px"
    >
      <Typography fontWeight="700" fontSize="16px" color="#0B1116">
        Primeiramente, vamos escolher o tipo da conta que vamos criar:
      </Typography>

      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            width: '100%',
            maxWidth: '1024px',
            margin: { xs: '0 auto', lg: '0' }, // Remover a centralização em telas grandes
          }}
        >
          {accounts.map((account) => (
            <Grid
              item
              xs={12} // ocupa 100% da largura em telas extra-pequenas
              sm={6} // ocupa 50% da largura em telas pequenas
              md={4} // ocupa 33.33% da largura em telas médias
              lg={3} // ocupa 25% da largura em telas grandes e maiores
              key={account.id}
            >
              <AccountTypeCard
                id={account.id}
                title={account.title}
                handleSelect={account.handleSelect}
                imageUrl={account.imageUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  )
}
