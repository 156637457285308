import { CompanyApiRemoteService, ICompany } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class CompanyUseDetailsUseCase
  implements IUsecase<{ companyId: string }, ICompany>
{
  constructor(private userApi: CompanyApiRemoteService) {}

  public async handle({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<ICompany>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.getCompanyInfo({ companyId })

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Erro ao recuperar dados das empresas relacionadas ao usuário',
          ),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar dados das empresas'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
