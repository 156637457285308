import { Box, Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { BaseInput } from '../base-component/base-input'
import {
  BaseSelectWithSearch,
  ISelectMenuItemProps,
} from '../base-component/base-select-with-search'
import { BaseSelectInput } from '../base-component/base-select-input'

export interface IModalFilterOption {
  name?: string
  placeholder?: string
  startDateTitle?: string
  startDate?: string
  endDate?: string
  endDateTitle?: string
  selectedDate?: string
  options?: ISelectMenuItemProps[]
  selectedOptions?: ISelectMenuItemProps[]
}

export interface IModalFilter {
  id: string
  type: 'dropdown' | 'date'
  dateType?: 'month' | 'day'
  title?: string
  options: IModalFilterOption[]
}

interface IModalFilterTransformResponse {
  name?: string
  startDateTitle?: string
  endDateTitle?: string
  selected:
    | string
    | {
        label: string
        value: any
      }[]
}

export interface IModalFilterResponse {
  filters: IModalFilterTransformResponse[]
}

export const FilterModal: React.FC<{
  initialStateFilters: IModalFilter[]
  handle: () => void
}> = ({ initialStateFilters }) => {
  const { HideModal } = ModalFullContext()
  const [filters, setFilters] = useState<IModalFilter[]>(initialStateFilters)

  function onSelectOption(value: any) {
    const newFilters = updateSelectedOption(value)

    if (newFilters) {
      setFilters(newFilters)
    }
  }

  function findMenuItemByValue(
    valueToFind: string | number,
  ):
    | { filterIndex: number; optionIndex: number; menuItemIndex: number }
    | undefined {
    for (let filterIndex = 0; filterIndex < filters.length; filterIndex++) {
      const modalFilter = filters[filterIndex]

      for (
        let optionIndex = 0;
        optionIndex < modalFilter.options.length;
        optionIndex++
      ) {
        const modalFilterOption = modalFilter.options[optionIndex]

        if (modalFilterOption.options) {
          const menuItemIndex = modalFilterOption.options.findIndex(
            (item) => item.value === valueToFind,
          )

          if (menuItemIndex !== -1) {
            return { filterIndex, optionIndex, menuItemIndex }
          }
        }
      }
    }

    return undefined
  }

  function updateSelectedOption(
    valueToFind: string | number,
    removeItem = false,
  ): IModalFilter[] | undefined {
    const foundItem = findMenuItemByValue(valueToFind)

    if (!foundItem) return undefined

    const { filterIndex, optionIndex, menuItemIndex } = foundItem
    const modalFilters = [...filters]

    const menuItem =
      modalFilters[filterIndex].options[optionIndex].options?.[menuItemIndex]

    if (!menuItem) {
      return undefined
    }

    if (!modalFilters[filterIndex].options[optionIndex].selectedOptions) {
      modalFilters[filterIndex].options[optionIndex].selectedOptions = []
    }

    if (removeItem) {
      menuItem.removed = false

      modalFilters[filterIndex].options[optionIndex].selectedOptions =
        modalFilters[filterIndex].options[optionIndex].selectedOptions?.filter(
          (option) => option.value !== menuItem.value,
        )

      //
    } else {
      menuItem.removed = true

      const alreadyExistsInsideSelectedOptions = modalFilters[
        filterIndex
      ].options[optionIndex].selectedOptions?.findIndex(
        (option) => option.value === menuItem.value,
      )

      if (
        alreadyExistsInsideSelectedOptions &&
        alreadyExistsInsideSelectedOptions === -1
      ) {
        modalFilters[filterIndex].options[optionIndex].selectedOptions?.push(
          menuItem,
        )
      }
    }

    return modalFilters
  }

  function onDateFilterSelect({
    startDateTitle,
    endDateTitle,
    value,
    parentId,
  }: {
    startDateTitle?: string
    endDateTitle?: string
    value: any
    parentId: string
  }) {
    const foundedParent = filters.findIndex((filter) => filter.id === parentId)

    if (foundedParent < 0) return

    const foundedFilterIndex = filters[foundedParent].options.findIndex(
      (option) => {
        if (startDateTitle) {
          return option.startDateTitle === startDateTitle
        }

        return option.endDateTitle === endDateTitle
      },
    )

    if (foundedFilterIndex < 0) return

    const newFilters = [...filters]
    const newValue = format(parseISO(`${value} 12:00:00`), 'yyyy-MM-dd') // To not mess up with timezone conversion

    newFilters[foundedParent].options[foundedFilterIndex].selectedDate =
      newValue

    if (startDateTitle) {
      newFilters[foundedParent].options[foundedFilterIndex].startDate = newValue
    } else {
      newFilters[foundedParent].options[foundedFilterIndex].endDate = newValue
    }

    setFilters(newFilters)
  }

  return (
    <Stack
      height="calc(100vh - 48px)"
      width="400px"
      right="0px"
      top="50%"
      position="relative"
      direction="column"
      alignItems="center"
      sx={{ zIndex: 1000 }}
    >
      <Stack
        height="100%"
        width="100%"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="32px"
        padding="24px"
        sx={{ overflowY: 'auto' }}
      >
        <Stack
          display="grid"
          width="100%"
          gridAutoRows="max-content"
          gap="16px"
          paddingTop="12px"
          sx={{ overflow: 'hidden' }}
        >
          {filters.map((filter) => (
            <Box width="100%" height="100%" key={filter.title}>
              <Stack
                width="100%"
                height="100%"
                display="grid"
                direction="row"
                gridAutoRows="max-content"
                gridAutoColumns="100%"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="16px"
              >
                {filter.type === 'dropdown' &&
                  filter.options.map((item) => (
                    <Box paddingBottom="32px" key={item.name}>
                      <BaseSelectInput
                        label={item?.placeholder ? item.placeholder : item.name}
                        options={item.options ?? []}
                        value=""
                        fullWidth
                        error=""
                        labelBackground="#ffffff"
                        setState={(value) => onSelectOption(value)}
                      />
                    </Box>
                  ))}

                {filter.type === 'date' &&
                  filter.options.map((item) => (
                    <Box paddingBottom="32px" key={item.name}>
                      <Stack
                        width="99%"
                        direction="row"
                        gap="16px"
                        justifyContent="space-between"
                      >
                        <BaseInput
                          type="date"
                          fullWidth
                          label={item.startDateTitle ?? ''}
                          labelBackground="#ffffff"
                          setState={(event) => {
                            onDateFilterSelect({
                              startDateTitle: item.startDateTitle ?? '',
                              value: event,
                              parentId: filter.id,
                            })
                          }}
                          value={item.startDate ?? ''}
                          error={null}
                        />

                        {!!item?.endDateTitle && (
                          <BaseInput
                            type="date"
                            label={item.endDateTitle ?? ''}
                            labelBackground="#ffffff"
                            setState={(event) => {
                              onDateFilterSelect({
                                endDateTitle: item.endDateTitle ?? '',
                                value: event,
                                parentId: filter.id,
                              })
                            }}
                            value={item.endDate ?? ''}
                            error={null}
                          />
                        )}
                      </Stack>
                    </Box>
                  ))}
              </Stack>
            </Box>
          ))}
        </Stack>

        <Stack
          width="100%"
          justifyContent="flex-end"
          alignItems="flex-start"
          direction="row"
          gap="16px"
        >
          <Button variant="text" onClick={() => HideModal()}>
            Cancelar
          </Button>

          <Button
            variant="contained"
            onClick={() => HideModal()}
            sx={{ width: '140px' }}
          >
            Aplicar filtro
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
