import { CompanyApiRemoteService } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class UpdateAccountingEntriesUseCase
  implements
    IUsecase<
      { companyId: string; entryId: string; field: string; value: string },
      void
    >
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle({
    companyId,
    entryId,
    field,
    value,
  }: {
    companyId: string
    entryId: string
    field: string
    value: string
  }): Promise<Result<void>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response = await this.companyApi.updateAccountingEntries({
        companyId,
        entryId,
        field,
        value,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados do classificador.'),
        )
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
