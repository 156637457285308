import { Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import {
  CompanyUserPermissionTypeEnum,
  ICompanyUser,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'

export const CompanyEditUserModal: React.FC<{
  user: ICompanyUser
  handle: (user: {
    userId: string
    permission: CompanyUserPermissionTypeEnum
  }) => Promise<Result<void>>
  handleHideAndRefetchCompanyUsers: () => void
}> = ({ user, handle, handleHideAndRefetchCompanyUsers }) => {
  const { HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const [modalUser, setModalUser] = useState<ICompanyUser>(user)

  const handleExecute = async () => {
    try {
      const result = await handle({
        userId: modalUser.user.id,
        permission: modalUser.permission,
      })

      if (result.isFailure) {
        ShowToast('error', 'Ocorreu um erro ao alterar o permissionamento.')
        return
      }

      ShowToast('success', 'Usuário alterado com sucesso.')
      handleHideAndRefetchCompanyUsers()
    } catch (error) {
      ShowToast('error', 'Ocorreu um erro ao alterar o permissionamento.')
    }
  }

  const handleChangeUserPermission = (
    permission: CompanyUserPermissionTypeEnum,
  ) => {
    setModalUser({ ...modalUser, permission })
  }

  return (
    <Stack
      width="500px"
      height="auto"
      maxHeight="595px"
      gap="24px"
      justifyContent="space-between"
    >
      <Stack width="100%" height="90%" gap="24px">
        <Stack key={user.id} width="100%" alignItems="center" gap="24px">
          <Stack width="100%" gap="12px" direction="row" alignItems="center">
            <Typography fontWeight="600" fontSize="16px">
              {modalUser.user.name}
            </Typography>
            <Typography fontWeight="400" color="#777C84" fontSize="14px">
              @{modalUser.user.username}
            </Typography>
          </Stack>

          <Stack
            width="100%"
            sx={{
              color: '#4865CC',
            }}
          >
            <BaseInputSingleSelect
              placeholder=""
              options={[
                {
                  label: 'Analista',
                  value: CompanyUserPermissionTypeEnum.ANALISTA,
                },
                {
                  label: 'Master',
                  value: CompanyUserPermissionTypeEnum.MASTER,
                },
              ]}
              showLabel={false}
              value={modalUser.permission}
              onSelect={(e) => handleChangeUserPermission(e)}
              marginBottom={0}
              marginTop={0}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        gap="24px"
        justifyContent="flex-end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => HideModal()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          variant="contained"
          onClick={handleExecute}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Alterar
        </Button>
      </Stack>
    </Stack>
  )
}
