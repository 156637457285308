import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack } from '@mui/material'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { PlanCard } from '../../plan/card'

export const CompanyPlanModal: React.FC<{
  handle: () => void
  plans: {
    id: number
    title: string
    subTitle: string
    price: string
    planOptions: {
      active: boolean
      option: string
    }[]
  }[]
}> = ({ handle, plans = [] }) => {
  const { HideModal } = ModalFullContext()

  return (
    <Stack
      width="932px"
      height="552px"
      borderTop="2px solid #13214E"
      position="relative"
      padding="24px"
    >
      <Box
        sx={{
          position: 'absolute',
          right: '0px',
          top: '-50px',
          cursor: 'pointer',
        }}
        onClick={HideModal}
      >
        <CloseIcon />
      </Box>

      <Stack
        height="552px"
        direction="row"
        gap="32px"
        overflow="auto"
        paddingBottom="12px"
        sx={{
          flexWrap: 'wrap',
          '& > .MuiPaper-root': {
            width: 'calc(50% - 8px)',
            minWidth: '150px',
            marginBottom: '16px',
          },
        }}
      >
        {plans.map((plan, index) => {
          return (
            <PlanCard
              id={plan.id}
              title={plan.title}
              subTitle={plan.subTitle}
              price={plan.price}
              planOptions={plan.planOptions}
              key={index}
              selectionButtonAction={handle}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}
