import { Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { BaseSelectInput } from '../base-component/base-select-input'

export const AccountImportExtractModal: React.FC<{
  handle: (isCsv: boolean, accountId: string) => void
  accountId: string
}> = ({ handle, accountId }) => {
  const { HideModal } = ModalFullContext()
  const [value, setValue] = useState<string>('')

  return (
    <Stack width="240px" height="200px" gap="32px">
      <Typography>Qual o formato de importação</Typography>
      <BaseSelectInput
        label="Formato"
        options={[
          {
            label: 'CSV',
            value: 'CSV',
          },
          {
            label: 'OFX',
            value: 'OFX',
          },
        ]}
        fullWidth
        error=""
        value={value}
        setState={(e) => setValue(e)}
      />

      <Stack direction="row" gap="32px">
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => HideModal()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '220px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          disabled={!value}
          variant={!value ? 'outlined' : 'contained'}
          onClick={() => handle(value === 'CSV', accountId)}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '220px',
            height: '40px',
          }}
        >
          Importar
        </Button>
      </Stack>
    </Stack>
  )
}
