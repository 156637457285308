import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

export type PlanCardProps = {
  data: {
    Icon: () => JSX.Element
    title: string
    value: string
  }[]
}

export const CompanyTotalizerCard = ({ data = [] }: PlanCardProps) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(`(max-width: 1024px)`)

  return (
    <Stack
      width="100%"
      gap="24px"
      height="100%"
      alignItems="center"
      direction="row"
      justifyContent="center"
    >
      {data &&
        data.map(({ Icon, title, value }, index) => {
          return (
            <Stack
              key={`${value}-${index}`}
              width="100%"
              height="120px"
              borderRadius="24px"
              border={`1px solid ${theme.palette.success.main}`}
              sx={{ backgroundColor: theme.palette.common.white }}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Stack
                width="100%"
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap="8px"
              >
                {<Icon />}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      color: '#1E3466',
                      fontSize: isMediumScreen ? '26px' : '32px',
                      fontWeight: 700,
                    }}
                  >
                    {value}
                  </Typography>
                  <Box
                    sx={{
                      color: '#777C84',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      width: '110px',
                    }}
                  >
                    {title}
                  </Box>
                </Stack>
              </Stack>
              <Stack width="90%" justifyContent="center" alignItems="end">
                <Box
                  sx={{
                    color: '#777C84',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  desde Dez/2024
                </Box>
              </Stack>
            </Stack>
          )
        })}
    </Stack>
  )
}
