export const PlanIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#4865CC"
      d="M5.333 5.467H16v1.066H5.333V5.467zm0 5.333h5.39v1.067h-5.39V10.8zm10.252-2.667H5.333V9.2h9.186l1.066-1.067zM8.53 19.381a1.647 1.647 0 001.463.55h5.202a.467.467 0 000-.934H9.992c-.607-.01-.5-.538-.414-.7a2.64 2.64 0 00.258-.61.788.788 0 00-.41-.882 1.266 1.266 0 00-1.494.345c-.23.236-.548.598-.853.95.188-.74.433-1.688.673-2.613a.877.877 0 00-.503-1.084.95.95 0 00-1.184.528c-.288.555-2.568 4.375-2.59 4.414a.467.467 0 10.8.479c.086-.144 1.906-3.193 2.48-4.21-.432 1.666-.908 3.52-.936 3.688a.58.58 0 00.272.646.613.613 0 00.737-.15c.084-.076.241-.256.638-.717.37-.435.748-.862 1.135-1.28.146-.154.234-.086.148.065a1.527 1.527 0 00-.22 1.515z"
    ></path>
    <path
      fill="#4865CC"
      d="M18.667 14.464v6.87h-16V2.667h16v3.329l.886-.887c.137-.134.287-.255.447-.36V2.001a.667.667 0 00-.667-.667H2a.667.667 0 00-.667.667v20a.667.667 0 00.667.666h17.333a.667.667 0 00.667-.666v-7.716a1.94 1.94 0 01-1.333.179z"
    ></path>
    <path
      fill="#4865CC"
      d="M22.752 7.907l-.349-.349a1.265 1.265 0 00-.073-1.615 1.304 1.304 0 00-1.833.108l-8.35 8.349-.558 2.094a.156.156 0 00.197.196l2.087-.564 7.795-7.795.33.33a.248.248 0 010 .35l-3.279 3.278a.533.533 0 10.755.754l3.278-3.278a1.315 1.315 0 000-1.859z"
    ></path>
  </svg>
)
