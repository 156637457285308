import {
  CompanyApiRemoteService,
  CompanyDebitRequest,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCompanyDebitUseCase
  implements IUsecase<CompanyDebitRequest, string[]>
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle(data: CompanyDebitRequest): Promise<Result<string[]>> {
    try {
      const { companyId, query } = data
      const response = await this.companyApi.getCompanyDebit(companyId, query)

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados dos clientes e fornecedores.'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados '))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
