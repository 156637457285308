import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'
import { IBankAccount, IBankTransfersImport } from './service-account'

export type IBank = {
  ispb: string
  name: string
  code: string
  fullName: string
}

export enum BankAccountTransferStatusEnum {
  TRANSACAO_EFETIVADA = 'TRANSACAO_EFETIVADA',
  LANCAMENTO_FUTURO = 'LANCAMENTO_FUTURO',
  TRANSACAO_PROCESSANDO = 'TRANSACAO_PROCESSANDO',
}

export enum BankAccountTransferMethodEnum {
  CREDITO = 'CREDITO',
  DEBITO = 'DEBITO',
}

export interface IBankAccountTransfer {
  id: string
  externalId: string
  status: BankAccountTransferStatusEnum
  type: any // validar no back, hoje retorna sempre null
  method: BankAccountTransferMethodEnum
  amount: string
  currency: string
  date: string
  partie: {
    name: string
    type: any // validar no back, hoje retorna sempre null
    document: any // validar no back, hoje retorna sempre null
  }
  transferCompleteDescription: string
  createdAt: string
  updatedAt: string
  bankAccount: IBankAccount
}

export interface IBankAccountTransferResponse {
  transfers: IBankAccountTransfer[]
  balance: string
}

export interface ICashFlowConsolidate {
  total: string
  inflow: string
  outflow: string
}
export interface ICashFlow {
  id: string
  month: string
  year: string
  balance: string
  inflow: string
  outflow: string
  createdAt: string
  updatedAt: string
  bankAccount: IBankAccount
}

export interface ICashFlowResponse {
  cashflows: ICashFlow[]
  consolidate: ICashFlowConsolidate
}

export class BankApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public listBanks = async (): Promise<Result<IBank[]>> => {
    try {
      const { data }: { data: IBank[] } = await this.service.get('/banks/v1', {
        baseURL: 'https://brasilapi.com.br/api',
      })

      const response = data
        .filter((bank) => bank.code)
        .map((item) => {
          const code = item.code.toString().padStart(3, '0')
          return {
            ...item,
            name: `${code} - ${item.name.trim()}`,
            fullName: `${code} - ${item.fullName.trim()}`,
            code: item.code.toString(),
          }
        })

      return Result.ok(response)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listBankAccountsTransfer = async ({
    accountsIds,
    date,
  }: {
    accountsIds?: string[]
    date?: string[]
  }): Promise<Result<IBankAccountTransferResponse>> => {
    try {
      const { data } = await this.service.get('/v1/bank/transfer', {
        params: {
          accountId:
            accountsIds && accountsIds?.length > 0 ? [...accountsIds] : [],
          date,
        },
      })

      return Result.ok({ transfers: data.transfers, balance: data.balance })
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listBankAccountsTransferHistory = async ({
    bankAccountId,
    companyId,
  }: {
    bankAccountId: string
    companyId: string
  }): Promise<Result<IBankTransfersImport[]>> => {
    try {
      const { data } = await this.service.get(
        `/v1/bank/${companyId}/bank-account/${bankAccountId}/import/history`,
      )

      return Result.ok(data.bankTransfersImport.transfersImport)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public deleteBankAccountsTransferHistory = async ({
    companyId,
    bankAccountId,
    importedTransfersId,
  }: {
    companyId: string
    bankAccountId: string
    importedTransfersId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(
        `/v1/bank/${companyId}/bank-account/${bankAccountId}/import/${importedTransfersId}`,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listCashFlow = async ({
    companiesIds,
    accountIds,
    date,
  }: {
    companiesIds?: string[]
    accountIds?: string[]
    date?: string[]
  }): Promise<Result<ICashFlowResponse>> => {
    try {
      const { data } = await this.service.get('/v1/bank/cashflow', {
        params: {
          companyId: companiesIds,
          accountId: accountIds,
          date,
        },
      })

      return Result.ok({
        cashflows: data.cashflows,
        consolidate: data.consolidate,
      })
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public openFinanceSync = async ({
    bankAccountId,
    companyId,
  }: {
    bankAccountId: string
    companyId: string
  }): Promise<Result<void>> => {
    try {
      const result = await this.service.post(
        `/v1/bank/${companyId}/bank-account/${bankAccountId}/open-finance/sync`,
        {
          params: {
            bankAccountId,
            companyId,
          },
        },
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
