import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { Box, Stack, Typography } from '@mui/material'
import logo from '../../assets/bank/itau-medium.jpg'
import { Logo } from '../base-component/base-logo-component'
import { CalendarIcon } from '../icons/calendar'
import { UsersIcon } from '../icons/users'
import { WalletIcon } from '../icons/wallet'

export type AccountCardProps = {
  id: number
  title: string
  accountType: string
  personType: string
  amount: string
  initialDate: string
  defaultLogo: string | undefined
  bankName: string
}

export const AccountDetailsCard = ({
  id,
  title,
  accountType,
  personType,
  amount,
  initialDate,
  defaultLogo,
  bankName,
}: AccountCardProps) => {
  return (
    <Stack
      key={`account-card-${id}`}
      width="100%"
      maxWidth="500px"
      height="auto"
      maxHeight="470px"
      borderRadius="8px"
      border="1px solid #B9BFC7"
      padding="24px"
      gap="24px"
      direction="row"
    >
      {/* Header */}
      <Stack
        padding="12px 24px"
        paddingBottom="0px"
        gap="12px"
        alignItems="center"
        direction="column"
      >
        <Box width="96px" height="96px">
          <Logo name={bankName} width={96} height={96} logo={defaultLogo} />
        </Box>
        <Typography
          key={`account-card-details-title-${id}`}
          fontWeight="700"
          fontSize="16px"
        >
          {title}
        </Typography>
      </Stack>
      {/* Content */}
      <Stack gap="24px" paddingLeft="32px" borderLeft="1px solid #B9BFC7">
        <Stack
          width="100%"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap="12px" direction="row">
            <WalletIcon />
            <Typography
              key={`account-card-details-type-title-${id}`}
              fontWeight="600"
              fontSize="14px"
              gap="12px"
            >
              Tipo de conta
            </Typography>
          </Stack>

          <Typography
            key={`account-card-details-type-${id}`}
            fontWeight="400"
            fontSize="14px"
          >
            {accountType}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap="12px" direction="row">
            <UsersIcon />
            <Typography
              key={`account-card-details-person-title-${id}`}
              fontWeight="600"
              fontSize="14px"
              gap="12px"
            >
              Tipo de pessoa
            </Typography>
          </Stack>

          <Typography
            key={`account-card-details-person-${id}`}
            fontWeight="400"
            fontSize="14px"
          >
            {personType}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap="12px" direction="row">
            <CalendarIcon />
            <Typography
              key={`account-card-details-date-title-${id}`}
              fontWeight="600"
              fontSize="14px"
              gap="12px"
            >
              Início dos lançamentos
            </Typography>
          </Stack>

          <Typography
            key={`account-card-details-date-${id}`}
            fontWeight="400"
            fontSize="14px"
          >
            {initialDate}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap="12px" direction="row">
            <AttachMoneyIcon sx={{ color: '#4865CC' }} />
            <Typography
              key={`account-card-details-amount-title-${id}`}
              fontWeight="600"
              fontSize="14px"
              gap="12px"
            >
              Saldo final bancário
            </Typography>
          </Stack>

          <Typography
            key={`account-card-details-amount-${id}`}
            fontWeight="400"
            fontSize="14px"
          >
            {amount}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
