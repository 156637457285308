import { useState } from 'react'
import { Result } from 'src/shared/protocol/protoco-result'
import { Schema } from 'yup'

export const CreateForm = ({
  fields,
  validation,
}: {
  fields: (string | { [key: string]: string })[]
  validation: Schema<any>
}) => {
  const form: {
    fields: {
      [key: string]: {
        value: any
        setState: (value: any) => void
        error: string | null
        setError: (value: any) => void
      }
    }
    validate: () => Promise<Result<unknown>>
  } = {
    fields: {},
    validate: async () => {
      try {
        await validation.validate(
          Object.fromEntries(
            Object.entries(form.fields).map(([key, value]) => [
              key,
              value.value,
            ]),
          ),
          { abortEarly: false },
        )

        return Result.ok()
      } catch (error: any) {
        console.log('ValidationError inner', error?.inner)

        if (error?.name === 'ValidationError') {
          Object.entries(form.fields).forEach(([key, value]) => {
            if (error?.inner?.some((err: any) => err?.path === key)) {
              form.fields[key].setError(
                error?.inner?.find((err: any) => err?.path === key)?.message ||
                  null,
              )
            }
          })
        }
        return Result.fail(error)
      }
    },
  }

  fields.forEach((field) => {
    const fieldObject =
      typeof field !== 'string'
        ? Object.entries(field)?.[0]
          ? {
              key: Object.entries(field)?.[0]?.[0],
              value: Object.entries(field)?.[0]?.[1],
            }
          : null
        : null
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fieldValue, setFieldValue] = useState<string>(
      !fieldObject ? '' : fieldObject.value || '',
    )
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fieldError, setFieldError] = useState<string | null>(null)

    const key = !fieldObject ? field : fieldObject.key
    form.fields[typeof key === 'string' ? key : fieldObject?.key || ''] = {
      value: fieldValue,
      setState: setFieldValue,
      error: fieldError,
      setError: setFieldError,
    }
  })

  return form
}
