import { Box } from '@mui/material'
import PropTypes from 'prop-types'

export const ColorCircle = ({
  color,
  width = 8,
  height = 8,
}: {
  color: string
  width: number
  height: number
}) => {
  return (
    <Box
      sx={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: color,
        borderRadius: '50%',
      }}
    />
  )
}

ColorCircle.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
