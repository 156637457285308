import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'

export type TUserOwnInfoResponse = {
  user: {
    id?: string
    name: string
    companies?: {
      id?: string
      name?: string
      cnpj?: string
    }[]
    companyName: string
    personType: string
    cpfCnpj: string
    username: string
    termsOfUse: string
    email: string
    backupCode: string
    twoAuthEnabled: boolean
  }
}

export type TUserProfileResponse = {
  user: {
    id: string
    name: string
    companyName: string
    personType: string
    cpfCnpj: string
    username: string
    termsOfUse: string
    email: string
    backupCode: string
    twoAuthEnabled: boolean
    emailRecoveryCode: string
    twoAuthSecret: string
  }
}

export type TProfileUpdateDTO = {
  name: string
  username: string
  oldPassword?: string
  password?: string
  twoAuthEnabled?: boolean
}

export type TListUsersResponse = {
  users: {
    id: string
    name: string
    companyName: string
    personType: string
    cpfCnpj: string
    username: string
  }
}

export class UserApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public ownInfo = async (): Promise<Result<TUserOwnInfoResponse>> => {
    try {
      const result = await this.service.get(`/v1/user/info`)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getProfile = async (): Promise<Result<TUserProfileResponse>> => {
    try {
      const result = await this.service.get(`/v1/user/profile`)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public update = async (
    data: TProfileUpdateDTO,
  ): Promise<Result<TUserProfileResponse['user']>> => {
    try {
      const result = (await this.service.put(
        `/v1/user/profile`,
        data,
      )) as TUserProfileResponse
      return Result.ok(result.user)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listUsers = async (): Promise<
    Result<TListUsersResponse['users'][]>
  > => {
    try {
      const result = await this.service.get(`/v1/user/all`)
      return Result.ok(result.data.users)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
