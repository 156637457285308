export const TotalizerReleasesIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
  >
    <circle cx="24" cy="24" r="24" fill="#5ED1A2"></circle>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M25.333 12H14a2 2 0 00-2 2v14.667a2 2 0 002 2h20a2 2 0 002-2v-4M24 30.667V36M17.333 36h13.334M29.333 16.667L32.667 20 36 16.667M32.667 12v8"
    ></path>
  </svg>
)
