import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'

export enum UserPersonTypeEnum {
  FISICA = 'FISICA',
  JURIDICA = 'JURIDICA',
}

export type TAuthRegisterDTO = {
  personType: UserPersonTypeEnum
  name: string
  cpfCnpj: string
  companyName: string
  password: string
  username: string
}

export type TAuthLoginDTO = {
  username: string
  password: string
}
export type TAuthLoginResponse = {
  token: string
  refresh: string
}

export type TAuthRecoveryEmailDTO = {
  email: string
}

export type TAuthRecovery2FAResponse = {
  token: string
  qrcode: string
}

export type TAuthRecovery2FAFinishDTO = {
  secret: string
  token: string
}

export type TAuthRecoveryWithPinDTO = {
  username: string
  code: string
  password: string
}

export type TAuthRecoveryWithEmailDTO = {
  email: string
}

export type TAuthRecoveryWithEmailFinishDTO = {
  code: string
  password: string
  email: string
}

export type TAuthRecoveryWith2FADTO = {
  username: string
  code: string
  password: string
}

export class AuthenticationApiRemoteService {
  constructor(private service: AxiosInstance) {}

  // Auth
  public register = async (data: TAuthRegisterDTO): Promise<Result<void>> => {
    try {
      const result = await this.service.post(`/v1/user/register`, data)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public login = async (
    data: TAuthLoginDTO,
  ): Promise<Result<TAuthLoginResponse>> => {
    try {
      const result = await this.service.post(`/v1/user/login`, data)

      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  // Setup recovery
  public recoveryEmail = async (
    data: TAuthRecoveryEmailDTO,
  ): Promise<Result<void>> => {
    try {
      const result = await this.service.patch(`/v1/user/recovery/email`, data)

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public recovery2FAInit = async (): Promise<
    Result<TAuthRecovery2FAResponse>
  > => {
    try {
      const result = await this.service.post(
        `/v1/user/recovery/two-auth/enable/first-step`,
      )

      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public recovery2FAFinish = async (
    data: TAuthRecovery2FAFinishDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/user/recovery/two-auth/enable/last-step`,
        data,
      )

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  // Recovery
  public recoveryWithPin = async (
    data: TAuthRecoveryWithPinDTO,
  ): Promise<Result<void>> => {
    try {
      const result = await this.service.post(
        `/v1/user/recovery/backup-code`,
        data,
      )

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public recoveryWithEmailStart = async (
    data: TAuthRecoveryWithEmailDTO,
  ): Promise<Result<void>> => {
    try {
      const result = await this.service.post(`/v1/user/recovery/email`, data)

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public recoveryWithEmailFinish = async (
    data: TAuthRecoveryWithEmailFinishDTO,
  ): Promise<Result<void>> => {
    try {
      const result = await this.service.post(
        `/v1/user/recovery/email/finish`,
        data,
      )

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public recoveryWith2FA = async (
    data: TAuthRecoveryWith2FADTO,
  ): Promise<Result<void>> => {
    try {
      const result = await this.service.post(`/v1/user/recovery/2fa`, data)

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
