import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { UserFullContext } from 'src/context/context-user'
import { CreateForm } from 'src/shared/util/form/form-util'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'

interface LoginPageProps {
  useCase: LoginUseCase
  userOwnDataUseCase: UserOwnDataUseCase
}

export function LoginPage(props: LoginPageProps): any {
  const { ShowToast } = ToastFullContext()
  const { UserInfo } = UserFullContext()

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const loginForm = CreateForm({
    fields: ['username', 'password'],
    validation: props.useCase.validation,
  })

  const handleLoginUsecase = async () => {
    try {
      setLoading(true)

      const usecaseResult = await props.useCase.handle({
        username: loginForm.fields.username.value,
        password: loginForm.fields.password.value,
      })

      setLoading(false)
      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro efetuar o login. Verifique os dados de acesso e tente novamente.',
        )
        return
      }

      const userResult = await props.userOwnDataUseCase.handle()

      if (userResult.isFailure) {
        return ShowToast(
          'error',
          'Ocorreu um erro efetuar o login. Verifique os dados de acesso e tente novamente.',
        )
      }

      const user = userResult.getValue()

      await UserInfo({
        id: user?.id,
        name: user?.name,
        companies: user?.companies,
        companyName: user?.companyName,
        personType: user?.personType,
        cpfCnpj: user?.cpfCnpj,
        username: user?.username,
        termsOfUse: user?.termsOfUse,
        email: user?.email,
        backupCode: user?.backupCode,
        twoAuthEnabled: user?.twoAuthEnabled,
      })

      return navigate('/home')
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleLogin = async () => {
    try {
      const validationResult = await loginForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      handleLoginUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleForgotPassword = () => {
    navigate('/auth/recovery/backup-code')
  }

  const handleRegister = () => {
    navigate('/auth/sign-up')
  }

  const handleForgotUsername = () => {
    navigate('/contact-us')
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Login
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '64px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: 'max-content',
              gap: '32px',
              width: '100%',
            }}
          >
            <BaseInput
              label="Username"
              fullWidth
              {...loginForm.fields.username}
            />
            <BaseInput
              label="Senha"
              type="password"
              fullWidth
              {...loginForm.fields.password}
            />
          </Box>
          <Button
            variant="text"
            onClick={handleForgotPassword}
            sx={{
              marginBottom: '64px',
              marginTop: '8px',
              justifySelf: 'end',
            }}
          >
            Esqueci minha senha
          </Button>
          {!loading && (
            <>
              <Button variant="contained" fullWidth onClick={handleLogin}>
                Entrar
              </Button>
            </>
          )}
          {loading && (
            <CircularProgress
              sx={{
                justifySelf: 'center',
                justifyItems: 'center',
              }}
            />
          )}
          <Button
            variant="text"
            fullWidth
            onClick={handleRegister}
            sx={{
              marginTop: '24px',
            }}
          >
            Novo por aqui? Cadastre-se
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={handleForgotUsername}
            sx={{
              marginTop: '24px',
            }}
          >
            Esqueceu seu username? Fale conosco
          </Button>
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
