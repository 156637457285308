import { CircularProgress, Stack, Typography } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { IListCompanyFilterDTO } from 'src/service/service-company'
import { TableHeader } from './header'

export const columnPadding = 32
export const ColumnsCommon: GridColDef = {
  headerAlign: 'left',
  align: 'left',
  headerName: 'Placeholder',
  flex: 1,
  headerClassName: 'super-app-theme--header',
  cellClassName: 'super-app-theme--normal-column',
  disableColumnMenu: true,
  disableReorder: true,
  disableExport: true,
  filterable: false,
  resizable: false,
  sortable: false,
  field: 'Placeholder',
}

export type TableProps = {
  rows: any[]
  columns: GridColDef[]
  loading: boolean
  allowSelectAll: boolean
  loadMore: (page: number) => void
  showSearchButton?: boolean
  searchQuery?: string
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>
  showFilterButton?: boolean
  filter?: IListCompanyFilterDTO
  setFilter?: React.Dispatch<React.SetStateAction<IListCompanyFilterDTO>>
  handleOpenFilterModal?: () => void
  selectedItemActions?: {
    title: string
    action: (selectedItems: number[]) => void
    disabled?: boolean
  }[]
  setSelectedGridIds?: React.Dispatch<React.SetStateAction<string[]>>
  rightActions?: React.ReactNode
  leftActions?: React.ReactNode
  checkboxSelection?: boolean
  tableStyle?: object
  tableContainerStyle?: object
  currentPage: number
  totalRegisters: number
  totalPages: number
  showNoResultsOverlay?: boolean
}

export const Table = ({
  rows,
  columns,
  loading,
  totalRegisters,
  totalPages,
  currentPage = 1,
  loadMore,
  selectedItemActions = [],
  filter,
  searchQuery,
  setFilter,
  setSearchQuery,
  setSelectedGridIds,
  showFilterButton = false,
  showSearchButton = false,
  handleOpenFilterModal,
  rightActions,
  leftActions,
  checkboxSelection = true,
  tableStyle = {},
  tableContainerStyle = {},
  showNoResultsOverlay = true,
}: TableProps) => {
  const tableRef = useRef<HTMLInputElement>(null)
  const [selectedItems, setSelectedItems] = useState<any[]>([])

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    const selectedRows = newSelection.map((id) =>
      rows.find((row: any) => row.id === id),
    )
    setSelectedItems(selectedRows)
    setSelectedGridIds &&
      setSelectedGridIds(selectedRows?.map((row) => row.id) ?? [])
  }

  const handleScroll = useCallback(() => {
    const { current } = tableRef
    if (current) {
      const bottom =
        current.scrollHeight - current.scrollTop === current.clientHeight
      if (bottom && !loading && currentPage < totalPages) {
        loadMore(currentPage + 1)
      }
    }
  }, [loading, currentPage, totalPages, loadMore])

  useEffect(() => {
    const { current } = tableRef
    if (current) {
      current.addEventListener('scroll', handleScroll)
      return () => {
        current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <Stack width="100%" gap="32px" sx={tableContainerStyle}>
      <TableHeader
        selectedItems={selectedItems}
        filter={filter}
        handleOpenFilterModal={handleOpenFilterModal}
        searchQuery={searchQuery}
        selectedItemActions={selectedItemActions}
        setFilter={setFilter}
        setSearchQuery={setSearchQuery}
        showFilterButton={showFilterButton}
        showSearchButton={showSearchButton}
        rightActions={rightActions}
        leftActions={leftActions}
      />

      <div
        ref={tableRef}
        style={{
          height: 'auto',
          width: '100%',
          overflow: 'auto',
          maxHeight: 'calc(100% - 56px - 32px)',
          backgroundColor: rows.length === 0 ? 'transparent' : '#ffffff',
          border: 'none !important',
        }}
      >
        {showNoResultsOverlay && rows?.length === 0 && (
          <Stack width="100%" justifyContent="center" alignItems="center">
            {loading && <CircularProgress />}
            {!loading && (
              <Typography sx={{ padding: 2 }}>
                Não há nenhum registro cadastrado no momento
              </Typography>
            )}
          </Stack>
        )}
        {(!showNoResultsOverlay || rows?.length > 0) && (
          <DataGrid
            sx={{
              height: 'auto',
              width: '100%',
              minHeight: '120px',
              backgroundColor: '#ffffff',
              border: 'none !important',
              borderTopRightRadius: '16px',
              borderTopLeftRadius: '16px',
              '& .MuiCheckbox-root': {
                color: '#4865CC !important',
              },
              '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
                color: '#ffffff !important',
              },
              '& .super-app-theme--header': {
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                height: 56,
                textAlign: 'center',
                color: '#ffffff',
                gap: '4px',
                wordBreak: 'break-word',
                border: 'none !important',
                borderBottom: 'none !important',
                borderRadius: '0px',
                borderTop: 'none',
                ':first-child': {
                  borderLeft: 'none',
                },
                ':last-child': {
                  borderTopRightRadius: '16px ',
                },
                backgroundColor: '#5ED1A2',
              },
              '& .MuiDataGrid-columnHeaderCheckbox': {
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 16,
                height: 56,
                textAlign: 'center',
                color: '#ffffff',
                gap: '4px',
                wordBreak: 'break-word',
                borderRadius: '0px',
                border: 'none',
                borderBottom: '1px solid #DFEAED',
                ':first-child': {
                  borderLeft: 'none',
                },
                ':last-child': {
                  borderRight: 'none',
                  borderTopRightRadius: '16px',
                },
                backgroundColor: '#5ED1A2',
                borderTopLeftRadius: '16px',
                borderColor: '#FFF',
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .super-app-theme--normal-column .MuiDataGrid-cellCheckbox': {
                fontFamily: 'Noto Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                height: 56,
                borderTopLeftRadius: 10,
                textAlign: 'left',
                color: '#383838',
                backgroundColor: '#ffffff !important',
                borderRadius: '0px',
                border: 'none',
                borderBottom: '1px solid #DFEAED',
                ':first-child': {
                  borderLeft: 'none',
                },
                ':last-child': {
                  borderRight: 'none',
                },
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#ffffff !important',
              },
              '& .MuiDataGrid-columnHeader--last': {
                borderTopRightRadius: '16px',
              },
              ...tableStyle,
            }}
            rows={rows ?? []}
            columns={columns}
            loading={loading}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            rowSelectionModel={
              selectedItems.map((item) => item.id) as GridRowId[]
            }
            hideFooterPagination
            hideFooter
            slots={{
              noRowsOverlay: () => (
                <>
                  {loading && <CircularProgress />}
                  {!loading && (
                    <Typography sx={{ padding: 2 }}>
                      Não há nenhum registro cadastrado no momento
                    </Typography>
                  )}
                </>
              ),
              noResultsOverlay: () => (
                <>
                  {loading && <CircularProgress />}
                  {!loading && (
                    <Typography sx={{ padding: 2 }}>
                      Não há nenhum registro cadastrado no momento
                    </Typography>
                  )}
                </>
              ),
            }}
          />
        )}
      </div>
    </Stack>
  )
}
