import React, { useEffect, useState } from 'react'
import qrcode from 'qrcode'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'

interface BaseQRCodeProps {
  qrcodeUrl: string
  backgroundColor?: string
}

export const BaseQRCode: React.FC<BaseQRCodeProps> = ({
  qrcodeUrl,
  backgroundColor,
}: BaseQRCodeProps) => {
  const { ShowToast } = ToastFullContext()
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  useEffect(() => {
    qrcode.toDataURL(
      qrcodeUrl,
      {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        rendererOpts: {
          quality: 1,
        },
        width: 200,
        margin: 0,
        color: {
          dark: '#000',
          light: backgroundColor ?? '#F4F8FA',
        },
      },
      function (err, url) {
        if (err) {
          console.error(err)
          ShowToast('error', 'Erro ao gerar o QR Code')
          return
        }
        setImageUrl(url)
      },
    )
  }, [qrcodeUrl])
  return <>{imageUrl && <img src={imageUrl} alt="QR Code" />}</>
}
