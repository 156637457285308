import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { ModalFullContext } from '../modal-provider'

export const UpdateCompanyMassImportModal: React.FC<{
  id: number
  companyName: string
  companyCNPJ: string
  onConfirm: (itemId: number, companyName: string, companyCNPJ: string) => void
  height: number
  width: number
}> = ({ id, companyName, companyCNPJ, onConfirm, height, width }) => {
  const { ShowToast } = ToastFullContext()
  const { HideModal } = ModalFullContext()
  const [name, setName] = useState<string>(companyName)
  const [CNPJ, setCNPJ] = useState<string>(companyCNPJ)

  const handleConfirm = async () => {
    if (!name || !CNPJ) return ShowToast('error', 'Preencha todos os campos')
    onConfirm && (await onConfirm(id, name, CNPJ))
  }

  const handleCancel = () => {
    setName('')
    setCNPJ('')
    HideModal()
  }

  return (
    <Stack
      width="100%"
      maxWidth={`${width}px`}
      height={`${height}px`}
      gap="32px"
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '400',
        }}
      >
        Altere os dados da empresa a ser importada.
      </Typography>
      <Box
        sx={{
          marginTop: '24px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            gap: '24px',
            width: '440px',
          }}
        >
          <BaseInput
            label="Nome"
            fullWidth
            value={name}
            setState={(e) => setName(e)}
            error={null}
          />
          <BaseInput
            label="CNPJ"
            fullWidth
            value={CNPJ}
            mask={CNPJ.length < 11 ? '999.999.999-99' : '99.999.999/9999-99'}
            setState={(e) => setCNPJ(e)}
            error={null}
          />
        </Box>
      </Box>
      <Stack
        direction="row"
        gap="16px"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          variant="contained"
          onClick={() => handleConfirm()}
        >
          Alterar
        </Button>
      </Stack>
    </Stack>
  )
}
