import { Checkbox, Stack, Typography, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import {
  CompanyClassificationRuleConditionalsConditionEnumUtil,
  CompanyClassificationRuleConditionalsVariableEnum,
  ICompanyClassificationRule,
} from 'src/service/service-company'
import { BaseInput } from '../../base-component/base-input'

export const ClassificationRuleViewModal: React.FC<{
  classificationRule: ICompanyClassificationRule
}> = ({ classificationRule }) => {
  const isSmallScreen = useMediaQuery(`(max-width: 1024px)`)

  return (
    <Stack
      width="960px"
      maxWidth={isSmallScreen ? '90vw' : '960px'}
      height="auto"
      minHeight="240px"
      maxHeight="80vh"
      position="relative"
      borderTop="1px solid #13214E"
    >
      <Stack
        width="100%"
        height="100%"
        direction="row"
        gap="32px"
        marginTop="24px"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        paddingRight="8px"
      >
        {/* Left Content */}
        <Stack width="57%" height="100%" gap="24px">
          <Stack marginTop="8px" width="100%" direction="row" gap="16px">
            <BaseInput
              label="Código"
              disabled
              type="text"
              value={classificationRule.accountingChartItem.code}
              setState={() => null}
              error=""
            />
            <BaseInput
              label="Nome da regra"
              disabled
              type="text"
              fullWidth={true}
              value={classificationRule.name}
              setState={() => null}
              error=""
            />
          </Stack>

          <Stack width="100%" alignItems="center" direction="row" gap="16px">
            <Checkbox
              sx={{
                color: '#1E1E1E',
                '&.Mui-checked': {
                  color: '#1E1E1E',
                },
              }}
              checked={classificationRule.generalRule}
              disabled
            />
            <Typography fontSize="16px" color="#1E1E1E">
              Toda e qualquer transação da empresa
            </Typography>
          </Stack>

          <Stack
            width="100%"
            flexWrap="wrap"
            gap="24px"
            borderTop="1px dashed #CCCCCC"
          >
            <Stack marginTop="24px" width="100%" gap="16px" direction="row">
              <BaseInput
                label="Crédito"
                disabled
                type="text"
                fullWidth={true}
                value={classificationRule.credit ?? ''}
                setState={() => null}
                error=""
              />
              <BaseInput
                label="Débito"
                disabled
                type="text"
                fullWidth={true}
                value={classificationRule.debit ?? ''}
                setState={() => null}
                error=""
              />
            </Stack>
            <Stack width="100%" gap="16px" direction="row">
              <BaseInput
                label="Categoria financeira"
                disabled
                type="text"
                fullWidth={true}
                value={classificationRule.financialCategory ?? ''}
                setState={() => null}
                error=""
              />
              <BaseInput
                label="Histórico"
                disabled
                type="text"
                fullWidth={true}
                value={classificationRule.historic ?? ''}
                setState={() => null}
                error=""
              />
            </Stack>
          </Stack>
        </Stack>
        {/* Right Content */}
        <Stack height="100%" width="43%" maxWidth="420px">
          <Stack
            height="100%"
            minHeight="240px"
            gap="16px"
            borderRadius="16px"
            padding="24px"
            flexWrap="wrap"
            sx={{ backgroundColor: '#F4F8FA' }}
          >
            {classificationRule.conditionals &&
              classificationRule.conditionals.map((rule, index) => {
                const conditionDescription =
                  CompanyClassificationRuleConditionalsConditionEnumUtil.toString(
                    rule.condition,
                  )
                let variableContent = ''

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY
                ) {
                  variableContent = rule.data.thirdParty?.name ?? ''
                }

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.DATE
                ) {
                  variableContent = `Dia ${rule.data.date?.init}`
                  if (rule.data.date?.end !== rule.data.date?.init) {
                    variableContent += ` e ${rule.data.date?.end}`
                  }
                }

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.VALUE
                ) {
                  variableContent = rule.data.value?.value
                    ? `R$ ${rule.data.value?.value}`
                    : 'R$ 0,00'
                }

                if (
                  rule.variable ===
                  CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION
                ) {
                  variableContent = rule.data.description?.description ?? ''
                }

                return (
                  <Stack key={index} width="100%" gap="16px" direction="row">
                    <Stack
                      padding="8px 16px 8px 16px"
                      gap="8px"
                      borderRadius="16px"
                      color="#FFFFFF"
                      sx={{
                        backgroundColor: '#5ED1A2',
                      }}
                    >
                      {conditionDescription}
                    </Stack>

                    <Stack
                      padding="8px 16px 8px 16px"
                      gap="8px"
                      borderRadius="16px"
                      color="#FFFFFF"
                      sx={{
                        backgroundColor: '#1E3466',
                      }}
                    >
                      {variableContent}
                    </Stack>
                  </Stack>
                )
              })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
