import { Typography } from '@mui/material'

export type BasePageSubtitleProps = {
  color: string
  text: string
  style?: object
}
export const BasePageSubtitle = ({
  text,
  color = '#1E3466',
  style,
}: BasePageSubtitleProps) => {
  return (
    <Typography
      fontSize="24px"
      fontWeight="700"
      lineHeight="32.69px"
      color={color}
      style={style}
    >
      {text}
    </Typography>
  )
}
