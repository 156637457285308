import React, { FC } from 'react'
import { Stack } from '@mui/material'

interface Props {
  leftComponent?: React.ReactNode
  rightComponent?: React.ReactNode
}

export const BaseInternalHeader: FC<Props> = ({
  leftComponent,
  rightComponent,
}) => {
  return (
    <Stack
      flexDirection="row"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      {leftComponent}
      {rightComponent}
    </Stack>
  )
}
