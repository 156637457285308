import { useEffect, useRef } from 'react'
import {
  ModalFullContext,
  ModalPositionEnum,
} from 'src/component/modal/modal-provider'
import {
  Modal as MuiModal,
  Backdrop,
  Fade,
  Box,
  Typography,
  useTheme,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export const Modal: React.FC = () => {
  const theme = useTheme()
  const { GetModalInformations, HideModal } = ModalFullContext()
  const modalRef = useRef<HTMLInputElement>(null)
  const { content, title, subtitle, closeButton, closeOnBackgroundClick } =
    GetModalInformations()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target === modalRef?.current) {
        if (
          GetModalInformations().content !== null &&
          GetModalInformations().closeOnBackgroundClick
        ) {
          HideModal()
        }
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [GetModalInformations, HideModal])

  return (
    <>
      {GetModalInformations().content !== null && (
        <MuiModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={GetModalInformations().content !== null}
          onClose={HideModal}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={GetModalInformations().content !== null}>
            <Box
              sx={{
                borderRadius: '16px',
                padding: '32px',
                backgroundColor: 'white',
                position: 'absolute' as const,
                top:
                  GetModalInformations().position === ModalPositionEnum.right
                    ? '0px'
                    : '50%',
                left:
                  GetModalInformations().position === ModalPositionEnum.right
                    ? 'auto'
                    : '50%',
                right:
                  GetModalInformations().position === ModalPositionEnum.right
                    ? '0px'
                    : 'auto',
                transform:
                  GetModalInformations().position === ModalPositionEnum.right
                    ? 'initial'
                    : 'translate(-50%, -50%)',
                justifySelf:
                  GetModalInformations().position === ModalPositionEnum.right
                    ? 'right'
                    : 'center',
                ...GetModalInformations()?.style,
              }}
            >
              <Box>
                {title && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '24px',
                        color: theme.palette.primary.dark,
                        paddingBottom: '16px',
                      }}
                    >
                      {title}
                    </Typography>
                    {closeButton && (
                      <Box
                        onClick={() => HideModal()}
                        sx={{ cursor: 'pointer' }}
                      >
                        <CloseIcon />
                      </Box>
                    )}
                  </Stack>
                )}
                {content}
              </Box>
            </Box>
          </Fade>
        </MuiModal>
      )}
    </>
  )
}
