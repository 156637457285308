import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { BaseQRCode } from 'src/component/base-component/base-qrcode'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { TAuthRecovery2FAResponse } from 'src/service/service-authentication'

export const EnableProfile2FAModal: React.FC<{
  handleConfirmAction: (secret: string, token: string) => void
  handleCancelAction: () => void
  handleGenerateNewQrCode: () => Promise<TAuthRecovery2FAResponse | undefined>
  height: number
  width: number
}> = ({
  handleConfirmAction,
  handleCancelAction,
  handleGenerateNewQrCode,
  height,
  width,
}) => {
  const theme = useTheme()
  const { ShowToast } = ToastFullContext()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState<string>('')
  const [secret, setSecret] = useState<string>('')
  const [qrcode, setQrcode] = useState<string | null>(null)
  const [validateStep, setValidateStep] = useState<boolean>(false)

  const handleGetQRCode = async () => {
    try {
      const response = await handleGenerateNewQrCode()

      if (response?.qrcode) {
        setQrcode(response?.qrcode)
        setSecret(response?.token)
      }
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    if (validateStep) return
    handleGetQRCode()
  }, [validateStep])

  const handleConfirm = async () => {
    if (token.length < 6) {
      return ShowToast('error', 'O código deve ter 6 dígitos')
    }
    setLoading(true)
    handleConfirmAction && (await handleConfirmAction(secret, token))
    setLoading(false)
  }

  const handleCancel = () => {
    setSecret('')
    setQrcode('')
    setToken('')
    setValidateStep(false)
    setLoading(false)
    handleCancelAction && handleCancelAction()
  }

  const handleNextStep = () => {
    setToken('')
    setValidateStep(true)
  }

  const handlePreviousStep = () => {
    setValidateStep(false)
  }

  return (
    <Stack
      width="100%"
      maxWidth={`${width}px`}
      height={`${height}px`}
      gap="32px"
      justifyContent="space-between"
    >
      {/* Etapa 1 */}
      {!validateStep && (
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            justifySelfc: 'center',
            justifyItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              marginTop: '32px',
              textAlign: 'center',
            }}
          >
            Siga o passo a passo para cadastrar a autenticação em 2 etapas
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              marginTop: '32px',
              color: theme.palette.text.disabled,
            }}
          >
            1 - Baixe um aplicativo de autenticação de dois fatores (ex: Google
            Authenticator). <br />2 - Escaneie o QRCode abaixo no aplicativo.{' '}
            <br />
            3- Clique em próximo passo e insira o código variável que aparece na
            sua tela a cada 60 segundos.
          </Typography>
          <Box
            sx={{
              marginTop: '32px',
            }}
          >
            {qrcode && <BaseQRCode qrcodeUrl={qrcode} />}
            {!qrcode && <CircularProgress />}
          </Box>
        </Box>
      )}
      {/* Etapa 2 */}
      {validateStep && (
        <Stack
          width="100%"
          height="50%"
          gap="32px"
          justifyContent="space-between"
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            Insira o código informado no aplicativo
          </Typography>
          <Box>
            <BaseInputCode
              length={6}
              value={token}
              setState={(e) => setToken(e)}
              error=""
            />
          </Box>
        </Stack>
      )}
      <Stack
        direction="row"
        gap="16px"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {!loading && (
          <Stack>
            {!validateStep && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  justifyItems: 'center',
                  gap: '16px',
                }}
              >
                <Button
                  fullWidth={false}
                  variant="outlined"
                  onClick={() => handleCancel()}
                >
                  Cancelar
                </Button>
                <Button fullWidth variant="contained" onClick={handleNextStep}>
                  Próximo passo
                </Button>
              </Box>
            )}
            {validateStep && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  justifyItems: 'center',
                  gap: '16px',
                }}
              >
                <Button
                  fullWidth={false}
                  variant="outlined"
                  onClick={() => handleCancel()}
                >
                  Cancelar
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handlePreviousStep}
                >
                  Novo QRCode
                </Button>
                {loading && <CircularProgress />}
                {!loading && (
                  <Button fullWidth variant="contained" onClick={handleConfirm}>
                    Atualizar
                  </Button>
                )}
              </Box>
            )}
          </Stack>
        )}
        {loading && (
          <CircularProgress
            sx={{
              justifySelf: 'center',
              justifyItems: 'center',
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
