// TODO: write documentation for colors and palette in own markdown file and add links from here

export const palette = {
  primaryBranding: '#4865CC',
  primaryLighter: '#4865CC',
  primaryDarker: '#1E3466',
  secondaryYellow: '#FCD24C',
  secondaryGreen: '#5ED1A2',
  neutralSurface: '#F4F8FA',
  neutralGrey: '#CCCCCC',
  neutralDisabled: '#DEDEE1',
  neutralBlue: '#F9FDFE',
  textWhite: '#FFFFFF',
  textBlack: '#0B1116',
  textGrey: '#777C84',
  feedbackError: '#DB2D25',
  feedbackSuccess: '#5ED1A2',
  feedbackWarning: '#FCD24C',
} as const

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  transparent: 'rgba(0, 0, 0, 0)',
  text: palette.textBlack,
  background: palette.neutralSurface,
  error: palette.feedbackError,
  primaryColor: palette.primaryBranding,
  white: palette.neutralSurface,
  disabled: palette.neutralDisabled,
}
