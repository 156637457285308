import {
  TUserOwnInfoResponse,
  UserApiRemoteService,
} from 'src/service/service-user'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'
import { StorageLocalstorage } from 'src/shared/storage/storage-localstorage'

export class UserOwnDataUseCase
  implements IUsecase<void, TUserOwnInfoResponse['user']>
{
  constructor(private userApi: UserApiRemoteService) {}

  public async handle(): Promise<Result<TUserOwnInfoResponse['user']>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.ownInfo()
      if (response.isFailure) {
        return Result.fail(new Error('Erro ao recuperar dados do usuário'))
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar dados do usuário'))
      }

      await StorageLocalstorage.set('user', {
        ...StorageLocalstorage.get('user'),
        ...result.user,
      })

      return Result.ok(result.user)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
