import { useState } from 'react'
import { IBankAccount } from 'src/service/service-account'

export function useCompanyAccount() {
  const [isLoading, setIsLoading] = useState(false)
  const [newAccount, setNewAccount] = useState<Partial<IBankAccount> | null>(
    null,
  )

  return {
    isLoading,
    newAccount,
    setIsLoading,
    setNewAccount,
  }
}
