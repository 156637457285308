import { FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material'

export type BaseRadioProps = {
  setState: (value: string) => void
  value: string
  options: {
    label: string
    value: string
  }[]
  groupStyle: any
}
export const BaseRadio = ({
  setState,
  value,
  options,
  groupStyle,
}: BaseRadioProps) => {
  const theme = useTheme()
  return (
    <RadioGroup
      sx={{
        display: 'grid',
        gridTemplateColumns: 'max-content max-content',
        gap: '32px',
        justifySelf: 'start',
        ...groupStyle,
      }}
      name="personType"
      value={value}
      onChange={(event) => setState(event.target.value)}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  )
}
