import { Box, Stack, Typography } from '@mui/material'
import Background from '../../assets/background/home-header.png'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useNavigate } from 'react-router-dom'

export type DefaultHeaderProps = {
  breadcumbItems?: {
    title: string
    navigateTo?: string
  }[]
  buttons?: React.ReactNode
  leftComponent?: React.ReactNode
  containerStyle?: object
}

export const DefaultHeader = ({
  breadcumbItems = [],
  leftComponent,
  buttons,
  containerStyle = {},
}: DefaultHeaderProps) => {
  const navigate = useNavigate()

  const handleNavigate = (navigateTo: string) => {
    if ([null, ''].includes(navigateTo)) {
      return
    }

    return navigate(navigateTo, { replace: true })
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack
        direction="row"
        sx={{
          height: '72px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          paddingRight: '24px',
          ...containerStyle,
        }}
      >
        {leftComponent}

        {breadcumbItems && breadcumbItems?.length > 0 && (
          <Stack
            height="auto"
            padding="8px 24px 8px 32px"
            borderRadius="0px 99px 99px 0px"
            gap="8px"
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#FFFFFF',
              color: '#777C84',
            }}
          >
            {breadcumbItems.map((breadcumb, index) => {
              return (
                <Stack
                  key={`header-breadcrumb-${index}`}
                  onClick={() => handleNavigate(breadcumb.navigateTo ?? '')}
                  sx={{
                    cursor:
                      index + 1 < breadcumbItems?.length
                        ? 'pointer'
                        : 'default',
                  }}
                >
                  <Typography
                    color={
                      index + 1 < breadcumbItems.length ? '777C84' : '#4865CC'
                    }
                    display="flex"
                    fontWeight="400"
                    fontSize="12px"
                    key={`header-breadcumb${index}`}
                    gap="12px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {breadcumb.title}
                    {index + 1 < breadcumbItems.length && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ChevronRightIcon
                          sx={{ width: '12px', height: '12px' }}
                        />
                      </Box>
                    )}
                  </Typography>
                </Stack>
              )
            })}
          </Stack>
        )}

        {buttons}
      </Stack>
    </Stack>
  )
}
