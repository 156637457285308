import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { BaseSelectInput } from 'src/component/base-component/base-select-input'
import { ModalFullContext } from 'src/component/modal/modal-provider'

export const ModalClassifierExport: React.FC<{
  handle: (data?: any) => void
  options: {
    value: string | number
    label: string
    component?: React.ReactNode
  }[]
}> = ({ handle, options }) => {
  const { HideModal } = ModalFullContext()
  const [typeSelected, setTypeSelected] = useState('')
  const [erpSelected, setERPSelected] = useState('')

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '45vw',
          height: '25vh',
          maxHeight: '375px',
          maxWidth: '488px',
          overflow: 'auto',

          borderRadius: '16px',
        }}
      >
        <Typography>Escolha o formato</Typography>

        <BaseInputSingleSelect
          options={[
            { label: 'Excel', value: 'excel' },
            { label: 'ERP', value: 'erp' },
          ]}
          onSelect={setTypeSelected}
        />

        {typeSelected === 'erp' && (
          <>
            <Typography marginBottom="15px">
              Selecione o ERP para exportação
            </Typography>

            <BaseSelectInput
              label="ERP"
              labelBackground="#ffffff"
              options={options}
              fullWidth
              error=""
              value={erpSelected}
              setState={(e) => setERPSelected(e)}
            />
          </>
        )}
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            HideModal()
            handle(typeSelected)
          }}
          sx={{ width: '108px', height: '40px' }}
        >
          Exportar
        </Button>
      </Box>
    </Box>
  )
}
