import {
  AccountApiRemoteService,
  IBankAccountOpenFinanceInitialFlowResponse,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class CreateAccountStepTwopluggyInititalFlowUseCase
  implements IUsecase<void, IBankAccountOpenFinanceInitialFlowResponse>
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle(): Promise<
    Result<IBankAccountOpenFinanceInitialFlowResponse>
  > {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.openFinanceInititalFlow()

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde',
          ),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
