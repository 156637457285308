import { Button, Stack } from '@mui/material'
import { useState } from 'react'
import { BaseRadio } from '../base-component/base-radio'
import { ModalFullContext } from './modal-provider'

export type IOptionType = {
  id: string
  holderId: string
  bankNumber?: string | undefined
  companyCnpj: string
  agencyNumber?: string
  accountNumber?: string
  creditCard:
    | {
        brandName: string
        number: string
      }
    | undefined
}

export const OpenFinanceAccountSelectModal: React.FC<{
  data: IOptionType[]
  handle: (data: IOptionType | null) => void
}> = ({ data = [], handle }) => {
  const { HideModal } = ModalFullContext()
  const [selected, setSelected] = useState<string>('')

  const handleContinue = () => {
    const selectedData = data.find((f) => f.id === selected)
    return handle && handle(selectedData as IOptionType)
  }

  return (
    <Stack width="100%" height="auto" maxHeight="300px" gap="32px">
      <Stack>
        <BaseRadio
          setState={(e) => setSelected(e)}
          value={selected}
          groupStyle={{
            display: 'flex',
            maxWidth: '780px',
            flexDirection: 'column',
            gap: '16px',
          }}
          options={[
            ...data
              .filter(
                (f) => !!f.agencyNumber && f.accountNumber && !f.creditCard,
              )
              .map((account) => {
                return {
                  label: `Ag: ${account.agencyNumber} C: ${account.accountNumber}`,
                  value: account.id,
                }
              }),
            // ...data
            //   .filter((f) => f.creditCard)
            //   .map((account) => {
            //     return {
            //       label: `Cartão de crédito "${account?.creditCard?.brandName}" - Final ${account?.creditCard?.number}`,
            //       value: account.id,
            //     }
            //   }),
          ]}
        />
      </Stack>
      <Stack
        direction="row"
        gap="24px"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => HideModal()}
        >
          Cancelar
        </Button>

        <Button
          disabled={['', null, undefined].includes(selected)}
          fullWidth={false}
          variant="contained"
          onClick={() => handleContinue()}
        >
          Selecionar
        </Button>
      </Stack>
    </Stack>
  )
}
