import LaunchIcon from '@mui/icons-material/Launch'
import { Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ISelectMenuItemProps } from 'src/component/base-component/base-select-with-search'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { UsersIcon } from 'src/component/icons/users'
import {
  AddUserToCompanyModal,
  TModalAddUserType,
} from 'src/component/modal/company/modal-add-user-company'
import { FilterModal, IModalFilter } from 'src/component/modal/modal-filter'
import {
  ModalFullContext,
  ModalPositionEnum,
} from 'src/component/modal/modal-provider'
import { SimpleTextModal } from 'src/component/modal/simple-modal-text'
import { ColumnsCommon } from 'src/component/table/table'
import { UserFullContext } from 'src/context/context-user'
import {
  CompanyUserPermissionTypeEnum,
  IListCompanyFilterDTO,
  TCompanyInfoResponse,
  TCompanyUserCreateDTO,
} from 'src/service/service-company'
import { TListUsersResponse } from 'src/service/service-user'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { ReplyCompanyClassificationRuleUseCase } from 'src/usecase/company/usecase-company-reply-classification-rule'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

export function useCompany(
  listCompaniesUseCase: ListCompanyUseCase,
  listClassificationRulesUseCase: ListCompanyClassificationRulesUseCase,
  addUserToCompanyUseCase: CreateCompanyUsersUseCase,
  listUsersUseCase: UserListUseCase,
  leaveCompanyUseCase: LeaveCompanyUseCase,
  replyCompanyClassificationRuleUseCase: ReplyCompanyClassificationRuleUseCase,
) {
  const navigate = useNavigate()
  const { CompanyInfo, setCurrentCompanyId, GetUserData, UserInfo } =
    UserFullContext()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [modalFilterOptions, setModalFilterOptions] = useState<IModalFilter[]>(
    [],
  )
  const [usersData, setUsersData] = useState<TListUsersResponse['users'][]>([])
  const [filter, setFilter] = useState<IListCompanyFilterDTO>(
    {} as IListCompanyFilterDTO,
  )
  const user = GetUserData()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState<
    TCompanyInfoResponse['companies'][]
  >([])
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([])

  const handleListUsers = async () => {
    const usersData = await listUsersUseCase.handle()

    if (usersData.isFailure) {
      return
    }

    const users = usersData.getValue()
    setUsersData(users as TListUsersResponse['users'][])
  }

  const handleGoToDetails = (companyId: string, companyName: string) => {
    setCurrentCompanyId(companyId)
    CompanyInfo({ id: companyId, name: companyName })
    navigate(`/company/${companyId}`)
  }

  const handleListUserCompaniesUsecase = async () => {
    try {
      setIsLoading(true)
      const usecaseResult = await listCompaniesUseCase.handle()
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
        )
      }

      const companiesData =
        usecaseResult.getValue() as TCompanyInfoResponse['companies'][]

      await UserInfo({
        id: user?.id,
        name: user?.name,
        companies: companiesData,
        companyName: user?.companyName,
        personType: user?.personType,
        cpfCnpj: user?.cpfCnpj,
        username: user?.username,
        termsOfUse: user?.termsOfUse,
        email: user?.email,
        backupCode: user?.backupCode,
        twoAuthEnabled: user?.twoAuthEnabled,
      })
      setCompanies(companiesData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleListUserCompanies = async () => {
    try {
      handleListUserCompaniesUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as empresas. Tente novamente mais tarde.',
      )
    }
  }

  const handleHideAndRefetchCompanies = () => {
    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handleAddUsersModal = (companyId: string) => {
    const currentCompany = companies.find((f) => f.id === companyId)
    const ownerId = currentCompany?.owner.id ?? ''
    const modalUsers = usersData
      .filter((f) => f.id !== ownerId)
      .map((user) => {
        const alreadyConfiguredUser = currentCompany?.users?.find(
          (obj) => obj.user.id === user.id,
        )

        return {
          id: user.id,
          name: user.name,
          username: user.username,
          alreadyConfiguredUser: !!alreadyConfiguredUser,
          permission: alreadyConfiguredUser
            ? alreadyConfiguredUser?.permission
            : CompanyUserPermissionTypeEnum.ANALISTA,
        } as TModalAddUserType
      })

    ShowModal({
      content: (
        <AddUserToCompanyModal
          users={modalUsers}
          companyId={companyId}
          handle={handleAddUsersToCompany}
          handleHideAndRefetchCompanies={handleHideAndRefetchCompanies}
        />
      ),
      title: 'Adicionar usuário',
      closeButton: false,
    })
  }

  const columns: GridColDef[] = [
    {
      ...ColumnsCommon,
      flex: 1.5,
      ...{
        field: 'name',
        headerName: 'Nome do cliente',
      },
      renderCell: (params) => `${params.row.name}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'plan',
        headerName: 'Plano contratado',
      },
      renderCell: (params) => `Plano 1`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'status',
        align: 'center',
        headerName: 'Situação',
      },
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="62px"
          height="24px"
          gap="8px"
          padding="8px 16px 8px 16px"
          fontWeight="600"
          fontSize="12px"
          borderRadius="16px"
          marginTop="5px"
          sx={{ backgroundColor: '#5ED1A2', color: '#F4F8FA' }}
        >
          Ativo
        </Stack>
      ),
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'date',
        headerName: 'Vencimento do plano',
      },
      renderCell: (params) => `01/01/2025`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      ...{
        field: 'id',
        headerName: 'Ações',
        align: 'left',
        renderCell: (params) => {
          const currentCompany = companies.find((f) => f.id === params.row.id)
          const ownerId = currentCompany?.owner.id ?? ''
          let hasPermission = true

          if (user.id !== ownerId) {
            const modalUserPermission = currentCompany?.users?.find(
              (obj) => obj.user.id === user.id,
            )?.permission

            if (modalUserPermission !== CompanyUserPermissionTypeEnum.MASTER) {
              hasPermission = false
            }
          }
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              height="100%"
              gap="12px"
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                  cursor: 'pointer',
                  color: '#4865CC',
                }}
                onClick={(e) => {
                  e.preventDefault()
                  handleGoToDetails(params.row.id, params.row.name)
                }}
              >
                <LaunchIcon />
              </Stack>

              {hasPermission && (
                <Stack
                  justifyContent="center"
                  height="100%"
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    handleAddUsersModal(params.row.id)
                  }}
                >
                  <UsersIcon />
                </Stack>
              )}
              {!hasPermission && (
                <Stack
                  justifyContent="center"
                  height="100%"
                  alignItems="center"
                  sx={{ cursor: 'not-allowed' }}
                >
                  <PeopleAltIcon />
                </Stack>
              )}
            </Stack>
          )
        },
      },
    },
  ]

  const createModalFilterOptions = async (
    plansFilter: { id: string; name: string }[],
    preSelectedPlans: string[],
    status: { id: string; name: string }[],
  ) => {
    const selectedPlans: ISelectMenuItemProps[] = []

    if (preSelectedPlans) {
      preSelectedPlans.forEach((planId) => {
        const plan = plansFilter.find((curr) => curr.id === planId)

        if (!plan) return

        selectedPlans.push({
          label: plan.name,
          value: plan.id,
        })
      })
    }

    const filterOptions: IModalFilter[] = [
      {
        id: '1',
        type: 'dropdown',
        options: [
          {
            name: 'Plano contratado',
            placeholder: 'Selecione o plano',
            options: plansFilter
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((plan) => ({
                label: plan.name,
                value: plan.id,
                removed: preSelectedPlans
                  ? preSelectedPlans.includes(plan.id)
                  : false,
              })),
            selectedOptions: selectedPlans,
          },
          {
            name: 'Situação',
            placeholder: 'Selecione a situação',
            options: status.map((value) => ({
              label: value.name,
              value: value.id,
            })),
            selectedOptions: [],
          },
        ],
      },
      {
        id: '2',
        title: 'Vencimento',
        type: 'date',
        dateType: 'day',
        options: [
          {
            startDateTitle: 'Vencimento',
            startDate: '',
            endDate: '',
          },
        ],
      },
    ]

    setModalFilterOptions(filterOptions)
  }

  const handleAddUsersToCompany = async (
    companyId: string,
    users: {
      userId: string
      permission: CompanyUserPermissionTypeEnum
    }[],
  ) => {
    const data = {
      companyId,
      users,
    } as TCompanyUserCreateDTO

    return await addUserToCompanyUseCase.handle(data)
  }

  const handleLeaveCompany = async () => {
    await Promise.all(
      selectedGridIds.map(async (item) => {
        return await leaveCompanyUseCase.handle({ companyId: item })
      }),
    )

    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handleOpenLeaveCompanyModal = () => {
    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Excluir"
          firstButtonAction={() => HideModal()}
          secondButtonAction={handleLeaveCompany}
          text="Deseja realmente sair da empresa?"
          height={100}
          width={295}
        />
      ),
      title: 'Sair da empresa.',
      closeButton: false,
    })
  }

  const handleCreateCompany = () => {
    navigate(`/company/new`)
  }

  const handleOpenFilterModal = () => {
    ShowModal({
      content: (
        <FilterModal
          initialStateFilters={modalFilterOptions}
          handle={() => HideModal()}
        />
      ),
      closeButton: true,
      title: 'Filtrar por',
      position: ModalPositionEnum.right,
    })
  }

  return {
    modalFilterOptions,
    usersData,
    filter,
    searchQuery,
    columns,
    companies,
    isLoading,
    selectedGridIds,
    setSearchQuery,
    setSelectedGridIds,
    setFilter,
    setModalFilterOptions,
    setUsersData,
    createModalFilterOptions,
    setIsLoading,
    handleAddUsersToCompany,
    setCompanies,
    handleListUserCompaniesUsecase,
    handleListUserCompanies,
    handleListUsers,
    handleOpenLeaveCompanyModal,
    handleCreateCompany,
    handleOpenFilterModal,
  }
}
