import { AccountApiRemoteService } from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object } from 'yup'

export interface TAccountGetCSVColumnsFileResponse {
  columns: string[]
}

export class AccountGetCSVColumnsFileUseCase
  implements
    IFormUsecase<
      { file: File; accountId: string; companyId: string },
      TAccountGetCSVColumnsFileResponse
    >
{
  constructor(private repository: AccountApiRemoteService) {}

  public async handle({
    file,
    accountId,
    companyId,
  }: {
    file: File
    accountId: string
    companyId: string
  }): Promise<Result<TAccountGetCSVColumnsFileResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.uploadCSVAndGetColumns(
        file,
        companyId,
        accountId,
      )

      if (response.isFailure) {
        return Result.fail(
          new Error(
            'Houve um problema na importação do arquivo. Tente novamente.',
          ),
        )
      }

      const result = response.getValue()

      return Result.ok(result as TAccountGetCSVColumnsFileResponse)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object()
}
