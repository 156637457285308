import {
  AuthenticationApiRemoteService,
  TAuthRecovery2FAResponse,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class Recovery2FAInitUseCase
  implements IUsecase<void, TAuthRecovery2FAResponse>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(): Promise<Result<TAuthRecovery2FAResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.recovery2FAInit()

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(
          new Error('Erro ao tentar configurar a autenticação de 2 fatores'),
        )
      }
      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
