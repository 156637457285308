import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import VerifiedIcon from '@mui/icons-material/Verified'

export const useSideBarData = () => {
  const { companyId } = useParams<{ companyId: string }>()
  const navigate = useNavigate()

  if (!companyId) navigate('/home')

  const SideBarData: {
    title: string
    path: string
    icon: React.ReactNode
    isMainPage?: boolean
  }[] = [
    {
      title: 'Início',
      path: `/company/${companyId}`,
      isMainPage: true,
      icon: (
        <HomeIcon
          sx={{
            color: '#ffffff',
            width: '24px !important',
            height: '24px !important',
          }}
        />
      ),
    },
    {
      title: 'Classificador',
      path: `/company/${companyId}/classifier`,
      isMainPage: true,
      icon: (
        <EditNoteOutlinedIcon
          sx={{
            color: '#ffffff',
            width: '24px !important',
            height: '24px !important',
          }}
        />
      ),
    },
    {
      title: 'Regras de classificação',
      path: `/company/${companyId}/classification-rules`,
      isMainPage: true,
      icon: (
        <EditNoteOutlinedIcon
          sx={{
            color: '#ffffff',
            width: '24px !important',
            height: '24px !important',
          }}
        />
      ),
    },
    {
      title: 'Contas sistêmicas',
      path: `/company/${companyId}/account/list`,
      isMainPage: true,
      icon: (
        <SavingsOutlinedIcon
          sx={{
            color: '#ffffff',
            width: '24px !important',
            height: '24px !important',
          }}
        />
      ),
    },
    {
      title: 'Conciliador',
      path: `/company/${companyId}/conciliator`,
      isMainPage: true,
      icon: (
        <VerifiedIcon
          sx={{
            color: '#ffffff',
            width: '24px !important',
            height: '24px !important',
          }}
        />
      ),
    },
  ]

  return SideBarData
}
