import {
  AuthenticationApiRemoteService,
  TAuthRecoveryEmailDTO,
  TAuthRecoveryWith2FADTO,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, ref, string } from 'yup'

export class RecoveryWith2FAUseCase
  implements IFormUsecase<TAuthRecoveryWith2FADTO, void>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(data: TAuthRecoveryWith2FADTO): Promise<Result<void>> {
    try {
      const response = await this.repository.recoveryWith2FA(data)

      if (response.isFailure) {
        return response
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('Username é obrigatório'),
    code: string()
      .required('Código 2FA é obrigatório')
      .max(6, 'Código 2FA deve ter 6 dígitos')
      .min(6, 'Código 2FA deve ter 6 dígitos'),
    password: string()
      .required('Senha é obrigatória')
      .min(6)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])/,
        'Senha deve conter ao menos 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
      ),
    confirmPassword: string()
      .required('Confirmação de senha é obrigatória')
      .oneOf([ref('password')], 'Senhas não conferem'),
  })
}
