import {
  AuthenticationApiRemoteService,
  TAuthRecoveryEmailDTO,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, ref, string } from 'yup'

export class RecoveryEmailUseCase
  implements IFormUsecase<TAuthRecoveryEmailDTO, void>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(data: TAuthRecoveryEmailDTO): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.recoveryEmail(data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao realizar a atualização do email'))
      }
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    email: string().required('Email é obrigatório').email('Email inválido'),
    confirmEmail: string()
      .required('Confirmação do email é obrigatório')
      .oneOf([ref('email')], 'Email não confere'),
  })
}
