import {
  CompanyApiRemoteService,
  TCompanyClassificationRulesCreateDTO,
  TReplyCompanyClassificationRuleResponse,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { array, object, string } from 'yup'

export class ReplyCompanyClassificationRuleUseCase
  implements
    IFormUsecase<
      TCompanyClassificationRulesCreateDTO,
      TReplyCompanyClassificationRuleResponse[]
    >
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle(
    data: TCompanyClassificationRulesCreateDTO,
  ): Promise<Result<TReplyCompanyClassificationRuleResponse[]>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.replyClassificationRules(data)

      if (response.isFailure) {
        return response
      }

      return Result.ok(
        response.getValue() as TReplyCompanyClassificationRuleResponse[],
      )
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companies: array(
      object({
        id: string().required(),
      }),
    ),
    classificationRules: array(
      object({
        id: string().required(),
      }),
    ),
  })
}
