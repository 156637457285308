import React, { createContext, useContext, useMemo, useState } from 'react'

export type CompanyMassImportContextType = {
  companies: { id: number; name: string; cnpj: string }[]
  setCompanies: (
    companies: { id: number; name: string; cnpj: string }[],
  ) => void
}

const CompanyMassImportContext = createContext(
  {} as CompanyMassImportContextType,
)

export const CompanyMassImportProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [companies, setCompanies] = useState<
    { id: number; name: string; cnpj: string }[]
  >([]) // Inicialize o estado do array de dados conforme necessário

  const dataProviderValue = useMemo(
    () => ({ companies, setCompanies }),
    [companies, setCompanies],
  )

  return (
    <CompanyMassImportContext.Provider value={dataProviderValue}>
      {children}
    </CompanyMassImportContext.Provider>
  )
}

export function useCompaniesMassImportContext() {
  const context = useContext(CompanyMassImportContext)
  if (!context) {
    throw new Error(
      'useCompaniesMassImportContext must be used within a DataProvider',
    )
  }
  return context
}
