import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useState } from 'react'

export type PlanCardProps = {
  planOptions: {
    active: boolean
    option: string
  }[]
  showFooterButton?: boolean
  butonAction?: () => void
}

export const PlanTooltip = ({
  planOptions = [],
  butonAction,
  showFooterButton,
}: PlanCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'plan-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-describedby={id}
        aria-label="Clique para saber mais sobre."
      >
        <InfoOutlinedIcon sx={{ color: '#4865CC' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ borderRadius: '16px !important' }}
      >
        <Stack width="220px" gap="8px" padding="24px" borderRadius="16px">
          {planOptions.map((option, index) => {
            return (
              <Typography
                display="flex"
                width="100%"
                key={`plan-tooltip-${index}-${option}`}
                fontWeight="400"
                fontSize="14px"
                gap="12px"
                justifyContent="flex-start"
                alignItems="flex-start"
                color="#1E1E1E"
                sx={{
                  '.MuiSvgIcon-root': {
                    fill: !option.active ? '#D52020' : '#057D88',
                    color: !option.active ? '#D52020' : '#057D88',
                  },
                }}
              >
                {option.active && <CheckCircleIcon />}
                {!option.active && <HighlightOffIcon />}
                {option.option}
              </Typography>
            )
          })}
          {showFooterButton && (
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={() => {
                butonAction && butonAction()
                handleClose()
              }}
            >
              Alterar plano
            </Button>
          )}
        </Stack>
      </Popover>
    </>
  )
}
