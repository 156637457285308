import { Stack, Typography } from '@mui/material'

export type BasePageTitleProps = {
  color?: string
  text: string
  subTitle?: string
  icon?: React.ReactNode
  fontSize?: number
}
export const BasePageTitle = ({
  text,
  color = '#1E3466',
  icon,
  subTitle,
  fontSize = 32,
}: BasePageTitleProps) => {
  return (
    <Stack gap="8px">
      <Stack gap="8px" direction="row" alignItems="center" color={color}>
        {icon}
        <Typography fontSize={`${fontSize}px`} fontWeight="700" color={color}>
          {text}
        </Typography>
      </Stack>
      {subTitle && (
        <Typography
          fontSize="16px"
          fontWeight="700"
          lineHeight="22px"
          color={color}
        >
          {subTitle}
        </Typography>
      )}
    </Stack>
  )
}
