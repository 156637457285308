import { CircularProgress, Stack } from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import {
  IOptionType,
  OpenFinanceAccountSelectModal,
} from 'src/component/modal/modal-open-finance-account-select'
import { OpenFinanceConsentModal } from 'src/component/modal/modal-pluggy-consent'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { VerticalStepper } from 'src/component/vertical-stepper/vertical-stepper'
import {
  BankAccountPersonTypeEnum,
  BankAccountTypeEnum,
  IBankAccountOpenFinanceInitialFlowResponse,
  TAccountCreateDTO,
} from 'src/service/service-account'
import { CreateAccountStepFourUseCase } from 'src/usecase/bank-account/usecase-account-create-step-four-form'
import { CreateAccountStepThreeUseCase } from 'src/usecase/bank-account/usecase-account-create-step-tree-form'
import { CreateAccountStepTwopluggyFinalFlowUseCase } from 'src/usecase/bank-account/usecase-account-create-step-two-pluggy-final-flow'
import { CreateAccountStepTwopluggyInititalFlowUseCase } from 'src/usecase/bank-account/usecase-account-create-step-two-pluggy-initial-flow'
import { ListBanksUseCase } from 'src/usecase/bank/usecase-bank-list'
import { CreateSystemicAccountStepFourPage } from './page-systemic-account-create-step-four'
import { CreateSystemicAccountStepOnePage } from './page-systemic-account-create-step-one'
import { CreateSystemicAccountStepThreePage } from './page-systemic-account-create-step-three'
import { CreateSystemicAccountStepTwoPage } from './page-systemic-account-create-step-two'
import { useCompanyAccount } from './useCompanyAccount'
import BlueHeader from '../../assets/background/header-blue.png'
import { PluggyConnect } from 'react-pluggy-connect'
import { Item } from 'pluggy-js'
import { AccountImportOfxFileUseCase } from 'src/usecase/bank-account/usecase-account-upload-file-ofx'
import { AccountGetCSVColumnsFileUseCase } from 'src/usecase/bank-account/usecase-account-get-csv-columns'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { UpdateAccountUseCase } from 'src/usecase/bank-account/usecase-account-update'

interface CreateAccountPageProps {
  stepTreeUseCase: CreateAccountStepThreeUseCase
  stepFourUseCase: CreateAccountStepFourUseCase
  openFinanceInitialFlowUseCase: CreateAccountStepTwopluggyInititalFlowUseCase
  openFinanceFinalFlowUseCase: CreateAccountStepTwopluggyFinalFlowUseCase
  listBanksUseCase: ListBanksUseCase
  uploadOFXFile: AccountImportOfxFileUseCase
  getCsvFileColumnsUseCase: AccountGetCSVColumnsFileUseCase
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
  updateUseCase: UpdateAccountUseCase
}

export function CreateSystemicAccountPage(props: CreateAccountPageProps) {
  const { companyId } = useParams()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedAccountData, setSelectedAccountData] = useState<{
    defaultAgencyNumber?: string
    defaultPersonType?: string | BankAccountPersonTypeEnum
    defaultAccountType?: string | BankAccountTypeEnum
    defaultBankNumber?: string
    defaultAccountNumber?: string
    defaultAccountName?: string
    defaultAmount?: string
    defaultDate?: string
    defaultBankImage?: string
    defaultBankName?: string
  } | null>(null)
  const [createdAccountId, setCreatedAccountId] = useState<string>('')
  const [openFinanceInitialFlowData, setOpenFinanceInitialFlowData] =
    useState<null | {
      externalUserId: string
      consentedAccountId: string
      accessToken: string
    }>(null)
  const [externalUserIdGenerated, setExternalUserIdGenerated] = useState<
    undefined | string
  >(undefined)
  const [openFinanceAccountSelectedData, setOpenFinanceAccountSelectedData] =
    useState<
      | undefined
      | {
          holderId?: string
          accountId?: string
        }
    >(undefined)
  const { newAccount, setNewAccount, setIsLoading, isLoading } =
    useCompanyAccount()

  const handleStepOneSuccess = (accountType: BankAccountTypeEnum) => {
    setNewAccount((prevUser) => ({
      ...prevUser,
      accountType,
    }))
    setCurrentStep(1)
  }

  const handleFinalFlow = async (
    data: IOptionType | null,
    accountType: string,
    bankLogo: string,
  ) => {
    if (!data) {
      HideModal()
      return
    }
    setExternalUserIdGenerated(openFinanceInitialFlowData?.externalUserId)

    setOpenFinanceAccountSelectedData({
      accountId: data.id,
      holderId: data.holderId,
    })

    setSelectedAccountData({
      defaultAccountNumber: data.accountNumber,
      defaultAgencyNumber: data.agencyNumber,
      defaultBankNumber: data.bankNumber,
      defaultPersonType: accountType
        ? ['BUSINESS'].includes(accountType)
          ? BankAccountPersonTypeEnum.FISICA
          : BankAccountPersonTypeEnum.JURIDICA
        : BankAccountPersonTypeEnum.FISICA,
      defaultBankImage: bankLogo ?? '',
    })

    HideModal()
    setCurrentStep(2)
  }

  const handleOpenFinanceFinalFlowUsecase = async ({
    externalUserId,
    consentedAccountId,
    item,
  }: {
    externalUserId: string
    consentedAccountId: string
    item: Item
  }) => {
    try {
      setIsLoading(true)

      const data = {
        companyId: companyId as string,
        externalUserId,
        consentedAccountId,
        pluggyCallback: {
          event: 'item/created',
          itemId: item.id,
        },
      }

      const result = await props.openFinanceFinalFlowUseCase.handle(data)
      setIsLoading(false)

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
        )
        HideModal()
      }

      const finalFlowResponse = result.getValue()

      if (
        finalFlowResponse?.accounts &&
        finalFlowResponse?.accounts.data.length > 0
      ) {
        setNewAccount((prevUser) => ({
          ...prevUser,
          consentedAccountId,
        }))

        ShowModal({
          content: (
            <OpenFinanceAccountSelectModal
              data={finalFlowResponse?.accounts.data.map((account) => {
                return {
                  id: account.accountId,
                  holderId: finalFlowResponse.holder.id,
                  bankNumber: account.compeCode,
                  companyCnpj: account.companyCnpj,
                  agencyNumber: account.branchCode,
                  accountNumber: account.number,
                  creditCard:
                    (account.type as unknown as string) === 'CARTAO_CREDITO'
                      ? {
                          brandName: account.brandName,
                          number: account.number,
                        }
                      : undefined,
                }
              })}
              handle={(e) =>
                handleFinalFlow(
                  e,
                  item.connector.type,
                  item.connector.institutionUrl,
                )
              }
            />
          ),
          title: 'Selecione a conta de gostaria de criar',
          closeButton: false,
          style: { maxWidth: '300px' },
        })
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleOpenFinanceInitialFlowUsecase = async () => {
    try {
      setIsLoading(true)
      const accountsResult = await props.openFinanceInitialFlowUseCase.handle()
      setIsLoading(false)

      if (accountsResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
        )
        HideModal()
      }

      const initialFlowResponse =
        accountsResult.getValue() as IBankAccountOpenFinanceInitialFlowResponse

      HideModal()

      if (
        initialFlowResponse.consentedAccountId &&
        initialFlowResponse.externalUserId &&
        initialFlowResponse.accessToken
      ) {
        return {
          externalUserId: initialFlowResponse.externalUserId,
          consentedAccountId: initialFlowResponse.consentedAccountId,
          accessToken: initialFlowResponse.accessToken,
        }
      } else {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
        )
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleInitialFlow = async (data: { item: Item }) => {
    try {
      if (!openFinanceInitialFlowData) {
        return
      }

      setExternalUserIdGenerated(openFinanceInitialFlowData.externalUserId)

      handleOpenFinanceFinalFlowUsecase({
        externalUserId: openFinanceInitialFlowData.externalUserId,
        consentedAccountId: openFinanceInitialFlowData.consentedAccountId,
        item: data.item,
      })
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleErrorFlow = (error: {
    message: string
    data?: {
      item: Item
    }
  }) => {
    console.log(error)

    ShowToast(
      'error',
      'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
    )
  }

  const handleOpenPluggyConsentModal = async () => {
    const handleOpenFinanceInitialFlow =
      await handleOpenFinanceInitialFlowUsecase()

    if (!handleOpenFinanceInitialFlow) {
      setOpenFinanceInitialFlowData(null)
      return
    }

    setOpenFinanceInitialFlowData(handleOpenFinanceInitialFlow)
  }

  const handleStepTwoSuccess = (openFinance: boolean) => {
    if (openFinance) {
      handleOpenPluggyConsentModal()
    } else {
      setNewAccount((prevUser) => ({
        ...prevUser,
        openFinance,
      }))
      setCurrentStep(2)
    }
  }

  const handleStepThreeSuccess = async (data: Partial<TAccountCreateDTO>) => {
    setNewAccount((prevUser) => ({
      ...prevUser,
      ...data,
    }))

    const accountData = {
      ...newAccount,
      ...data,
      externalUserId: externalUserIdGenerated ?? undefined,
      holderId: openFinanceAccountSelectedData?.holderId,
      accountId: openFinanceAccountSelectedData?.accountId,
      initialBalance: Number(data.amount?.replace(/[^\d]/g, '')) / 100,
      companyId,
      description: 'default description',
      bankLogo: data.bankLogo,
    } as TAccountCreateDTO

    try {
      setIsLoading(true)
      const accountResult = await props.stepTreeUseCase.handle(accountData)
      setIsLoading(false)

      if (accountResult.isFailure) {
        let message =
          'Ocorreu um erro ao criar a conta bancária. Tente novamente mais tarde.'
        if (accountResult?.error?.type === 'BankAccountAlreadyExistsError') {
          message = 'Já existe uma conta bancária cadastrada com estes dados.'
        }
        return ShowToast('error', message)
      }

      const createdAccount = accountResult.getValue()

      setSelectedAccountData({
        defaultAccountNumber: accountData.accountNumber,
        defaultAgencyNumber: accountData.agencyNumber,
        defaultBankNumber: accountData.bankNumber,
        defaultPersonType: accountData.personType,
        defaultAccountName: accountData.name,
        defaultAccountType: accountData.accountType,
        defaultAmount: accountData.amount,
        defaultDate: accountData.transactionCommencementDate.toString(),
        defaultBankImage: accountData.bankLogo,
        defaultBankName: accountData.bank.split('-')[1].trim(),
      })
      setCreatedAccountId(createdAccount?.id as string)

      setCurrentStep(3)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas bancárias. Tente novamente mais tarde.',
      )
    }
  }

  return (
    <Stack
      width="100%"
      height="100%"
      sx={{ backgroundColor: '#F4F8FA', overflow: 'hidden' }}
    >
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${companyId}` },
          {
            title: 'Contas sistêmicas',
            navigateTo: `/company/${companyId}/account/list`,
          },
          { title: 'Criar nova conta' },
        ]}
      />

      {openFinanceInitialFlowData && (
        <PluggyConnect
          connectToken={openFinanceInitialFlowData.accessToken}
          onSuccess={handleInitialFlow}
          onError={handleErrorFlow}
          onClose={() => setOpenFinanceInitialFlowData(null)}
        />
      )}

      <Stack
        width="100%"
        height="calc(100% - 120px)"
        direction="row"
        gap="32px"
        sx={{ overflow: 'hidden' }}
      >
        {isLoading && (
          <Stack
            width="80%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        )}
        {!isLoading && (
          <Stack
            width="80%"
            height="100%"
            paddingLeft="32px"
            sx={{ overflow: 'hidden' }}
          >
            <Stack width="100%" justifyContent="space-between" direction="row">
              <BasePageTitle color="#1E3466" text="Criar nova conta" />
            </Stack>
            <Stack
              width="100%"
              height="100%"
              gap="32px"
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{ overflow: 'hidden' }}
            >
              {currentStep === 0 && (
                <Stack
                  width="100%"
                  height="calc(100% - 48px)"
                  sx={{ overflow: 'auto' }}
                >
                  <CreateSystemicAccountStepOnePage
                    handleSelect={handleStepOneSuccess}
                  />
                </Stack>
              )}
              {currentStep === 1 && (
                <Stack width="100%" height="100%" sx={{ overflow: 'auto' }}>
                  <CreateSystemicAccountStepTwoPage
                    handleContinue={handleStepTwoSuccess}
                  />
                </Stack>
              )}
              {currentStep === 2 && (
                <Stack
                  width="calc(100% - 8px)"
                  height="100%"
                  paddingRight="8px"
                  sx={{ overflow: 'auto' }}
                >
                  <CreateSystemicAccountStepThreePage
                    useCase={props.stepTreeUseCase}
                    handleContinue={handleStepThreeSuccess}
                    defaultAccountNumber={
                      selectedAccountData?.defaultAccountNumber
                    }
                    defaultAgencyNumber={
                      selectedAccountData?.defaultAgencyNumber
                    }
                    defaultBankNumber={selectedAccountData?.defaultBankNumber}
                    defaultBankLogo={selectedAccountData?.defaultBankImage}
                    defaultPersonType={selectedAccountData?.defaultPersonType}
                    listBanksUseCase={props.listBanksUseCase}
                  />
                </Stack>
              )}
              {currentStep === 3 && (
                <Stack width="100%" height="100%" sx={{ overflow: 'auto' }}>
                  <CreateSystemicAccountStepFourPage
                    useCase={props.stepFourUseCase}
                    getCsvFileColumnsUseCase={props.getCsvFileColumnsUseCase}
                    uploadOFXFile={props.uploadOFXFile}
                    listLedgerAccountsUseCase={props.listLedgerAccountsUseCase}
                    updateUseCase={props.updateUseCase}
                    accountId={createdAccountId}
                    amount={selectedAccountData?.defaultAmount}
                    date={selectedAccountData?.defaultDate}
                    accountNumber={selectedAccountData?.defaultAccountNumber}
                    name={selectedAccountData?.defaultAccountName}
                    agencyNumber={selectedAccountData?.defaultAgencyNumber}
                    bankNumber={selectedAccountData?.defaultBankNumber}
                    bankLogo={selectedAccountData?.defaultBankImage}
                    bankName={selectedAccountData?.defaultBankName as string}
                    personType={
                      selectedAccountData?.defaultPersonType as BankAccountPersonTypeEnum
                    }
                    accountType={
                      selectedAccountData?.defaultAccountType as BankAccountTypeEnum
                    }
                    isOpenFinance={!!openFinanceAccountSelectedData}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
        <Stack width="20%" height="100%" alignItems="center">
          <VerticalStepper
            currentStep={currentStep}
            setCurrent={setCurrentStep}
            steps={[
              { title: 'Tipo da conta' },
              { title: 'Open Finance' },
              { title: 'Dados da conta' },
              { title: 'Conclusão' },
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
