import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'
import { IBankAccount } from './service-account'
import { TUserOwnInfoResponse } from './service-user'
import { format, getTime, parse } from 'date-fns'
import { BankAccountTransferMethodEnum } from './service-bank'

export type TCompanyCreateDTO = {
  name: string
  cnpj: string
}

export type TCompanyUpdateDTO = TCompanyCreateDTO & {
  id: string
}

export enum CompanyUserPermissionTypeEnum {
  MASTER = 'MASTER',
  ANALISTA = 'ANALISTA',
}

export class CompanyUserPermissionTypeEnumUtil {
  public static VALUES = [
    CompanyUserPermissionTypeEnum.MASTER,
    CompanyUserPermissionTypeEnum.ANALISTA,
  ]

  public static NAMES = ['Master', 'Analista']

  public static toString(value: CompanyUserPermissionTypeEnum): string {
    return this.NAMES[this.VALUES.findIndex((v) => v === value)]
  }
}

export type TCompanyUserCreateDTO = {
  companyId: string
  users: {
    userId: string
    permission: CompanyUserPermissionTypeEnum
  }[]
}

export type TCompanyClassificationRulesCreateDTO = {
  companyId: string // Empresa de origem
  companies: {
    id: string
  }[]
  classificationRules: {
    id: string
  }[]
}

export type TCompanyCreateMassDTO = {
  companies: TCompanyCreateDTO[]
}

export type ICompanyUser = {
  id: string
  user: {
    id: string
    name: string
    cpfCnpj: string
    username: string
  }
  permission: CompanyUserPermissionTypeEnum
}

export enum CompanyClassificationRuleConditionalsConditionEnum {
  CONTAINS = 'Contains',
  BETWEEN = 'Between',
  GREATER_THAN = 'GreaterThan',
  LESS_THAN = 'LessThan',
  EQUAL = 'Equal',
}

export class CompanyClassificationRuleConditionalsConditionEnumUtil {
  public static VALUES = [
    CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
    CompanyClassificationRuleConditionalsConditionEnum.BETWEEN,
    CompanyClassificationRuleConditionalsConditionEnum.GREATER_THAN,
    CompanyClassificationRuleConditionalsConditionEnum.LESS_THAN,
    CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
  ]

  public static NAMES = ['Contém', 'Entre', 'Maior que', 'Menor que', 'Igual']

  public static toString(
    value: CompanyClassificationRuleConditionalsConditionEnumUtil,
  ): string {
    return this.NAMES[this.VALUES.findIndex((v) => v === value)]
  }

  public static parse(
    value: string,
  ): CompanyClassificationRuleConditionalsConditionEnumUtil {
    return this.VALUES[this.NAMES.findIndex((v) => v === value)]
  }

  public static getOptions(): Array<{ id: string; title: string }> {
    return this.VALUES.map((value, index) => ({
      id: value.toString(),
      title: this.NAMES[index],
    }))
  }
}

export enum CompanyClassificationRuleConditionalsVariableEnum {
  VALUE = 'Value',
  DATE = 'Date',
  THIRD_PARTY = 'ThirdParty',
  DESCRIPTION = 'Description',
}

export type ICompanyAccountingChartItem = {
  id: string
  code: string
  createdAt: Date
  updatedAt: Date
}

export type ICompanyClassificationRuleConditionals = {
  condition: CompanyClassificationRuleConditionalsConditionEnum
  variable: CompanyClassificationRuleConditionalsVariableEnum
  data: {
    value?: {
      value: string
    }
    date?: {
      init: string
      end: string
    }
    thirdParty?: {
      name?: string
      documentNumber?: string
    }
    description?: {
      description: string
    }
  }
}

export type ICompanyClassificationRule = {
  id: string
  accountingChartItem: ICompanyAccountingChartItem
  name: string
  conditionals: ICompanyClassificationRuleConditionals[]
  credit: string | undefined
  debit: string | undefined
  historic: string | undefined
  financialCategory: string | undefined
  generalRule: boolean
  createdAt?: Date
  updatedAt?: Date
}

export type ICompany = {
  id: string
  name: string
  cnpj: string
  owner: TUserOwnInfoResponse['user']
  bankAccounts: IBankAccount[]
  users: ICompanyUser[]
  createdAt: Date
  updatedAt: Date
}

export type TReplyCompanyClassificationRuleResponse = {
  rule: ICompanyClassificationRule
  status: 'CREATED' | 'UPDATED'
  company: ICompany
}

export type TCompanyInfoResponse = {
  companies: ICompany
  classificationRules: ICompanyClassificationRule
}

export interface IListCompanyFilterDTO {
  planIds?: string[]
  status?: string[]
  validateAtStartPeriod?: string
  validateAtEndPeriod?: string
}

export type TAccountClassificationRuleCreateDTO = {
  accountingChartItem: {
    code: string
  }
  name: string
  conditionals: {
    condition: CompanyClassificationRuleConditionalsConditionEnum
    variable: CompanyClassificationRuleConditionalsVariableEnum
    data: {
      value?: {
        value: string
      }
      date?: {
        init: string | number
        end: string | number
      }
      thirdParty?: {
        name?: string
        documentNumber?: string
      }
      description?: {
        description: string
      }
    }
  }[]
  credit: string | undefined
  debit: string | undefined
  historic: string[] | undefined
  financialCategory: string | undefined
  generalRule: boolean
}

export type TAccountClassificationRuleUpdateDTO = {
  id: string
} & TAccountClassificationRuleCreateDTO

export type IListAccountingEntriesInformations = {
  bankBalance: string
  accountingBalance: string
  bankCashflow: {
    total: string
    inflow: string
    outflow: string
  }
}

export type IListAccountingEntriesResponseDTO = {
  informations: IListAccountingEntriesInformations
  companyAccountingEntries: {
    id: string
    company: Omit<ICompany, 'owner' | 'bankAccounts' | 'users'>
    bankAccount: Omit<IBankAccount, 'balanceHistory' | 'company' | 'balance'>
    date: string
    description: string | null
    vendor: string | null
    value: string
    method:
      | BankAccountTransferMethodEnum.CREDITO
      | BankAccountTransferMethodEnum.DEBITO
    debit: string | null
    credit: string | null
    historic: string | null
    financialCategory: string | null
    classificationRuleApplied: null
    classificationRules: any[]
    accountingChartItems: any[]
    bankTransferExternalId: string
    bankTransferStatus: string
    bankTransferType: null
    bankTransferMethod:
      | BankAccountTransferMethodEnum.CREDITO
      | BankAccountTransferMethodEnum.DEBITO
    bankTransferAmount: string
    bankTransferCurrency: 'BRL'
    bankTransferDate: string
    bankTransferPartie: {
      name: string
      type: string | null
      document: string | null
    }
    bankTransferCreatedAt: string
    bankTransferUpdatedAt: string
    bankAccountName: string
    bankAccountDescription: string
    bankAccountBank: string
    bankAccountBankNumber: string
    bankAccountAccountType: number
    bankAccountPersonType: number
    bankAccountTransactionCommencementDate: string
    bankAccountAgencyNumber: string
    bankAccountAccountNumber: string
    bankAccountOpenFinance: boolean
    bankAccountConsentedAccountId: string | null
    bankAccountCreatedAt: string
    bankAccountUpdatedAt: string
    createdAt: string
    updatedAt: string
  }[]
  count: number
  pages: number
}

export type IAccountingEntriesRequest = {
  companyId: string
  dateStart?: Date
  dateEnd?: Date
  query?: string
  valueMinimum?: string
  valueMaximum?: string
  page: number
}

export type IAccountingEntries = {
  id: string
  date: string
  description: string | null
  supplier: string | null
  value: string | null
  debit: string | null
  credit: string | null
  historic: string | null
  financialCategory: string | null
}

export type CompanyDebitRequest = {
  companyId: string
  query: string
}

export class CompanyApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public create = async (data: TCompanyCreateDTO): Promise<Result<void>> => {
    try {
      await this.service.post(`/v1/company`, data)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public update = async (
    data: TCompanyUpdateDTO,
  ): Promise<Result<TCompanyInfoResponse['companies']>> => {
    try {
      const result = await this.service.put(`/v1/company/${data.id}`, {
        name: data.name,
        cnpj: data.cnpj,
      })
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createClassificationRule = async (
    companyId: string,
    data: TAccountClassificationRuleCreateDTO,
  ): Promise<Result<void>> => {
    try {
      const formattedData = data

      let historic
      if (data.historic && data.historic?.length > 0) {
        historic = data.historic.join('|')
      }
      await this.service.post(`/v1/company/${companyId}/classification-rule`, {
        accountingChartItem: formattedData.accountingChartItem,
        classificationRule: {
          name: formattedData.name,
          generalRule: formattedData.generalRule,
          conditionals: formattedData.conditionals,
          credit:
            typeof formattedData.credit === 'string' &&
            formattedData?.credit?.trim()?.length === 0
              ? undefined
              : formattedData.credit,
          debit:
            typeof formattedData.debit === 'string' &&
            formattedData?.debit?.trim()?.length === 0
              ? undefined
              : formattedData.debit,
          historic,
          financialCategory:
            typeof formattedData.financialCategory === 'string' &&
            formattedData?.financialCategory?.trim()?.length === 0
              ? undefined
              : formattedData.financialCategory,
        },
      })
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public updateClassificationRule = async (
    companyId: string,
    data: TAccountClassificationRuleUpdateDTO,
  ): Promise<Result<ICompanyClassificationRule>> => {
    try {
      const formattedData = data
      let historic = ''
      if (data.historic && data.historic?.length > 0) {
        historic = data.historic.join('|')
      }
      const result = (await this.service.put(
        `/v1/company/${companyId}/classification-rule/${data.id}`,
        {
          accountingChartItem: formattedData.accountingChartItem,
          classificationRule: {
            name: formattedData.name,
            generalRule: formattedData.generalRule,
            conditionals: formattedData.conditionals,
            credit:
              typeof formattedData.credit === 'string' &&
              formattedData?.credit?.trim()?.length === 0
                ? undefined
                : formattedData.credit,
            debit:
              typeof formattedData.debit === 'string' &&
              formattedData?.debit?.trim()?.length === 0
                ? undefined
                : formattedData.debit,
            historic,
            financialCategory:
              typeof formattedData.financialCategory === 'string' &&
              formattedData?.financialCategory?.trim()?.length === 0
                ? undefined
                : formattedData.financialCategory,
          },
        },
      )) as ICompanyClassificationRule
      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public deleteClassificationRule = async ({
    companyId,
    classificationRuleId,
  }: {
    companyId: string
    classificationRuleId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(
        `/v1/company/${companyId}/classification-rule/${classificationRuleId}`,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createUser = async (
    data: TCompanyUserCreateDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(
        `/v1/company/${data.companyId}/permissions/allowed`,
        data,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public companyLeave = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(`/v1/company/${companyId}/permissions/leave`)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public createMass = async (
    data: TCompanyCreateMassDTO,
  ): Promise<Result<ICompany[]>> => {
    try {
      const result = await this.service.post(`/v1/company/mass-import`, data)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public uploadFile = async (
    file: File,
  ): Promise<Result<TCompanyCreateDTO[]>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const response = await this.service.postForm(
        '/v1/company/validate-import-file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return Result.ok(response.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getUserCompanies = async (): Promise<
    Result<TCompanyInfoResponse['companies'][]>
  > => {
    try {
      const result = await this.service.get(`/v1/company`)
      return Result.ok(result.data.companies)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyInfo = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<ICompany>> => {
    try {
      const result = await this.service.get(`/v1/company/${companyId}`)
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyClassificationRules = async (
    companyId: string,
    query?: string,
  ): Promise<Result<TCompanyInfoResponse['classificationRules'][]>> => {
    try {
      const queryString = query ? `?query=${encodeURIComponent(query)}` : ''
      const result = await this.service.get(
        `/v1/company/${companyId}/classification-rule${queryString}`,
      )
      return Result.ok(result.data.classificationRules)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public replyClassificationRules = async (
    data: TCompanyClassificationRulesCreateDTO,
  ): Promise<Result<TReplyCompanyClassificationRuleResponse[]>> => {
    try {
      const result = await this.service.post(
        `/v1/company/${data.companyId}/classification-rule/reply`,
        {
          companies: data.companies,
          classificationRules: data.classificationRules,
        },
      )
      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getAccountingEntries = async (
    companyId: string,
    page: number,
    dateStart?: Date,
    dateEnd?: Date,
    query?: string,
    valueMinimum?: string,
    valueMaximum?: string,
  ): Promise<Result<IListAccountingEntriesResponseDTO>> => {
    try {
      // const queryString = query ? `?query=${encodeURIComponent(query)}` : '';
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries`,
        {
          params: {
            page,
            dateEnd,
            dateStart,
            query,
            valueMaximum,
            valueMinimum,
          },
        },
      )

      return Result.ok(result.data)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public updateAccountingEntries = async ({
    companyId,
    entryId,
    field,
    value,
  }: {
    companyId: string
    entryId: string
    field: string
    value: string
  }): Promise<Result<void>> => {
    try {
      // const queryString = query ? `?query=${encodeURIComponent(query)}` : '';
      const result = await this.service.put(
        `/v1/company/${companyId}/accounting-entries/${entryId}`,
        { field, value },
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyVendors = async (
    companyId: string,
  ): Promise<Result<string[]>> => {
    try {
      // const queryString = query ? `?query=${encodeURIComponent(query)}` : '';
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries/vendors`,
      )
      return Result.ok(result.data.vendors)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public getCompanyDebit = async (
    companyId: string,
    query: string,
  ): Promise<Result<string[]>> => {
    try {
      // const queryString = query ? `?query=${encodeURIComponent(query)}` : '';
      const result = await this.service.get(
        `/v1/company/${companyId}/accounting-entries/debits`,
        {
          params: {
            query,
          },
        },
      )
      return Result.ok(result.data.debits)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
