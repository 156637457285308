import { Box, Button, Typography, useTheme } from '@mui/material'
import { ModalFullContext } from 'src/component/modal/modal-provider'

export const TermsOfUseModal: React.FC<{
  handle: () => void
}> = ({ handle }) => {
  const { HideModal } = ModalFullContext()

  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '50vw',
          height: '50vh',
          maxHeight: '507px',
          maxWidth: '613px',
          overflow: 'auto',
          padding: '16px 24px',
          borderRadius: '16px',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '19px',
          }}
        >
          Esta Política de Privacidade (“Política”) aplica-se ao uso dos
          serviços oferecidos pela <b>GARGO TECH LTDA.</b>, sociedade limitada,
          inscrita no CNPJ/MF sob nº 51.066.584/0001-39, gestora dos{' '}
          <b>SERVIÇOS da OPENI</b>, disponível para contratação através do
          SITE/PLATAFORMA https://openi.com.br e/ou pelos CANAIS OFICIAIS de
          comunicação da companhia, objetos deste Termo, doravante denominada
          OPENI, por PESSOA FÍSICA OU JURÍDICA, maior e capaz, que tenha seu
          CADASTRO com a OPENI, cujos dados passam a ser parte integrante desta
          Política, bem como que tenha “aceitado“ eletronicamente todas as
          cláusulas do mesmo e todas as demais políticas disponíveis no SITE, na
          PLATAFORMA e nos CANAIS OFICIAIS de comunicação, doravante denominado
          USUÁRIO (“Usuário”).
          <br />
          <br />
          Os serviços disponíveis pela <b>OPENI</b> no SITE https://openi.com.br
          (“Site” e/ou “Plataforma”) e nos CANAIS OFICIAIS de comunicação
          (“Canais”) serão regidos pelas cláusulas e condições abaixo.
          <br />
          <br />
          Ao aceitar eletronicamente a presente Política, através do clique no
          botão que indique a anuência da Política de Privacidade, bem como dos
          Termos de Uso, disponibilizado em etapas de Cadastro na Plataforma
          e/ou de Contratação dos Serviços, o USUÁRIO estará automaticamente
          aderindo e concordando a se submeter integralmente a seus termos e
          condições e a qualquer de suas alterações futuras, além de aceitar as
          disposições das políticas do Site, da Plataforma e dos Canais Oficiais
          de comunicação. Caso discorde da política de privacidade, você não
          deve utilizar a PLATAFORMA e os SERVIÇOS DA <b>OPENI</b> da OPENI.
          <br />
          <br />
          <b>Cláusula Primeira Do Objeto</b>
          <br />
          <br />
          <b>1.1</b> – Objeto. A Política de Privacidade da <b>OPENI</b> tem por
          objetivo estabelecer e compartilhar com todos os CONTRATANTES e
          USUÁRIOS, de forma transparente, as regras sobre a coleta, tratamento,
          armazenamento e eliminação dos dados pessoais coletados, dentro do
          escopo dos serviços prestados nos limites exigidos e autorizados por
          lei.
          <br />
          <br />
          <b>1.1.1</b> Como condição de acesso e de uso, ao se cadastrar no
          website ou utilizar as soluções associadas da OPENI, o CONTRATANTE e
          USUÁRIO declara que fez a leitura completa e obteve plena ciência da
          presente Política de Privacidade, atribuindo assim, de forma livre e
          expressa, sua concordância com os termos estipulados, onde autoriza a
          coleta, tratamento e o armazenamento de seus dados e dos demais dados
          pessoais coletados. Caso o CONTRATANTE e USUÁRIO não reconheça como
          verdadeiros, não aceite ou não concorde com quaisquer das disposições
          desta Política, deve se abster de realizar o seu cadastro e utilizar
          as soluções associadas da OPENI.
          <br />
          <br />
          <b>1.1.2</b> O CONTRATANTE e USUÁRIO garante que informará apenas
          dados corretos e que obteve de forma lícita ou mediante autorização,
          nos casos em que essa foi necessária, sendo exclusivamente responsável
          pela exatidão e veracidade dos dados que forneceu, isentando a OPENI
          em caso de erros ou danos que resultem destas informações errôneas.
          <br />
          <br />
          <b>Cláusula Segunda Escopo</b>
          <br />
          <br />
          <b>2.1</b> – Funcionamento. A <b>OPENI</b> tem o compromisso e visa
          garantir a privacidade e a proteção dos dados pessoais coletados de
          seus usuários, a transparência sobre o uso dos dados e a segurança no
          website e soluções associadas.
          <br />
          <br />
          <b>2.1.1</b> É fundamental que o CONTRATANTE e USUÁRIO também colabore
          para manter seus dados pessoais seguros. Por esse motivo, a{' '}
          <b>OPENI</b> incentiva que os usuários tomem as medidas apropriadas
          para se proteger, como manter confidenciais todos os nomes de usuário
          e senhas.
          <br />
          <br />
          <b>2.1.2</b> As condições e informações apresentadas nesta política
          estão de acordo com a legislação brasileira, atendendo os termos da
          Lei Geral de Proteção de Dados – LGPD (Lei n° 13.709 de 14 de Agosto
          de 2018), bem como Marco Civil da Internet (Lei n° 12.965 de 23 de
          Abril de 2014 e sua Regulamentação (Decreto n° 8771 de 11 de Maio de
          2016); Código de Defesa do Consumidor (Lei n° 8078 de 11 de Setembro
          de 1990 e Código Civil, Lei n° 10.406 de 10 de Janeiro de 2002).
          <br />
          <br />
          <b>Cláusula Terceira Coleta de dados</b>
          <br />
          <br />
          <b>3.1</b> – Sobre a Coleta de dados. Quando o usuário realiza o
          cadastro e/ou fornece dados nos formulários dos websites e soluções da
          OPENI, estes serão mantidos em sigilo e serão utilizados apenas para o
          propósito que motivou a coleta, conforme Tabela de Dados Pessoais
          Coletados – Item 3.2.
          <br />
          <br />
          <b>3.1.1</b> Os dados são coletados quando o usuário os insere
          voluntariamente ao acessar e interagir com os meios de contato e
          soluções da OPENI, para a criação de contas de acesso das soluções
          utilizadas e contratadas e o registro das atividades efetuadas pelo
          usuário por meio de logs, que incluem: · SERVIÇOS DA OPENI: Os
          usuários inserem seus dados para realização de cadastro; · SAC e/ou
          FAQ e/ou CANAIS OFICIAIS de comunicação: Comunicações com a Central de
          Atendimento ao Cliente da OPENI, onde é possível realizar o cadastro
          de novo cliente, registrar dúvidas e/ou reclamações; · Formulários de
          Cadastro: Registros pelos quais são realizadas a coleta de dados
          pessoais para o cadastro de um novo contato, cliente e colaborador; ·
          E-mail, mensagens de texto e outras mensagens eletrônicas: Interações
          via comunicação eletrônica entre o Titular dos dados e a OPENI.
          <br />
          <br />
          <b>3.1.2</b> As informações coletadas e armazenadas em ambiente seguro
          e controlado são: · Nome Completo; · RG; · CPF; · Telefone; · Celular;
          · Cargo; · Endereço de e-mail; · Endereço de IP; · Porta lógica de
          origem do usuário; · Geolocalização.
          <br />
          <br />
          <b>3.1.3</b> As finalidades de uso dos dados são: • Identificar o
          usuário, prestar os serviços e cumprir as obrigações decorrentes do
          uso das soluções OPENI; • Informar sobre conteúdos, promoções,
          notícias e demais eventos relevantes para a manutenção do
          relacionamento com o usuário; • Responder as solicitações de
          informação do usuário; • Atendimento de suporte ao usuário; •
          Processamento de solicitações do usuário; • Interações com usuários em
          sites, blogs, fóruns de discussão e redes sociais; • Processos
          seletivos e de contratação de colaboradores.
          <br />
          <br />
          <b>3.1.4</b> Dados Pessoais Sensíveis: A <b>OPENI</b> não tem a
          intenção de coletar ou processar dados pessoais sensíveis no curso
          normal de suas atividades. Caso o seja necessário, por qualquer
          motivo, a <b>OPENI</b> se apoiará no prévio e expresso consentimento
          do titular dos dados para qualquer atividade de coleta ou
          processamento. Os dados pessoais sensíveis poderão ser processados
          para outras finalidades, como por exemplo o de cumprimento da lei
          aplicável, ou para cumprir com os relatórios de diversidade internos.
          <br />
          <br />
          <b>3.1.5</b> Os dados também serão usados para a gestão,
          administração, prestação e melhoria dos serviços prestados, buscando o
          desenvolvimento da plataforma e proteção dos dados coletados.
          <br />
          <br />
          <b>3.1.6</b> Caso exista a possibilidade de as informações pessoais
          dos CONTRATANTES e USUÁRIOS serem cedidas a terceiros, essa
          circunstância será devidamente advertida aos usuários nos formulários
          de recolhimento dos dados pessoais, contudo, você terá o direito de se
          opor a essa cessão.
          <br />
          <br />
          <b>Cláusula Quarta Do Armazenamento de dados</b>
          <br />
          <br />
          <b>4.1</b> – Como se dará o armazenamento. Os dados coletados são
          armazenados em ambiente operacional seguro e controlado da OPENI, pelo
          prazo mínimo estipulado de 6 (seis) meses.
          <br />
          <br />
          <b>4.1.1</b> Caso haja solicitação do cliente, os dados poderão ser
          apagados antes desse prazo. No entanto, pode ocorrer de os dados
          precisarem ser mantidos por um período superior, por motivo de lei,
          ordem judicial, prevenção à fraude (art. 11, II, “a” da Lei Geral de
          Proteção de Dados “LGPD”, Lei nº 13.709/2018), proteção ao crédito
          (art. 7º, X, LGPD) e outros interesses legítimos, em conformidade com
          o artigo 10 da LGPD. Terminado o prazo e a necessidade legal, os dados
          pessoais serão excluídos com o uso de métodos de descarte seguro ou
          utilizados de forma anonimizada para fins estatísticos. Os dados
          coletados são armazenados em servidores localizados no Brasil.
          <br />
          <br />
          <b>4.1.2</b> O usuário pode solicitar a visualização e alteração,
          limitação de uso e a exclusão de seus dados pessoais por meio dos
          Canais de Atendimento da OPENI.
          <br />
          <br />
          <b>Cláusula Quinta Transferência de dados</b>
          <br />
          <br />
          <b>5.1</b> – Transferência de dados. Devido à natureza dos negócios da
          OPENI, para a efetivação dos serviços contratados e propósitos
          administrativos, é possível que ocorram transferências de dados
          pessoais dentro da <b>OPENI</b> e com empresas do grupo e para
          terceiros, nos limites exigidos e autorizados por lei. A <b>OPENI</b>{' '}
          aplica medidas de segurança para proteger os dados pessoais
          transferidos contra perda, uso indevido, alteração ou destruição.
          <br />
          <br />
          <b>5.1.1</b> Os dados pessoais podem ser compartilhados com empresas
          terceirizadas para o desenvolvimento de atividades e prestação de
          serviços da OPENI, exceto para ações de marketing com mala direta,
          respeitando obrigatoriamente as condições estipuladas na presente
          Política de Privacidade.
          <br />
          <br />
          <b>5.1.2</b> A <b>OPENI</b> certifica-se que estes fornecedores seguem
          os requisitos adequados de segurança para proteção aos dados pessoais
          tratados em seu nome.
          <br />
          <br />
          <b>5.1.3</b> A <b>OPENI</b> poderá ser obrigada, por lei, processos
          legais, litígios e/ou solicitações de autoridades públicas e
          governamentais, a divulgar dados pessoais. Também poderão ser
          divulgados seus dados pessoais se for determinado que, para fins de
          segurança nacional, aplicação da lei ou outras questões de importância
          pública, a divulgação é necessária ou apropriada.
          <br />
          <br />
          <b>5.1.4</b> Nas hipóteses de compartilhamento de dados pessoais com
          terceiros, todos os sujeitos mencionados nos itens 5.1.1 e 5.1.2
          deverão utilizar os dados pessoais partilhados de maneira consistente
          e de acordo com os propósitos para os quais foram coletados (ou com os
          quais o usuário consentiu previamente) e de acordo com o que foi
          determinado por esta Política de Privacidade; outras declarações de
          privacidade de website; e todas as leis de privacidade e proteção de
          dados aplicáveis.
          <br />
          <br />
          <b>Cláusula Sexta Direito dos titulares</b>
          <br />
          <br />
          <b>6.1</b> – Direito dos titulares. A Lei Geral de Proteção de Dados,
          em seu art. 18, lista uma garantia de direitos que todos os titulares
          de dados (pessoa física cujo dado é tratado) possuem. Com isso, a
          OPENI disponibiliza o atendimento a todos que desejam exercer o que
          lhes é garantido por lei. Se mesmo após a leitura de nossa Política de
          Privacidade o usuário desejar obter mais informações ou realizar ações
          pertinentes aos seus dados pessoais, os direitos são: • Acessar seus
          Dados Pessoais: O usuário poderá solicitar que a <b>OPENI</b> confirme
          se seus dados estão sendo processados ou não, e em caso afirmativo,
          poderá solicitar o acesso a tais dados. • Solicitar a exclusão de seus
          Dados Pessoais: O usuário poderá solicitar que a <b>OPENI</b> exclua
          seus dados pessoais. • Solicitar a correção de seus Dados Pessoais: O
          usuário poderá solicitar que a <b>OPENI</b> atualize seus dados
          pessoais. • Opor-se ao tratamento de seus Dados Pessoais: O usuário
          poderá solicitar à <b>OPENI</b> que não processe seus dados pessoais.
          • Retirar seu consentimento para o tratamento de seus Dados Pessoais:
          O usuário poderá retirar o seu consentimento para o tratamento de seus
          dados pessoais, mesmo que inicialmente tenha concedido tal
          consentimento à OPENI. Se o usuário remover seu consentimento para
          finalidades que são essenciais aos serviços prestados, os recursos
          podem ficar indisponíveis. • Solicitar restrição do tratamento de seus
          Dados Pessoais: O usuário poderá solicitar à <b>OPENI</b> a limitação
          de tratamento de seus dados pessoais para determinados propósitos,
          mediante certas condições. • Direito de portabilidade de dados: O
          usuário poderá solicitar à <b>OPENI</b> que lhe forneça seus dados
          pessoais ou transmita esses dados a outro operador ou controlador.
          <br />
          <br />
          <b>6.1.1</b> A <b>OPENI</b> empreenderá todos os esforços para atender
          tais pedidos no menor espaço de tempo possível. No entanto, mesmo em
          caso de requisição de exclusão, será respeitado o prazo de
          armazenamento mínimo de informações de usuários de aplicações de
          Internet, determinado como prazo legal ou aquele que dependa para o
          cumprimento de uma obrigação contratual, devendo prevalecer o que for
          de menor prazo.
          <br />
          <br />
          <b>Cláusula Sétima Cookies</b>
          <br />
          <br />
          <b>7.1</b> – Sobre o Cookies. Cookies são arquivos de texto contendo
          dados que um site envia para o navegador web do dispositivo móvel ou
          computador enquanto o usuário navega nele. Os cookies possuem
          diferentes funções, mas, primordialmente, permitem que o usuário
          navegue corretamente pelas páginas web.
          <br />
          <br />
          <b>7.1.1</b> Para obter mais informações sobre os tipos de cookies que
          usamos e como essas informações são usadas pela OPENI, consulte nossa
          política de Cookies.
          <br />
          <br />
          <b>Cláusula Oitava Segurança</b>
          <br />
          <br />
          <b>8.1</b> – Segurança. A <b>OPENI</b> possui medidas de segurança
          técnicas e organizacionais para prevenir que a informação do usuário
          seja manipulada, intencionalmente ou não, perdida, destruída ou
          acessada por pessoas não autorizadas. Nossas medidas de segurança são
          continuamente avaliadas e revistas, de acordo com a tecnologia
          apropriada.
          <br />
          <br />
          <b>8.1.1</b> De forma responsável, declaramos assumir compromisso
          total com a segurança e privacidade dos dados, durante todo o período
          de navegação. Esta política de privacidade e segurança tem como
          principal objetivo de esclarecer e demonstrar aos visitantes, como
          todas as informações, aqui coletadas, serão tratadas e quais poderão
          ser divulgadas ou publicadas com nossos parceiros.
          <br />
          Cláusula Nona Alterações desta política
          <br />
          <br />
          <b>9.1</b> – Sobre as alterações desta política. A <b>OPENI</b> tem o
          direito de alterar o teor desta Política de Privacidade a qualquer
          momento, conforme a finalidade ou necessidade, tal qual para adequação
          e conformidade legal de disposição de lei ou norma que tenha força
          jurídica equivalente, cabendo ao usuário verificá-la sempre que
          efetuar o acesso aos meios digitais da OPENI, conforme os termos do
          art. 50 da LGPD
          <br />
          <br />
          <b>9.1.1</b> Em caso de modificação significativa da presente Política
          de Privacidade, é de responsabilidade da <b>OPENI</b> notificar ao
          usuário o teor das modificações realizadas, conforme disposto no § 6º
          do art. 8º da LGPD. Esta notificação deverá ocorrer através dos meios
          digitais existentes e o usuário reconhece que toda comunicação por
          meio digital é válida para a divulgação de qualquer assunto que se
          refira a proteção de dados.
          <br />
          <br />
          <b>Cláusula Décima Contato</b>
          <br />
          <br />
          <b>10.1</b> – Como nos contatar. Dúvidas e solicitações para exercer
          qualquer direito sobre os seus dados, entre em contato com os CANAIS
          OFICIAIS de comunicação da OPENI.
          <br />
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '24px',
          marginTop: '40px',
          justifySelf: 'end',
        }}
      >
        <Button variant="outlined" onClick={HideModal}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            HideModal()
            handle()
          }}
        >
          Aceito e concordo
        </Button>
      </Box>
    </Box>
  )
}
