export const EditTableIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      fill="#4865CC"
      d="M13 27h1.4l8.625-8.625-1.4-1.4L13 25.6V27zm14.3-10.075l-4.25-4.2 1.4-1.4a1.92 1.92 0 011.413-.575c.559 0 1.03.192 1.412.575l1.4 1.4c.383.383.583.846.6 1.388a1.804 1.804 0 01-.55 1.387L27.3 16.925zM25.85 18.4L15.25 29H11v-4.25l10.6-10.6 4.25 4.25zm-3.525-.725l-.7-.7 1.4 1.4-.7-.7z"
    ></path>
  </svg>
)
