import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { ToastFullContext } from '../base-component/base-snackbar'
import { ModalFullContext } from './modal-provider'

export const ImportFileModal: React.FC<{
  showInstructions?: boolean
  controlRegisterId?: string
  instructions?: string[]
  handle: (file: File, controlRegisterId: string) => void
  allowedExtensions?: string[]
  template?: {
    url: string
    filename: string
  }
}> = ({
  handle,
  showInstructions = true,
  controlRegisterId = '',
  allowedExtensions = [],
  instructions = [],
  template,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { ShowToast } = ToastFullContext()
  const { HideModal } = ModalFullContext()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fileList = event.target.files

    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      const fileExtension = file.name.split('.').pop()?.toLowerCase()
      if (
        allowedExtensions.length === 0 ||
        allowedExtensions.includes(fileExtension!)
      ) {
        setUploadingFile(true)
        setSelectedFile(file)
        await handle(file, controlRegisterId)
        setUploadingFile(false)
      } else {
        ShowToast('warning', 'Extensão de arquivo não permitida.')
      }
    }
  }

  const handleDownloadTemplate = () => {
    if (!template) return

    const link = document.createElement('a')
    link.href = template?.url
    link.download = template?.filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleCancel = () => {
    setSelectedFile(null)
  }

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    if (
      allowedExtensions.length === 0 ||
      allowedExtensions.includes(fileExtension!)
    ) {
      setSelectedFile(file)
      handle(file, controlRegisterId)
    } else {
      alert('Extensão de arquivo não permitida.')
    }
  }

  let allowedFormatsText = ''
  if (allowedExtensions.length > 0) {
    const formattedExtensions = allowedExtensions.map((ext) =>
      ext.toUpperCase(),
    )
    allowedFormatsText =
      'Formatos permitidos: ' + formattedExtensions.join(', ')
  } else {
    allowedFormatsText = 'Formato permitido: XLS, XLSX, DOC e PDF'
  }

  return (
    <form>
      <Stack
        width={uploadingFile ? '290px' : '780px'}
        borderTop="2px solid #13214E"
        position="relative"
        padding="24px"
      >
        <Box
          sx={{
            position: 'absolute',
            right: '0px',
            top: '-50px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedFile(null)
            HideModal()
          }}
        >
          <CloseIcon />
        </Box>

        {!uploadingFile && (
          <Stack>
            {showInstructions && instructions.length > 0 && (
              <>
                <Typography fontSize="14px" fontWeight="600">
                  Instruções:
                </Typography>

                <Stack width="100%" height="100%" paddingLeft="12px">
                  {instructions &&
                    instructions.map((instruction, index) => {
                      return (
                        <Typography
                          key={index}
                          fontSize="14px"
                          fontWeight="400"
                        >
                          {instruction}
                        </Typography>
                      )
                    })}
                </Stack>
                {template && (
                  <Button
                    variant="outlined"
                    sx={{ width: '180px', marginTop: '24px' }}
                    onClick={handleDownloadTemplate}
                  >
                    Planilha modelo
                  </Button>
                )}
              </>
            )}

            <Stack
              width="100%"
              height="240px"
              justifyContent="center"
              alignItems="center"
              border="2px dashed #E7B92D"
              gap="8px"
              borderRadius="12px"
              marginTop="24px"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                name="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <Stack width="100%" justifyContent="center" alignItems="center">
                <Typography fontSize="12px" fontWeight="400" color="#565757">
                  {allowedFormatsText}
                </Typography>
                <Typography fontSize="12px" fontWeight="400" color="#565757">
                  Tamanho máximo: 10MB
                </Typography>
              </Stack>

              <Typography fontSize="16px" fontWeight="400" color="#00030C">
                Arraste o arquivo aqui
              </Typography>

              <Typography fontSize="12px" fontWeight="400" color="#565757">
                ou clique abaixo
              </Typography>

              <Button variant="contained" onClick={handleUpload}>
                Selecione arquivo
              </Button>
            </Stack>
          </Stack>
        )}

        {uploadingFile && (
          <Stack width="100%" justifyContent="center" alignItems="center">
            <Typography fontSize="14px" fontWeight="400" marginBottom="24px">
              Isso deve demorar alguns instantes.
            </Typography>
            <Typography fontSize="14px" fontWeight="400" marginBottom="24px">
              Por favor aguarde...
            </Typography>

            <CircularProgress />

            <Button
              onClick={handleCancel}
              variant="contained"
              sx={{ width: '210px', marginTop: '24px' }}
            >
              Cancelar
            </Button>
          </Stack>
        )}
      </Stack>
    </form>
  )
}
