import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { EditOutlined } from '@mui/icons-material'
import { RegisterUseCase } from 'src/usecase/authentication/usecase-authentication-form-register'
import { useState } from 'react'
import { UserPersonTypeEnum } from 'src/service/service-authentication'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TermsOfUseModal } from 'src/component/modal/modal-terms-of-use'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { BaseRadio } from 'src/component/base-component/base-radio'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { useNavigate } from 'react-router-dom'
import { LoginUseCase } from 'src/usecase/authentication/usecase-authentication-form-login'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'

interface RegisterPageProps {
  useCase: RegisterUseCase
  loginUsecase: LoginUseCase
  userOwnData: UserOwnDataUseCase
}

export function RegisterPage(props: RegisterPageProps): any {
  const { ShowModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()

  const [loading, setLoading] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const theme = useTheme()
  const navigate = useNavigate()

  const registerForm = CreateForm({
    fields: [
      { personType: 'FISICA' },
      'name',
      'companyName',
      'cpfCnpj',
      'username',
      'password',
      'confirmPassword',
    ],
    validation: props.useCase.validation,
  })

  const handleRegisterUsecase = async () => {
    try {
      setAcceptedTerms(true)
      setLoading(true)

      const usecaseResult = await props.useCase.handle({
        name: registerForm.fields.name.value,
        cpfCnpj: registerForm.fields.cpfCnpj.value?.replace(/[^0-9]/g, ''),
        companyName: registerForm.fields.companyName.value,
        password: registerForm.fields.password.value,
        username: registerForm.fields.username.value,
        personType: registerForm.fields.personType.value as UserPersonTypeEnum,
      })

      if (usecaseResult.isFailure) {
        setLoading(false)
        switch ((usecaseResult.error as any)?.type) {
          case 'UserInvalidCpfCnpjError':
            ShowToast('error', 'O CPF/CNPJ informado é inválido.')
            break

          case 'UserUsernameAlreadyExistsError':
            ShowToast('error', 'O username informado já está em uso.')
            break

          default:
            ShowToast(
              'error',
              'Dados informados inválidos. Verifique os campos ou tente novamente mais tarde.',
            )
            break
        }
        return
      }

      const loginResult = await props.loginUsecase.handle({
        username: registerForm.fields.username.value,
        password: registerForm.fields.password.value,
      })

      if (loginResult.isFailure) {
        setLoading(false)
        console.log(loginResult.error)

        ShowToast(
          'error',
          'Ocorreu um erro ao cadastrar. Verifique os campos ou tente novamente mais tarde.',
        )
        return
      }

      const ownDataResult = await props.userOwnData.handle()

      if (ownDataResult.isFailure) {
        setLoading(false)
        console.log(ownDataResult.error)

        ShowToast(
          'error',
          'Ocorreu um erro ao recuperar os dados do usuário. Verifique os campos ou tente novamente mais tarde.',
        )
        return
      }

      setLoading(false)

      navigate('/auth/backup-code')
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao cadastrar. Tente novamente mais tarde.',
      )
    }
  }

  const handleRegister = async () => {
    try {
      const validationResult = await registerForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      if (acceptedTerms) {
        return handleRegisterUsecase()
      }

      ShowModal({
        content: <TermsOfUseModal handle={handleRegisterUsecase} />,
        title: 'Termos de uso',
      })
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao cadastrar. Tente novamente mais tarde.',
      )
    }
  }

  const handleLogin = () => {
    navigate('/auth/sign-in')
  }

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '440px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: '700',
          }}
        >
          Cadastro
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            marginTop: '8px',
          }}
        >
          Insira os dados para cadastro
        </Typography>
        <BaseRadio
          groupStyle={{
            marginTop: '24px',
          }}
          {...registerForm.fields.personType}
          options={[
            { label: 'Pessoa física', value: 'FISICA' },
            { label: 'Pessoa jurídica', value: 'JURIDICA' },
          ]}
        />
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            marginTop: '24px',
            gap: '24px',
            width: '100%',
          }}
        >
          <BaseInput
            label="Nome completo"
            fullWidth
            {...registerForm.fields.name}
            // value={name}
            // setState={setName}
            // error={nameError}
          />
          <BaseInput
            label="CPF/CNPJ"
            fullWidth
            {...registerForm.fields.cpfCnpj}
            // value={cpfCnpj}
            // setState={setCpfCnpj}
            // error={cpfCnpjError}
            mask={
              registerForm.fields.personType.value === 'FISICA'
                ? '999.999.999-99'
                : '99.999.999/9999-99'
            }
          />
          <BaseInput
            label="Empresa"
            fullWidth
            {...registerForm.fields.companyName}
            // value={company}
            // setState={setCompany}
            // error={companyError}
          />
          <BaseInput
            label="Senha"
            type="password"
            fullWidth
            {...registerForm.fields.password}
            // value={password}
            // setState={setPassword}
            // error={passwordError}
          />
          {!!registerForm.fields.password.error && (
            <Box
              sx={{
                p: {
                  fontSize: '10px',
                  fontWeight: '400',
                  lineHeight: '13px',
                },
                marginTop: '-10px',
              }}
            >
              <Typography>A senha deve conter:</Typography>
              <ul
                style={{
                  marginTop: '-2px',
                  paddingLeft: '16px',
                  marginBottom: '-2px',
                }}
              >
                <li>
                  <Typography
                    sx={{
                      color:
                        registerForm.fields.password.value?.length < 6
                          ? theme.palette.error.main
                          : theme.palette.success.main,
                    }}
                  >
                    6 dígitos
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      color: /[A-Z]/.test(registerForm.fields.password.value)
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                  >
                    Pelo menos 1 letra maiúscula
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      color: /[a-z]/.test(registerForm.fields.password.value)
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                  >
                    Pelo menos 1 letra minúscula
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      color: /[^A-Za-z0-9]/.test(
                        registerForm.fields.password.value,
                      )
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                  >
                    Pelo menos 1 símbolo
                  </Typography>
                </li>
              </ul>
            </Box>
          )}
          <BaseInput
            label="Confirmar senha"
            type="password"
            fullWidth
            {...registerForm.fields.confirmPassword}
            // value={confirmPassword}
            // setState={setConfirmPassword}
            // error={confirmPasswordError}
          />
          <BaseInput
            label="Username"
            fullWidth
            {...registerForm.fields.username}
            // value={username}
            // setState={setUsername}
            // error={usernameError}
            iconStart={'@'}
            iconEnd={
              <EditOutlined style={{ color: theme.palette.primary.main }} />
            }
          />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'max-content max-content',
              gap: '24px',
              marginTop: '40px',
              justifySelf: 'end',
            }}
          >
            {!loading && (
              <>
                <Button variant="outlined" onClick={handleLogin}>
                  Cancelar
                </Button>
                <Button variant="contained" onClick={handleRegister}>
                  Cadastrar
                </Button>
              </>
            )}
            {loading && <CircularProgress />}
          </Box>
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
