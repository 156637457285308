import { Box, Stack, Stepper, Typography } from '@mui/material'
import { Circle, Line } from './styles'

export type VerticalStepperProps = {
  setCurrent: (value: number) => void
  currentStep: number
  steps: {
    title: string
  }[]
}

export const VerticalStepper = ({
  currentStep,
  setCurrent,
  steps,
}: VerticalStepperProps) => {
  const handleChangeStep = (step: number) => {
    if (step >= currentStep) return

    setCurrent(step)
  }

  return (
    <Stack
      marginRight="24px"
      marginTop="72px"
      minWidth="126px"
      sx={{ backgroundColor: 'transparent' }}
    >
      <Stepper activeStep={currentStep} orientation="vertical">
        {steps.map((step, index) => {
          return (
            <Stack
              key={index}
              onClick={() => handleChangeStep(index)}
              direction="row"
            >
              <Box height="120px">
                <Circle isActive={index <= currentStep}>
                  {index + 1}
                  {index + 1 < steps.length && (
                    <Line isActive={index <= currentStep} />
                  )}
                </Circle>
              </Box>
              <Typography
                marginLeft="12px"
                marginTop="12px"
                fontWeight={index <= currentStep ? '700' : '400'}
                fontSize="12px"
                color={index <= currentStep ? '#5ED1A2' : '#B9BFC7'}
              >
                {step.title}
              </Typography>
            </Stack>
          )
        })}
      </Stepper>
    </Stack>
  )
}
