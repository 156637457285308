import React from 'react'
import { Box, Typography } from '@mui/material'

interface LogoProps {
  name: string
  logo?: string
  width?: number
  height?: number
}

export const Logo: React.FC<LogoProps> = ({
  name,
  logo,
  width = 48,
  height = 48,
}) => {
  const getInitials = (name: string) => {
    const firstName = name.split(' ')[0] // Pega a primeira palavra do nome
    return firstName.length > 6
      ? firstName.substring(0, 6).toUpperCase()
      : firstName.toUpperCase()
  }

  return (
    <Box
      sx={{
        width,
        height,
        backgroundColor: logo ? 'transparent' : '#e0e0e0',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundImage: logo ? `url(${logo})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {!logo && (
        <Typography
          sx={{
            color: '#616161',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '12px',
          }}
        >
          {getInitials(name)}
        </Typography>
      )}
    </Box>
  )
}
