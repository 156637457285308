export const MockPlans = [
  {
    id: 1,
    title: 'Basic',
    price: 'R$130,90',
    subTitle: '+R$ 13,90 por CNPJ acima do 11º',
    planOptions: [
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
      {
        option: 'Lorem Ipsum',
        active: false,
      },
    ],
  },
  {
    id: 1,
    title: 'Pro',
    price: 'R$130,90',
    subTitle: '+R$ 13,90 por CNPJ acima do 11º',
    planOptions: [
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
    ],
  },
  {
    id: 1,
    title: 'Premium',
    price: 'R$130,90',
    subTitle: '+R$ 13,90 por CNPJ acima do 11º',
    planOptions: [
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
    ],
  },
  {
    id: 1,
    title: 'Enterprise',
    price: 'R$130,90',
    subTitle: '+R$ 13,90 por CNPJ acima do 11º',
    planOptions: [
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
      {
        option: 'Lorem Ipsum',
        active: true,
      },
    ],
  },
]
