import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'
import { BaseDateSelect } from 'src/component/base-component/base-date-select'
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { ColumnsCommon } from 'src/component/table/header'
import { HeaderWithFilter } from 'src/component/table/header-with-filter'
import SearchIcon from '@mui/icons-material/Search'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { MenuDotIcon } from 'src/component/icons/menu-dot'

export function useClassifierColumns(
  searchQuery: string,
  vendorsOptions: {
    value: string
    label: string
  }[],
  minValue: string,
  maxValue: string,
  companyId: string,
  debits: string[],
  credits: string,
  columnsTableSelected: string[],
  initialDate: Date | undefined,
  finalDate: Date | undefined,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
  setMinValue: React.Dispatch<React.SetStateAction<string>>,
  setMaxValue: React.Dispatch<React.SetStateAction<string>>,
  setDebits: React.Dispatch<React.SetStateAction<string[]>>,
  setCredits: React.Dispatch<React.SetStateAction<string>>,
  listCompanyDebitsUseCase: ListCompanyDebitUseCase,
  handleSelectDate: (e: Date | undefined, type: 'initial' | 'final') => void,
  handleListAccountingEntries: (
    companyId: string,
    page: number,
  ) => Promise<void>,
) {
  const [variablesSelected, setVariablesSelected] = useState<string[]>([])
  const HistoricVariables = [
    {
      label: 'Data',
      value: 'data',
    },
    {
      label: 'Descrição',
      value: 'descricao',
    },
    {
      label: 'Valor',
      value: 'valor',
    },
    {
      label: 'Débito',
      value: 'debito',
    },
    {
      label: 'Crédito',
      value: 'credito',
    },
  ]
  const columns = useMemo(() => {
    const order = [
      'Data',
      'Descrição',
      'Clientes e fornecedor',
      'Valor',
      'Débito',
      'Crédito',
      'Histórico',
      'Categoria financeira',
      'Ações',
    ]
    const makeGrid: GridColDef[] = [
      {
        ...ColumnsCommon,
        flex: 1,
        align: 'left',
        ...{
          minWidth: 350,
          field: 'Clientes e fornecedor',
          renderHeader: () => (
            <HeaderWithFilter
              title="Clientes e fornecedores"
              content={
                <Stack sx={{ padding: '0px 10px', width: '296px' }}>
                  <Box paddingX="20px">
                    <Stack direction="row" marginTop="20px">
                      <SortByAlphaIcon />
                      <Typography marginLeft="10px">Ordem crescente</Typography>
                    </Stack>

                    <Stack direction="row" marginTop="20px">
                      <SortByAlphaIcon />
                      <Typography marginLeft="10px">
                        Ordem decrescente
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    width="100%"
                    borderBottom="dashed 1px #777C84"
                    marginTop="20px"
                    marginBottom="20px"
                  />

                  <Box paddingX="20px">
                    <BaseInput
                      fullWidth
                      labelBackground="#F4F8FA"
                      type="text"
                      label="Buscar Empresa"
                      setState={(event) => {
                        if (!event) {
                          return setSearchQuery && setSearchQuery('')
                        }

                        setSearchQuery && setSearchQuery(event)
                      }}
                      value={searchQuery ?? ''}
                      error={null}
                      iconStart={<SearchIcon />}
                    />

                    <Box marginTop="20px">
                      {vendorsOptions.map((item) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          key={item.label}
                          marginBottom="10px"
                        >
                          <Checkbox
                            sx={{
                              color: '#4865CC',
                              '&.Mui-checked': {
                                color: '#4865CC',
                              },
                            }}

                            // checked={value.indexOf(option.value.toString()) > -1}
                          />
                          <ListItemText
                            primary={item.label}
                            sx={{ marginLeft: '5px' }}
                          />
                        </Stack>
                      ))}
                    </Box>
                  </Box>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.supplier}`,
      },
      {
        ...ColumnsCommon,
        flex: 0.6,
        align: 'center',
        minWidth: 150,
        ...{
          field: 'Histórico',
          renderHeader: () => (
            <HeaderWithFilter
              title="Histórico"
              content={
                <Stack sx={{ padding: '10px 20px' }}>
                  <Typography color="#000" fontSize="14px" fontWeight={600}>
                    Selecione o valor mínimo e máximo
                  </Typography>
                  <Stack direction="row" marginTop="20px">
                    <BaseInput
                      label="Mínimo"
                      fullWidth
                      setState={(e) => setMinValue(e)}
                      error=""
                      value={minValue}
                      containerStyle={{ width: '140px', marginRight: '15px' }}
                    />

                    <BaseInput
                      label="Máximo"
                      fullWidth
                      setState={(e) => setMaxValue(e)}
                      error=""
                      value={maxValue}
                      containerStyle={{ width: '140px' }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <BaseDropDownInput
            options={HistoricVariables}
            error=""
            value={variablesSelected}
            setState={(e) => setVariablesSelected(e)}
          />
        ),
      },
      {
        ...ColumnsCommon,
        flex: 1,
        align: 'center',
        minWidth: 250,
        ...{
          field: 'Categoria financeira',
          renderHeader: () => (
            <HeaderWithFilter
              title="Categoria financeira"
              content={
                <Stack sx={{ padding: '10px 20px' }}>
                  <Typography color="#000" fontSize="14px" fontWeight={600}>
                    Selecione o valor mínimo e máximo
                  </Typography>
                  <Stack direction="row" marginTop="20px">
                    <BaseInput
                      label="Mínimo"
                      fullWidth
                      setState={(e) => setMinValue(e)}
                      error=""
                      value={minValue}
                      containerStyle={{ width: '140px', marginRight: '15px' }}
                    />

                    <BaseInput
                      label="Máximo"
                      fullWidth
                      setState={(e) => setMaxValue(e)}
                      error=""
                      value={maxValue}
                      containerStyle={{ width: '140px' }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              listCompanyDebitsUseCase={listCompanyDebitsUseCase}
              variant="debits"
              error=""
              label=""
              setState={(e) => setCredits(e)}
              value={credits}
            />
          </Stack>
        ),
      },
      {
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Ações',
          headerName: 'Ações',
          minWidth: 100,
          align: 'left',
          renderCell: (params) => (
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '15px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <MenuDotIcon />
            </IconButton>
          ),
        },
      },
    ]

    if (columnsTableSelected.includes('date')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Data',
          minWidth: 130,
          renderHeader: () => (
            <HeaderWithFilter
              title="Data"
              content={
                <Stack sx={{ padding: '10px 20px' }}>
                  <Typography color="#000" fontSize="14px" fontWeight={600}>
                    Selecione o período
                  </Typography>
                  <Stack direction="row" marginTop="20px">
                    <BaseDateSelect
                      title="De"
                      onSelect={(e) => handleSelectDate(e, 'initial')}
                      buttonStyle={{ width: '160px' }}
                      containerStyle={{ width: '160px', marginRight: '15px' }}
                      selectedDate={initialDate}
                    />

                    <BaseDateSelect
                      title="Até"
                      onSelect={(e) => handleSelectDate(e, 'final')}
                      buttonStyle={{ width: '160px' }}
                      containerStyle={{ width: '160px' }}
                      selectedDate={finalDate}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => handleListAccountingEntries(companyId, 1)}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.date || ''}`,
      })
    }

    if (columnsTableSelected.includes('description')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 1,
        align: 'left',
        ...{
          field: 'Descrição',
          minWidth: 350,
          renderHeader: () => (
            <HeaderWithFilter
              title="Descrição"
              content={
                <Stack sx={{ padding: '0px 10px', width: '296px' }}>
                  <Box paddingX="20px">
                    <Stack direction="row" marginTop="20px">
                      <SortByAlphaIcon />
                      <Typography marginLeft="10px">Ordem crescente</Typography>
                    </Stack>

                    <Stack direction="row" marginTop="20px">
                      <SortByAlphaIcon />
                      <Typography marginLeft="10px">
                        Ordem decrescente
                      </Typography>
                    </Stack>
                  </Box>

                  <Box
                    width="100%"
                    borderBottom="dashed 1px #777C84"
                    marginTop="20px"
                    marginBottom="20px"
                  />

                  <Box paddingX="20px">
                    <BaseInput
                      fullWidth
                      labelBackground="#F4F8FA"
                      type="text"
                      label="Buscar Empresa"
                      setState={(event) => {
                        if (!event) {
                          return setSearchQuery && setSearchQuery('')
                        }

                        setSearchQuery && setSearchQuery(event)
                      }}
                      value={searchQuery ?? ''}
                      error={null}
                      iconStart={<SearchIcon />}
                    />

                    <Box marginTop="20px">
                      {vendorsOptions.map((item) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          key={item.value}
                          marginBottom="10px"
                        >
                          <Checkbox
                            sx={{
                              color: '#4865CC',
                              '&.Mui-checked': {
                                color: '#4865CC',
                              },
                            }}

                            // checked={value.indexOf(option.value.toString()) > -1}
                          />
                          <ListItemText
                            primary={item.value}
                            sx={{ marginLeft: '5px' }}
                          />
                        </Stack>
                      ))}
                    </Box>
                  </Box>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.description}`,
      })
    }

    if (columnsTableSelected.includes('value')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Valor',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Valor"
              content={
                <Stack sx={{ padding: '10px 20px' }}>
                  <Typography color="#000" fontSize="14px" fontWeight={600}>
                    Selecione o valor mínimo e máximo
                  </Typography>
                  <Stack direction="row" marginTop="20px">
                    <BaseInput
                      label="Mínimo"
                      fullWidth
                      type="number"
                      setState={(e) => setMinValue(e)}
                      error=""
                      value={minValue}
                      containerStyle={{ width: '140px', marginRight: '15px' }}
                    />

                    <BaseInput
                      label="Máximo"
                      type="number"
                      fullWidth
                      setState={(e) => setMaxValue(e)}
                      error=""
                      value={maxValue}
                      containerStyle={{ width: '140px' }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => handleListAccountingEntries(companyId, 1)}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.value}`,
      })
    }

    if (columnsTableSelected.includes('debit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Débito',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Débito"
              content={
                <Stack sx={{ padding: '10px 20px' }}>
                  <Typography color="#000" fontSize="14px" fontWeight={600}>
                    Selecione o valor mínimo e máximo
                  </Typography>
                  <Stack direction="row" marginTop="20px">
                    <BaseInput
                      label="Mínimo"
                      fullWidth
                      setState={(e) => setMinValue(e)}
                      error=""
                      value={minValue}
                      containerStyle={{ width: '140px', marginRight: '15px' }}
                    />

                    <BaseInput
                      label="Máximo"
                      fullWidth
                      setState={(e) => setMaxValue(e)}
                      error=""
                      value={maxValue}
                      containerStyle={{ width: '140px' }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              listCompanyDebitsUseCase={listCompanyDebitsUseCase}
              variant="debits"
              error=""
              label=""
              setState={(e) => setCredits(e)}
              value={credits}
            />
          </Stack>
        ),
      })
    }

    if (columnsTableSelected.includes('debit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Crédito',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Crédito"
              content={
                <Stack sx={{ padding: '10px 20px' }}>
                  <Typography color="#000" fontSize="14px" fontWeight={600}>
                    Selecione o valor mínimo e máximo
                  </Typography>
                  <Stack direction="row" marginTop="20px">
                    <BaseInput
                      label="Mínimo"
                      fullWidth
                      setState={(e) => setMinValue(e)}
                      error=""
                      value={minValue}
                      containerStyle={{ width: '140px', marginRight: '15px' }}
                    />

                    <BaseInput
                      label="Máximo"
                      fullWidth
                      setState={(e) => setMaxValue(e)}
                      error=""
                      value={maxValue}
                      containerStyle={{ width: '140px' }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    gap="12px"
                    marginTop="20px"
                  >
                    <Button
                      variant="outlined"
                      sx={{ width: '104px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Cancelar
                      </Typography>
                    </Button>

                    <Button
                      variant="contained"
                      sx={{ width: '100px', height: '40px', padding: 0 }}
                      onClick={() => console.log('press me ')}
                    >
                      <Typography
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="16.41px"
                      >
                        Aplicar
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              listCompanyDebitsUseCase={listCompanyDebitsUseCase}
              variant="credits"
              error=""
              label=""
              setState={(e) => setCredits(e)}
              value={credits}
            />
          </Stack>
        ),
      })
    }

    return order
      .map((key) => makeGrid.find((column) => column.field === key))
      .filter((column): column is GridColDef => column !== undefined)
  }, [vendorsOptions, columnsTableSelected])

  return {
    columns,
  }
}
