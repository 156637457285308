import {
  AuthenticationApiRemoteService,
  TAuthRecovery2FAFinishDTO,
  TAuthRecovery2FAResponse,
} from 'src/service/service-authentication'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'
import { object, string } from 'yup'

export class Recovery2FAFinishUseCase
  implements IFormUsecase<TAuthRecovery2FAFinishDTO, void>
{
  constructor(private repository: AuthenticationApiRemoteService) {}

  public async handle(data: TAuthRecovery2FAFinishDTO): Promise<Result<void>> {
    try {
      const response = await this.repository.recovery2FAFinish(data)

      if (response.isFailure) {
        return response
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    secret: string().required('Erro interno: O código secreto não foi enviado'),
    token: string()
      .required('O código de 6 dígitos do aplicativo é obrigatório')
      .length(6, 'O código de 6 dígitos do aplicativo é obrigatório'),
  })
}
